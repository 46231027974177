import { Dispatch } from 'redux';
import apiRequest from '@categoryProduct/api/apiRequest';
import endpoints from '@categoryProduct/api/endpoints';
import loadPublicDesign from '../action/loadPublicDesign';
import setDesignStatus from '../action/setDesignStatus';
import setDesignData from '../action/setDesignData';
import { DesignStatus } from '../types';

const loadPublicDesignOperation = (designId: string) => async (dispatch: Dispatch) => {
  dispatch(loadPublicDesign(designId));
  return apiRequest(endpoints.publicDesignGet, { encPublicDesignId: designId }, 'GET', 'data')
    .then((design) => {
      return dispatch(setDesignData(design));
    }, () => {
      return dispatch(setDesignStatus(DesignStatus.DESIGN_FAILED));
    });
};

export default loadPublicDesignOperation;
