import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './SubCategories.css';
import apiTypings from '../../../../api/optimalprint-sdk';
import { categorySelectors } from '../../../../store/category';

interface Props {
  categoryId: number;
  parentCategoryId: number;
  parentCategory?: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo;
  subCategories?: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo[];
}

const SubCategories = ({ subCategories, parentCategory, categoryId }: Props) => {
  const [collapsed, setCollapsed] = useState(true);

  let links = [] as any[];
  if (subCategories) {
    const subCategoriesLinks = subCategories.slice(0, collapsed ? 5 : subCategories.length).map(c => (
      <Link
        key={c.categoryId}
        to={c.url}
        className={cn({
          [styles.link]: true,
          [styles.linkActive]: c.categoryId === categoryId,
        })}
      >
        {c.translatedName}
      </Link>
    ));

    links = [...subCategoriesLinks];
  }


  if (parentCategory) {
    const parentCategoryLink = (
      <Link
        key={parentCategory.categoryId}
        to={parentCategory.url}
        className={styles.link}
      >
        <Translation>{t => t('txt_all')}</Translation>
      </Link>
    );

    links = [parentCategoryLink, ...links];
  }

  const seeMoreLink = subCategories && subCategories.length > 5 && (
    <Translation>
      {t =>
        <div
          className={cn(styles.seeMore)}
          onClick={() => setCollapsed(!collapsed)}
        >
          {
            collapsed
              ? t('txt_see_more')
              : t('txt_see_less')
          }
        </div>
      }
    </Translation>
  );

  return (
    <Translation>
      {t =>
        <div>
          <span className={styles.categoryName}>{t('txt_label_category')}</span>
          <div id='subCategorySection' className={styles.container}>{links}</div>
          <span id='relatedCat'>{seeMoreLink}</span>
        </div>
      }
    </Translation>
  );
};

const mapStateToProps = (state: any, props: Props) => ({
  subCategories: categorySelectors.getSubCategories(state, props.categoryId, props.parentCategoryId),
  parentCategory: categorySelectors.getCategory(state, props.parentCategoryId),
});

export default connect(mapStateToProps)(SubCategories);
