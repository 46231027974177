import React from 'react';
import styles from './index.css';
import { Translation } from 'react-i18next';

interface Props {
  children: any;
}

const ProductWelcome: React.FC<Props> = ({ children }: Props) => {
  return (
    <Translation>
      {t => (
        <div className={styles['product-welcome']}>
          <div className={styles['welcome-text']}>
            {t('txt_create_birth_poster')}
          </div>
          <div className={styles['product-info']}>
            {children}
          </div>
        </div>
      )}
    </Translation>
  );
};

export default ProductWelcome;
