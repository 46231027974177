import translateText from '@categoryProduct/util/translateText';
import { Store } from '../../typings';

const getLabel = (control: any, defaultValue: string) => (control ? control.label : defaultValue);
const getValue = (control: any, defaultValue: string) => (control ? control.value : defaultValue);
const addSpaceIfNotEmpty = (v: string) => (v !== '' ? ` ${v}` : '');

const getBabyData = (store: Store, section: string, babyIndex: number) => {
  const sectionControls = store.controls.sections[section];
  const keyPrefix = `_${babyIndex}`;

  if (!sectionControls) {
    return [];
  }

  let babyWeight = '';

  if (sectionControls.babyWeightUnits.value.value === 'm') {
    babyWeight = [
      sectionControls.babyWeightGrams.value,
      translateText(getLabel(sectionControls.babyWeightUnits.value, '')),
    ].join(' ');
  }

  if (sectionControls.babyWeightUnits.value.value === 'i') {
    babyWeight = [
      getValue(sectionControls.babyWeightPounds, ''),
      translateText('txt_pounds'),
      getValue(sectionControls.babyWeightOunces, ''),
      translateText('txt_ounces'),
    ].join(' ');
  }

  const isBirthTimeProvided = sectionControls.birthHour.value.trim() !== '' && sectionControls.birthMin.value.trim() !== '';
  const birthTime = isBirthTimeProvided
    ? [
      sectionControls.birthHour.value,
      ':',
      sectionControls.birthMin.value,
      addSpaceIfNotEmpty(getValue(sectionControls.birthAmPm.value, '')),
    ].join('')
    : '';

  const babyLength = sectionControls.babyLength
    ? [
      sectionControls.babyLength.value,
      translateText(getLabel(sectionControls.babyLengthUnits.value, '')),
    ].join(' ')
    : '';

  const result = [
    {
      key: `name${keyPrefix}`,
      value: sectionControls.babyName.value,
    },
    {
      key: `date${keyPrefix}`,
      value: sectionControls.babyBirthDate.value,
    },
    {
      key: `weight${keyPrefix}`,
      value: babyWeight.toUpperCase(),
    },
    {
      key: `time${keyPrefix}`,
      value: birthTime.toUpperCase(),
    },
    {
      key: `length${keyPrefix}`,
      value: babyLength.toUpperCase(),
    },
  ];
  return result;
};

export default getBabyData;
