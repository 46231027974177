import React, {useContext} from 'react';
import { Translation } from 'react-i18next';
import Text from '@categoryProduct/component/common/Text';
import EditorControlsContext from '@categoryProduct/util/context/EditorControlsContext';
import SimpleList from '../../Controls/SimpleList';
import styles from './index.css';
import { connect } from 'react-redux';

interface Props {
  section: string;
}

const controlName = 'background';

const BackgroundSelector: React.FC<Props> = ({section}: Props) => {

  const options = [
    {
      label: 'Grey',
      value: '#faf9f5',
      id: '1',
    },
    {
      label: 'Beige',
      value: '#f2f2f2',
      id: '2',
    },
    {
      label: 'Light Orange ',
      value: '#fdf6ef',
      id: '3',
    },
    {
      label: 'Green',
      value: '#ecf5e9',
      id: '4',
    },
    {
      label: 'Blue',
      value: '#e4f2f7',
      id: '5',
    },
    {
      label: 'Pink',
      value: '#f9edef',
      id: '6',
    }, {
      label: 'White',
      value: '#ffffff',
      id: '7',
    },
  ];

  const editorControls = useContext(EditorControlsContext);

  const onSelect = (value: any) => {
    if (editorControls) {
      editorControls.changeBackgroundColor(0, value.value);
    }
  };

  const optionRender = (option: any) => {
    return (
      <div
        className={styles.circleOption}
        style={{ background: option.value }}
      />
    );
  };

  return (
    <>
      <Translation>
        {t => (
          <div className={styles.backgroundSelector}>
            <Text darkBlue>{t('txt_background')}:</Text>
            <SimpleList
              options={options}
              initialValue={options[0]}
              optionRender={optionRender}
              styles={styles}
              section={section}
              onSelect={onSelect}
              name={controlName}
            />
          </div>
        )}
      </Translation>
    </>
  );
};


const mapStateToProps = () => ({});
export default connect(mapStateToProps)(BackgroundSelector);
