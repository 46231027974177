import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Translation } from 'react-i18next';
import { History } from 'history';
import ReactPlaceholder from 'react-placeholder';

import { categorySelectors } from '@categoryProduct/store/category';
import { findFilterbyTag } from '@categoryProduct/util/category';
import Button from '@categoryProduct/component/common/Button';
import FilterSlider from './FilterSlider';
import SortBy from './SortBy';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import { StringMap } from '@categoryProduct/typings';
import { getOrderedUpsellObjects } from '@categoryProduct/component/util/TrimFoilHelper';

import 'react-placeholder/lib/reactPlaceholder.css?global';
import styles from './styles.css';

interface Props {
  history: History;
  sortByOptions?: SelectType[];
  onSortByChange: (selected: SelectType) => void;
  clickToFilter: (tag: number) => void;
  filters?: Filters;
  countOfDesigns: number | string;
  resetFilter: () => void;
  selectedFilters?: Filter[];
  categoryId: number;
  parentCategoryId: number;
  selectedSorting: SelectType;
  subCategories?: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo[];
  isDisabled: boolean;
}

const FILTERS_APPLIED_TO_SEE_DELETE_BUTTON = 3;

const FiltersContainer: React.FC<Props> = (
  {
    history,
    selectedSorting,
    filters,
    clickToFilter,
    countOfDesigns,
    resetFilter,
    selectedFilters,
    sortByOptions,
    onSortByChange,
    subCategories,
    isDisabled,
  },
) => {
  if (!filters || !subCategories || !selectedFilters) {
    return null;
  }

  const [ totalNumberOfDesigns, setTotalNumberOfDesigns ] = useState(0);
  const [ showFilters, onChange ] = useState(false);
  const openModal = () => {
    onChange(true);
    document.body.style.overflow = 'hidden';
  };
  const closeModal = () => {
    onChange(false);
    document.body.style.overflow = 'auto';
  };

  // do not allow 0 to be shown to user, while we have temporary results
  // case: when we sort we are clearing family (size is 0), then get new one and
  // do proper designs impression tracking (size is correct now)
  useEffect(() => {
    if (countOfDesigns > 0) {
      setTotalNumberOfDesigns(countOfDesigns as number);
    }
  }, [ countOfDesigns ]);

  return (
    <React.Fragment>
      <Translation>
        {t => (
          <div
            className={classNames(
              styles.container,
              { [styles.active]: showFilters },
            )}
          >
            <div className={styles.header}>
              <div />
              <h3 className={styles.modalHeader}>{t('txt_filters')}</h3>
              <span id='clearFilterMobile_Text' className={styles.modalHeaderRightText} onClick={resetFilter}>
                {t('txt_clear_all')}
              </span>
            </div>
            <div className={styles.content}>
              {sortByOptions && sortByOptions.length > 1 && (
                <div className={styles.filterItem}>
                  <span className={styles.categoryName}>{t('txt_sort_by')}</span>
                  <SortBy
                    history={history}
                    selectedSorting={selectedSorting}
                    sortOptions={sortByOptions}
                    onChange={onSortByChange}
                  />
                </div>
              )}
              {filters.color && filters.color.length > 1 && (
                <div className={styles.filterItem}>
                  <span className={styles.categoryName}>{t('txt_filter_color')}</span>
                  <FilterSlider items={filters.color} clickToFilter={clickToFilter} inRow />
                </div>
              )}
              {filters.foil && filters.foil.length > 1 && (
                <div className={classNames('spotfinish', styles.filterItem)}>
                  <span className={styles.categoryName}>{t('txt_filter_spotFinish')}</span>
                  <FilterSlider items={getOrderedUpsellObjects(filters.foil)} clickToFilter={clickToFilter} inRow />
                </div>
              )}
              {filters.trim && filters.trim.length > 1 && (
                <div className={styles.filterItem}>
                  <span className={styles.categoryName}>{t('txt_filter_trim')}</span>
                  <FilterSlider items={getOrderedUpsellObjects(filters.trim)} clickToFilter={clickToFilter} inRow />
                </div>
              )}
              {filters.folded && filters.folded.length > 1 && (
                <div className={styles.filterItem}>
                  <span className={styles.categoryName}>{t('txt_filter_folded')}</span>
                  <FilterSlider items={filters.folded} clickToFilter={clickToFilter} inRow />
                </div>
              )}
              {filters.gender && filters.gender.length > 1 && (
                <div className={styles.filterItem}>
                  <span className={styles.categoryName}>{t('txt_filter_gender')}</span>
                  <FilterSlider items={filters.gender} clickToFilter={clickToFilter} inRow />
                </div>
              )}
              {filters.nfotos && filters.nfotos.length > 1 && (
                <div className={styles.filterItem}>
                  <span className={styles.categoryName}>{t('txt_filter_imageCounter')}</span>
                  <FilterSlider items={filters.nfotos} clickToFilter={clickToFilter} inRow />
                </div>
              )}
              {filters.orient && filters.orient.length > 1 && (
                <div className={styles.filterItem}>
                  <span className={styles.categoryName}>{t('txt_filter_orientation')}</span>
                  <FilterSlider items={filters.orient} clickToFilter={clickToFilter} inRow />
                </div>
              )}
              {filters.size && filters.size.length > 1 && (
                <div className={styles.filterItem}>
                  <span className={styles.categoryName}>{t('txt_filter_format')}</span>
                  <FilterSlider items={filters.size} clickToFilter={clickToFilter} inRow />
                </div>
              )}
            </div>
            <div className={styles.modalFooter}>
              <button id='showDesignsMobile_Button' className={styles.modalFooterButton} onClick={closeModal}>
                {t('txt_show_count_designs', { count: totalNumberOfDesigns } as StringMap)}
              </button>
            </div>
          </div>
        )}
      </Translation>
      <Translation>
        {t => (
          <div className={styles.filterMobileContainer}>
            <ReactPlaceholder
              showLoadingAnimation
              type='textRow'
              ready={!isDisabled}
              style={{ width: '100%', height: '44px', margin: '15px 0', backgroundColor: '#e8e8e8' }}
            >
              <button id='openMobileFilters_Button' onClick={openModal} className={styles.fullButton}>
                <i className={classNames('icon', 'icon-tune', styles.mainFilterIcon)} />
                {t('txt_filters')}
              </button>
            </ReactPlaceholder>
            {selectedFilters.length ?
              <div className={styles.activeFilters}>
                {selectedFilters.length >= FILTERS_APPLIED_TO_SEE_DELETE_BUTTON && (
                  <Button id='resetMobileFilter_Button' deleteAllButton onClick={resetFilter}>
                    {t('txt_category_mobile_delete_all_filters')}
                  </Button>
                )}
                {selectedFilters.map(item => (
                  <div
                    id={`closeMobileFilter_${item.tag}`}
                    key={`bt_${item.value}`}
                    onClick={() => clickToFilter(item.tag)}
                    className={styles.filterSliderItem}
                  >
                    {item.tagTr}
                    <i className={classNames('icon', 'icon-cross', styles.filterCloseIcon)} />
                  </div>))}
              </div> : null}
          </div>
        )}
      </Translation>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, props: Props) => ({
  filters: state.category.filters,
  subCategories: categorySelectors.getSubCategories(state, props.categoryId, props.parentCategoryId),
  selectedFilters: (categorySelectors.getSelectedFiltersFromState(state)
    .map(item => findFilterbyTag(item, state.category.filters)) as Filter[]),
});


export default connect(mapStateToProps)(FiltersContainer);
