import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { EditorControls } from 'browser-editor';
import { Store } from '../../typings';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import getBabyIllustrationControl from '@categoryProduct/store/birthPosters/selector/getBabyIllustrationControl';
import changeBabyIllustration from './changeBabyIllustration';

const changeBabyFacing = (
  editor: EditorControls | null,
  babyIndex: number
) => (
  dispatch: ThunkDispatch<Store, void, AnyAction>,
  getState: () => Store
) => {
  const state = getState();

  const illustrationControl = getBabyIllustrationControl(state, babyIndex);
  if (!illustrationControl) {
    return;
  }

  const illustrationData = illustrationControl.data as apiTypings.AppBundle.Api.Entity.Design.V1.IBirthPosterIllustration;

  dispatch(changeBabyIllustration(editor, illustrationData, babyIndex));
};

export default changeBabyFacing;
