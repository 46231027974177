import { handleActions } from 'redux-actions';
import { types as categoryTypes } from '../category/actions';

const fetchStateReducer = handleActions<any>(
  {
    [categoryTypes.categoriesSet]: (
      state,
      { payload: {
        fetchState,
      } },
    ) => ({
      ...state,
      'categories': fetchState,
    }),
    [categoryTypes.paperFormatsSet]: (
      state,
      { payload: {
        fetchState,
      } },
    ) => ({
      ...state,
      'paperFormats': fetchState,
    }),
    [categoryTypes.frameFormatsSet]: (
      state,
      { payload: {
        fetchState,
      } },
    ) => ({
      ...state,
      'frameFormats': fetchState,
    }),
    [categoryTypes.frameAlternativesSet]: (
      state,
      { payload: {
        designs,
      } },
    ) => ({
      ...state,
      'frameAlternatives': designs,
    }),
    [categoryTypes.favouritesAdd]: (
      state,
      { payload: {
        fetchState,
      } },
    ) => (fetchState ? {
      ...state,
      'favourites': fetchState,
    } : state),
    [categoryTypes.designFamilySet]: (
      state,
      { payload: {
        categoryId,
        data: family,
      } },
    ) => ({
      ...state,
      [`family/${family.designs[0].familyId}`]: 'all',
    }),
    [categoryTypes.designFamiliesSet]: (
      state,
      { payload: {
        categoryId,
        fetchState,
      } },
    ) => ({
      ...state,
      [`category/${categoryId}`]: fetchState,
    }),
    [categoryTypes.similarProductsSet]: (
      state,
      { payload: {
        designId,
      } },
    ) => ({
      ...state,
      [`similarProducts/${designId}`]: 'all',
    }),
    [categoryTypes.designFamiliesDelete]: (
      state,
      { },
    ) => {
      const undefinedDeletedKeys = Object.keys(state)
        .filter(key => key.startsWith('family/') || key.startsWith('category/') || key.startsWith('similarProducts/'))
        .reduce((acc, key) => ({ ...acc, [key]: undefined }), {} as { [s: string]: any });

      return {
        ...state,
        ...undefinedDeletedKeys,
      };
    },
  },
  {},
);

export default fetchStateReducer;
