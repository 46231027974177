import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import classNames from 'classnames';
import { Store } from '@categoryProduct/store/typings';
import iconStyles from '@categoryProduct/component/commonStyles/icon.css';
import Text from '@categoryProduct/component/common/Text';
import getActiveSectionId from '@categoryProduct/store/controls/selector/getActiveSectionId';
import activateSection from '@categoryProduct/store/controls/operation/activateSection';
import isSectionValid from '@categoryProduct/store/controls/selector/isSectionValid';
import isSectionTouched from '@categoryProduct/store/controls/selector/isSectionTouched';
import deactivateSectionControls from '@categoryProduct/store/controls/operation/deactivateSectionControls';
import styles from './styles.css';

interface OwnProps {
  title: string;
  allowUserExpand?: boolean;
  section: string;
  expandIfSectionValid?: string;
  children: any;
}

interface Props extends OwnProps {
  isActive: boolean;
  prevSectionValid: boolean;
  touched: boolean;
  dispatch: Dispatch;
}

const SimpleSection = ({ title, allowUserExpand, prevSectionValid, section, isActive, dispatch, touched, children }: Props) => {
  useEffect(() => {
    return () => {
      dispatch(deactivateSectionControls(section));
    };
  }, []);

  const toggleSection = () => {
    if (allowUserExpand && prevSectionValid) {
      if (isActive) {
        return;
      }
      dispatch(activateSection(section));
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.titleBar, { touched, [styles.open]: isActive })}
        onClick={toggleSection}
      >
        <Text header6 bold className={styles.subCategoryText}>{title}</Text>
        <i
          className={classNames(iconStyles.icon, styles.accordionIcon, {
            [styles.open]: isActive,
          })}
        />
      </div>
      <div
        className={classNames(styles.content, styles.reviewsContainer, {
          [styles.open]: isActive,
          touched,
        })}
      >
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state: Store, { section, expandIfSectionValid }: OwnProps) => ({
  isActive: getActiveSectionId(state) === section,
  touched: isSectionTouched(state, section),
  prevSectionValid: expandIfSectionValid ? isSectionValid(state, expandIfSectionValid) : true,
});
export default connect(mapStateToProps)(SimpleSection);
