import { each, flatten, intersection, uniq } from 'lodash';
import apiTypings from '../../api/optimalprint-sdk';
import { DesignFamily, Design } from '@categoryProduct/typings';

export interface Design {
  categoryId?: number;
  designId: string;
  previewId: string;
  groupId: number;
  categoryName?: string;
  name?: string;
  designName?: string;
  designAuthorName?: string;
  productId: number;
  paperFormatId: number;
  productTypeId: number;
  color: string;
  hasMp: boolean;
  isFolded: boolean;
  isNew: boolean;
  tags: number[];
  totalPages: number;
  familyId?: number;
  id: number;
  spotFinishId: number;
  spotFinishes: number[];
  trimId: number;
  trims: number[];
}

export interface PaperFormat {
  paperFormatId: number;
  productTypeId: number;
  orientationId: number;
  isFolded: boolean;
  finalSize: string;
  systemName: string;
  name: string;
  icon: string;
}

export interface Category {
  categoryId: number;
  name: string;
  parentCategoryId: number;
  title: string;
  h1: string;
  banner: string;
  gradientColor1: string;
  gradientColor2: string;
  h1FontColor: string;
  h2FontColor: string;
  translatedName: string;
  dbName: string;
}

interface Response {
  families: DesignFamily[] | null;
  enabledTags: number[];
  selectedTags: number[];
}

export const filterFamilies = (
  families: DesignFamily[],
  groupedSelectedTags: number[][],
  availableFilters: Filters,
): Response => {
  // TODO Groups logic: in case this code is needed (don't know what is the logic of it)
  // const usedGroupIds: number[] = [];
  const result = families
    .map((item) => item.designs)
    .filter(item => item)
    .slice(0, families.length) as Design[][];
  const finalFamilyList = [] as Design[][];
  const selectedTags = groupedSelectedTags.reduce((acc, values) => [ ...acc, ...values ], []);
  let enabledTags = [] as number[];

  let tagsAvailability: number[][] = [];
  for (let i = 0; i < result.length; i += 1) {
    // const familyGroupIds = [];
    const familyDesigns = result[i];

    // get available tags for missing each set of filters one by one
    // like all tags if 1st group is missing provided 3 are selected
    // so we know what options from the 1st group can be selected/enabled
    const availableTagsByGroups = groupedSelectedTags.map((group, index) => {
      // get all other groups with tags except current one in the loop
      const otherGroupedFilters = groupedSelectedTags.filter((_, i) => i !== index);
      // filter designs
      const designsFilteredWithOtherGroups = familyDesigns.filter(design => {
        return otherGroupedFilters
          .every((tags: number[]) => Boolean(intersection(design.tags, tags).length));
      });
      // get all tags from the current result
      const availableTags = designsFilteredWithOtherGroups
        .reduce((acc, design) => [ ...acc, ...design.tags ], [] as number[])
        .filter(tag => Boolean(tag));

      return uniq(availableTags);
    });
    // collect them all
    tagsAvailability = availableTagsByGroups.map((tags, i) => uniq([ ...(tagsAvailability[i] || []), ...tags ]));

    // filtering with current set of filters
    const matchingDesigns = familyDesigns.filter(design => {
      // TODO Groups logic: in case this code is needed (don't know what is the logic of it)
      // const isNotDuplicatedGroupId = !design.groupId || usedGroupIds.indexOf(design.groupId) === -1;
      const matchedByTags = (
        !selectedTags.length
        || groupedSelectedTags.every((tags: number[]) => Boolean(intersection(design.tags, tags).length))
      );
      return Boolean(matchedByTags);
    });

    if (matchingDesigns.length) {
      // add all tags of the matched designs as enabled (as ready for further filtering)
      const matchingFamilyTags = matchingDesigns
        .reduce((acc, design) => [ ...acc, ...design.tags ], [] as number[])
        .filter(tag => Boolean(tag));
      // collect them all
      enabledTags = uniq([ ...enabledTags, ...matchingFamilyTags ]);

      // put this design as first one in family list
      const designs = uniq([ matchingDesigns[0], ...familyDesigns ]);
      finalFamilyList.push(designs);

      // TODO Groups logic: in case this code is needed (don't know what is the logic of it)
      // familyGroupIds.push(matchingDesigns[0].groupId);
      //
      // each(familyGroupIds, (id) => {
      //   if (id && usedGroupIds.indexOf(id) === -1) {
      //     usedGroupIds.push(id);
      //   }
      // });
    }
  }

  // remove selected filters that doesn't have results
  const areFilteredDesignsForTheTag = (tag: number) => flatten(finalFamilyList).some(d => d.tags.indexOf(tag) > -1);
  const selectedTagsNew = flatten(groupedSelectedTags).filter(areFilteredDesignsForTheTag);

  // get names of all selected groups
  const selectedFilterNames = (groupedSelectedTags
    .map((group: number[]) => findFilterKeyByTag(group[0], availableFilters))
    .filter((name: string | null) => Boolean(name)) as string[]);

  // add sibling of the selected filter to enabled once (respecting their availability with other filters)
  const enabledTagsNew = uniq([
    ...enabledTags,
    ...flatten(
      selectedFilterNames
        .map((name, index) => {
          // check if members of the selected group available with other group values
          return intersection(
            [ ...availableFilters[name].map((f: Filter) => f.tag) ],
            tagsAvailability[index],
          );
        }),
    ),
  ]);

  return {
    enabledTags: enabledTagsNew,
    selectedTags: selectedTagsNew,
    families: finalFamilyList.map((item) => ({ designs: item })),
  };
};

export const getSelectedFilters = (filters: Filters, selectedTags: number[]) => {
  const result: number[][] = [];
  each(Object.keys(filters), name => {
    const filterGroup = [];
    for (let i = 0; i < filters[name].length; i += 1) {
      if (selectedTags.indexOf(filters[name][i].tag) > -1) {
        filterGroup.push(filters[name][i].tag);
      }
    }

    if (filterGroup.length) {
      result.push(filterGroup);
    }
  });
  return result;
};

// TODO @imad this method looks wrong for this file
export const defaultFamilyWrapper = (
  designFamilies: apiTypings.AppBundle.Api.Entity.Design.V1.IDesignFamily[],
  page?: number,
  limit?: number,
): apiTypings.AppBundle.Api.Entity.Design.V1.IDesignFamily[] => {
  let newDesignFamilies = designFamilies;

  if (page && limit && page >= 0 && limit >= 0) {
    newDesignFamilies = newDesignFamilies.slice((page - 1) * limit);
    newDesignFamilies = newDesignFamilies.slice(0, limit);

    return newDesignFamilies.reduce(
      (acc, family, index) => {
        if (family.designs && family.designs.length === 0) return acc;

        const familyOffset = (page - 1) * limit + index;
        return {
          ...acc,
          [familyOffset]: {
            ...family,
          },
        };
      },
      {} as any,
    ) as apiTypings.AppBundle.Api.Entity.Design.V1.IDesignFamily[];
  }

  return designFamilies;
};

export const findFilterbyTag = (tag: number, filters: Filters): Filter | null => {
  let answer = null;
  each(Object.keys(filters), (v) => {
    if (filters[v].length) {
      const curTag = filters[v].find((item: Filter) => item.tag === tag);
      if (curTag) answer = curTag;
    }
  });
  return answer;
};

export const findFilterKeyByTag = (tag: number, filters: Filters): string | null => {
  return Object.keys(filters)
    .find(filterKey => filters[filterKey].some((item: Filter) => item.tag === tag)) || null;
};

export const remapSelectedFiltersToAvailableFilters = (
  availableFilters: Filters,
  selectedFilters: number[],
  enabledFilters: number[],
) => {
  const newAvailableFilters = {};
  const keys = Object.keys(availableFilters);
  each(keys, (key) => {
    newAvailableFilters[key] = [];
    each(availableFilters[key], (filter) => {
      const customFilter = filter;
      customFilter.isSelected = selectedFilters.includes(filter.tag);
      customFilter.isEnabled = enabledFilters.includes(filter.tag);
      newAvailableFilters[key].push(filter);
    });
  });
  return newAvailableFilters;
};

export const getEnabledAndSelectedFilters = (
  availableFilters: Filters,
) => {
  const selectedFilters: number[] = [];
  const enabledFilters: number[] = [];
  each(availableFilters, (filters) => {
    each(filters, (filter) => {
      filter.isSelected && selectedFilters.push(filter.tag);
      filter.isEnabled && enabledFilters.push(filter.tag);
    });
  });
  return { selectedFilters: uniq(selectedFilters), enabledFilters: uniq(enabledFilters) };
};
