import ActionTypes from '../actionTypes';

const registerInputControl = (name: string, sectionId: string, flatValue = true, valid = false, value?: any) => ({
  type: ActionTypes.REGISTER_INPUT_CONTROL,
  sectionId,
  name,
  valid,
  value,
  flatValue,
});

export default registerInputControl;
