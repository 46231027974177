import React from 'react';
import { connect } from 'react-redux';

import { categorySelectors } from '@categoryProduct/store/category';
import FilterItem from './FilterItem';

import styles from './FilterSlider.css';

interface Props {
  items: Filter[];
  enabledFilters: number[];
  selectedFilters: number[];
  clickToFilter: (tag: number) => void;
  inRow?: boolean;
}

const FilterSlider: React.FC<Props> = ({ items, enabledFilters, selectedFilters, clickToFilter, inRow }) => {
  const isOnlyOneEnabled = Boolean(
    items.length
    && items.filter(item => enabledFilters.indexOf(item.tag) >= 0).length === 1,
  );

  return (
    <div className={inRow ? styles.containerRow : styles.container}>
      {items.map(item => {
        const isDisabled = Boolean(enabledFilters.indexOf(item.tag) < 0);
        const isSelected = Boolean(selectedFilters.indexOf(item.tag) >= 0);
        return (
          <FilterItem
            key={`${item.tag}_${item.tagTr}`}
            icon={item.icon}
            title={item.shortTagTr || item.tagTr}
            color={item.color}
            value={item.value}
            name={item.filterName}
            onClick={() => clickToFilter(item.tag)}
            isDisabled={isDisabled}
            isSelected={isSelected || isOnlyOneEnabled && !isDisabled}
            isOnlyEnabled={isOnlyOneEnabled && !isDisabled}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  selectedFilters: categorySelectors.getSelectedFiltersFromState(state),
  enabledFilters: categorySelectors.getEnabledFiltersFromState(state),
});

export default connect(mapStateToProps)(FilterSlider);
