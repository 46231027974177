import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@categoryProduct/component/common/Text';
import styles from './index.css';

const ProductPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.productPlaceholder}>
      <div className={styles.spinnerMain}>
        <div className={styles.spinner} />
      </div>
    </div>
  );
};

export default ProductPlaceholder;
