import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './store';

// eslint-disable-next-line no-underscore-dangle
const composeWithDevTools = typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = composeWithDevTools
  ? composeWithDevTools({ maxAge: 50 })
  : compose;

const configureStore = (preloadedState: any) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk)),
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./store', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./store').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
