import { UpsellOptions } from '@categoryProduct/typings';

export const getAvailableTrims = (upsellOptions: UpsellOptions) => {
  return Object.keys(upsellOptions).map(v => Number(v));
};

export const getAvailableSpotFinishes = (upsellOptions: UpsellOptions) => {
  return Object.keys(upsellOptions)
    .reduce((acc: number[], id: string) => {
      upsellOptions[id].forEach((spotId: number) => {
        if (acc.indexOf(Number(spotId)) === -1) {
          acc.push(spotId);
        }
      });
      return acc;
    }, []);
};

export const getTrimsForSelectedSpotFinishId = (upsellOptions: UpsellOptions, selectedSpotFinishId: number) =>
  Object.keys(upsellOptions)
    .map(v => Number(v))
    .filter((trimId: number) => upsellOptions[trimId].indexOf(selectedSpotFinishId) >= 0);

export const getSpotFinishesForSelectedTrimId = (upsellOptions: UpsellOptions, selectedTrimId: number) =>
  upsellOptions[selectedTrimId];

export const isOnlyDefaultUpsellValue = (upsellValues: number[]) =>
  upsellValues && upsellValues.length === 1 && Number(upsellValues[0]) === 1;

export const getOrderedUpsellIds = (ids: number[], noneOptionValue: number = 1) => {
  const sortedIds = ids.sort((a, b) => (a - b));
  return sortedIds[0] === noneOptionValue ? [ ...sortedIds.slice(1), noneOptionValue ] : sortedIds;
};

export const addNoneOption = (ids: number[], noneOptionValue: number = 1) => {
  return ids.indexOf(noneOptionValue) >= 0 ? ids : [ ...ids, noneOptionValue ];
};

export const getOrderedUpsellObjects =
  (values: Filter[]) => {
  if (!values.length) {
    return values;
  }

  const compare = (a: Filter, b: Filter) => {
    if (a.value < b.value) {
      return -1;
    } else if (a.value > b.value) {
      return 1;
    }

    return 0;
  };
  const sortedValues = values.sort(compare);
  return Number(sortedValues[0].value) === 1 ? [ ...sortedValues.slice(1), sortedValues[0] ] : sortedValues;
};
