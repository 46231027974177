import {
  JsonDesign,
  JsonDesignPage,
  JsonImageElement,
  JsonTextElement,
  JsonTextElementPlaceholder,
} from 'browser-editor';

interface EditData {
  step1: BabySectionDataReduced;
  step2: BabySectionData;
  step3?: BabySectionData;
  step4?: BabySectionData;
}

interface BabySectionDataReduced {
  background: string;
  babyFacing?: string;
  illustration?: string;
}

interface BabySectionData {
  babyName: string;
  babyBirthDate: string;
  birthHour: string;
  birthMin: string;
  birthAmPm: string;
  babyLength: string;
  babyLengthUnits: string;
  babyWeightUnits: string;
  babyWeightGrams: string;
  babyWeightPounds: string;
  babyWeightOunces: string;
  babyFacing: string;
  illustration: string;
}

const BABY_NAME_START = 'birthPosterBaby_';

const stripLetters = (text: string) => text.replace(/[^0-9\\.\s]*/g, '').replace(/\s+/, ' ').trim();
const getOnlyLetters = (text: string) => text.replace(/[^[a-zA-Z]]*/g, '').replace(/\s+/, ' ').trim();

const isBabyImage = (name: string) => {
  return name.indexOf(BABY_NAME_START) === 0;
};

const getBackgroundColor = (page: JsonDesignPage) => {
  return page.color;
};

const getPlaceholders = (element: JsonTextElement) => {
  return element.plva || [];
};

const getSectionName = (element: JsonImageElement) => {
  const babyData = element.bpbd;

  if (babyData) {
    const elementIndex = Number(babyData.n);
    return `step${Number(elementIndex) + 1}`;
  }

  return null;
};

const getSingleBabyValues = (element: JsonImageElement) => {
  const babyData = element.bpbd;

  if (babyData) {
    return {
      babyName: '',
      babyBirthDate: '',
      birthHour: '',
      birthMin: '',
      birthAmPm: '',
      babyLength: stripLetters(babyData.l),
      babyLengthUnits: babyData.lu,
      babyWeightUnits: '',
      babyWeightGrams: '',
      babyWeightPounds: '',
      babyWeightOunces: '',
      babyFacing: Number(babyData.ifr) ? 'Right' : 'Left',
      illustration: babyData.k,
    };
  }

  return null;
};

const getPlaceholder = (name: string, placeholders: JsonTextElementPlaceholder[]) => {
  const match = placeholders.filter(e => e.p === name)[0];
  return match ? match.v : '';
};

const applyPlaceholders = (babyIndex: number, data: BabySectionData, placeholders: JsonTextElementPlaceholder[]) => {
  const babyPlaceholders = placeholders.filter(e => e.p.indexOf(String(babyIndex)) >= 0);
  const time = getPlaceholder(`time_${babyIndex}`, babyPlaceholders);
  const hours = time.split(':')[0] || '';
  const mins = time.split(':')[1] || '';
  const weight = getPlaceholder(`weight_${babyIndex}`, babyPlaceholders);
  const weightArr = stripLetters(weight).split(' ');

  return {
    ...data,
    babyName: getPlaceholder(`name_${babyIndex}`, babyPlaceholders),
    babyBirthDate: getPlaceholder(`date_${babyIndex}`, babyPlaceholders),
    birthHour: stripLetters(hours),
    birthMin: stripLetters(mins),
    birthAmPm: getOnlyLetters(mins),
    babyWeightUnits: weightArr.length > 1 ? 'i' : 'm',
    babyWeightGrams: weightArr.length > 1 ? '' : stripLetters(weightArr[0]),
    babyWeightPounds: weightArr.length > 1 ? stripLetters(weightArr[0]) : '',
    babyWeightOunces: weightArr.length > 1 ? stripLetters(weightArr[1]) : '',
  };
};

export default (designStructure: JsonDesign): EditData => {
  const page = designStructure.structure[0];
  const placeholders: JsonTextElementPlaceholder[] = [];

  const result: EditData = {
    step1: {
      background: getBackgroundColor(page),
    },
    step2: {} as BabySectionData,
  };

  page.elements.forEach((element) => {
    if (element.t === 'image' && isBabyImage(element.n)) {
      const babyValues = getSingleBabyValues(element as JsonImageElement);
      const sectionName = getSectionName(element as JsonImageElement);

      if (sectionName && babyValues) {
        result[sectionName] = babyValues;
      }
    }

    if (element.t === 'text') {
      placeholders.push(...getPlaceholders(element as JsonTextElement));
    }
  });

  // apply placeholders (as only at this point we have full list of them)
  [ 2, 3, 4 ].forEach((stepIndex: number) => {
    const sectionName = `step${stepIndex}`;
    const data = result[sectionName];

    if (data) {
      result[sectionName] = applyPlaceholders(stepIndex - 1, data, placeholders);
    }
  });

  return result;
};
