import { Dispatch } from 'redux';

import * as actions from './actions';
import { getFrameFormats } from './selectors';

const findAndSetFrameAlternatives = (
  encPublicDesignId: string
) => (
  dispatch: Dispatch,
  getState: () => any
) => {
  const state = getState();
  const framesData = getFrameFormats(state);
  if (!framesData || framesData.length === 0) {
    return;
  }
  const alternativeDesigns = framesData.find((frameSizeGroup: any) => frameSizeGroup.designs.find((design: any) => design.encPublicDesignId === encPublicDesignId));
  if (alternativeDesigns) {
    dispatch(actions.frameAlternativesSet({
      designs: alternativeDesigns.designs,
    }));
  }
};

export default findAndSetFrameAlternatives;
