// TODO: remove in favor of react header
// this is a horrible way to update counts
// but it is the way done right now in the old header
// TO BE REPLACED IN FUTURE WITH PROPER COMMUNICATION WITH THE HEADER COMPONENT

export const setCounterBySelector = (selector: string, count: number, overwrite?: boolean) => {
  if (typeof document === 'undefined') {
    return;
  }

  // convert to real array for IE11 support
  const elements = [].slice.call(document.querySelectorAll(selector));
  if (!elements.length) {
    return;
  }

  elements.forEach((element: HTMLElement) => {
    const currentBasketCount = parseInt(element.innerHTML, 10) || 0;
    const newBasketCount = overwrite ? count : currentBasketCount + count;
    element.innerHTML = String(newBasketCount);

    if (newBasketCount === 0) {
      element.setAttribute('style', 'display: none;');
    } else {
      element.setAttribute('style', '');
    }
  });
};


export const basketCounterSelectors = [ '.op-icon-shopping-cart .op-header_acc-item_bubble', '.js-cart-counter .badge' ];
export const favouriteCounterSelectors = [ '.op-icon-heart-filled .op-header_acc-item_bubble', '.js-favorites-count' ];

export const setBasketItemsCount = (count: number) => {
  const selector = '.js-cart-counter';
  // convert to real array for IE11 support
  const mobileMenuBasketLink = [].slice.call(document.querySelectorAll(selector));
  mobileMenuBasketLink.forEach((element: HTMLElement) => {
    if (!element.querySelector('.badge')) {
      element.innerHTML = `${element.innerHTML} <span class="badge" style="display: none;">${count}</span>`;
    }
  });

  setCounterBySelector(`${selector} .badge`, count, true);
};

export const incrementBasketItemsCount = () => basketCounterSelectors.forEach(selector => setCounterBySelector(selector, 1));

export const setFavouritesCount = (count: number) => favouriteCounterSelectors.forEach(selector => setCounterBySelector(selector, count, true));

export const incrementFavouritesCount = () => favouriteCounterSelectors.forEach(selector => setCounterBySelector(selector, 1));

export const decrementFavouritesCount = () => favouriteCounterSelectors.forEach(selector => setCounterBySelector(selector, -1));
