import { AnyAction } from 'redux';
import { DesignState, DesignStatus, DesignType } from '../types';

const loadPublicDesign = (state: DesignState, { designId }: AnyAction) => ({
  ...state,
  designId,
  designData: undefined,
  designType: DesignType.PUBLIC_DESIGN,
  designStatus: DesignStatus.DESIGN_LOADING,
});

export default loadPublicDesign;
