import React, { useContext } from 'react';
import { Translation } from 'react-i18next';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import Text from '@categoryProduct/component/common/Text';
import formatDate from "@categoryProduct/util/formatData";
import EditorControlsContext from '@categoryProduct/util/context/EditorControlsContext';
import BabyIndexContext from '@categoryProduct/util/context/BabyIndexContext';
import updatePosterPlaceholder from '@categoryProduct/store/birthPosters/operation/updatePosterPlaceholder';
import SimpleInput from '../../Controls/SimpleInput';
import styles from './index.css';
import { Store } from '@categoryProduct/store/typings';

interface OwnProps {
  section: string;
}

interface Props extends OwnProps {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const controlName = 'babyBirthDate';

const BabyBirthDateInput = ({section, dispatch} : Props) => {
  const initialValue = formatDate(new Date());

  const editorControls = useContext(EditorControlsContext);
  const babyIndex = useContext(BabyIndexContext);
  const validator = (v: string) => { return Boolean(v && v.trim().length > 0); };
  const onChange = (value: string, isValid: boolean) => {
    if (editorControls) {
      dispatch(updatePosterPlaceholder(editorControls, section, babyIndex));
    }
  };

  return (
    <>
      <Translation>
        {t => (
          <div
            className={styles.birthDate}
          >
            <Text darkBlue>{t('txt_date_of_birth')}:</Text>
            <div className={styles.inputs}>
              <SimpleInput
                className={styles.fullDate}
                initialValue={initialValue}
                validator={validator}
                onChange={onChange}
                section={section}
                name={controlName}
              />
            </div>
          </div>
        )}
      </Translation>
    </>
  );
};

export default connect()(BabyBirthDateInput);
