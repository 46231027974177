const ProductType = {
    BUSINESS_CARDS: 1,
    LETTERHEADS: 2,
    CORRESPONDENCE_CARDS: 3,
    INVITATIONS: 4,
    GREETING_CARDS: 5,
    PLACEMENT_CARDS: 6,
    POSTCARDS: 7,
    BROCHURES: 9,
    WEDDING_INVITATION: 10,
    FOLDED_POSTCARDS: 17,
    FOLDERS: 18,
    APPOINTMENT_CARDS: 21,
    DRIVING_INSTRUCTIONS: 22,
    CHURCH_PROGRAM: 23,
    MENU: 25,
    TYPE_PLACE_CARD: 26,
    THANK_YOU_CARD: 27,
    TYPE_ENVELOPES: 28,
    TYPE_BLANK_ENVELOPES: 29,
    UPLOAD_PRODUCT: 30,
    RSVP_CARDS: 32,
    CUSTOM_DESIGN_SERVICE: 33,
    UPLOAD_PRODUCT_150G: 36,
    PRINTED_INVOICE_FEE: 37,
    INVOICE_FEE: 34,
    PEN: 38,
    FLYERS: 47,
    TYPE_COATING: 48,
    WALL_CALENDAR: 52,
    LABEL_ADDRESS: 53,
    POSTERS: 54,
    LABEL_CLOTHES: 55,
    LABEL_MARKET: 56,
    BC_HOLDER: 57,
    PHOTOBOOK: 58,
    PHOTOBOOK_COVER: 59,
    LABEL_ADDRESS_BUSINESS: 60,
    POSTERS_BUSINESS: 61,
    POSTCARDS_BUSINESS: 62,
    INVITATIONS_BUSINESS: 63,
    TYPE_VARIABLE_DATA_FEE: 66,
    TYPE_MANUALLY_LOOK_FEE: 68,
    TYPE_PREMIUM_PAPER: 70,
    TYPE_BLANK_ENVELOPES_WITH_PRINT: 72,
    BAPTISM_INVITATIONS: 73,
    CONFIRMATION_INVITATIONS: 74,
    BIRTHDAY_ANNOUNCEMENT_CARDS: 75,
    TYPE_PREMIUM: 76,
    WALL_CALENDAR_WITH_HOOK_PRODUCT_TYPE_ID: 77,
    PHOTOBOOK_HARDCOVER: 78,
    FLYPBOOK_SOFTCOVER: 79,
    FLYPBOOK_HARDCOVER: 80,
    POSTERS_LARGE: 81,
    FLYPBOOK_SERIES_SOFTCOVER: 82,
    SUBSTRATE_STANDARD: 83,
    ARTISTIC_POSTERS: 84,
    FAMILY_PLANNER_CALENDAR: 85,
    SPOT_FINISH: 86,
    TRIM: 87,
    CANVAS_SLIM: 88,
    CANVAS_PREMIUM: 89,
    CANVAS_MEDIUM: 90,
    MUG_SIMPLE_PHOTO: 91,
    MUG_WRAP_AROUND: 92,
    STICKER_GIFT: 93,
    STICKER_RETURN_ADDRESS: 94,
    STICKER_ADDRESS: 95,
    BIRTH_POSTER: 96,
    NOTEBOOK_SOFTBACK: 97,
    NOTEBOOK_SPIRAL: 98,
    ACTIVITY_POSTER: 99,
    POSTER_FRAME: 100,
    POSTER_HANGER: 101,
};

export const substrates = [
    ProductType.SUBSTRATE_STANDARD,
    ProductType.TYPE_PREMIUM_PAPER,
    ProductType.TYPE_COATING,
];

export const addressLabelsIds = [
    ProductType.LABEL_ADDRESS,
    ProductType.LABEL_ADDRESS_BUSINESS,
];

export const photoBookIds = [
    ProductType.PHOTOBOOK_HARDCOVER,
    ProductType.PHOTOBOOK,
];

export const flypBookIds = [
    ProductType.FLYPBOOK_SOFTCOVER,
    ProductType.FLYPBOOK_HARDCOVER,
    ProductType.FLYPBOOK_SERIES_SOFTCOVER,
];

export const customPhotobooksIds = [
    ProductType.FLYPBOOK_SOFTCOVER,
    ProductType.FLYPBOOK_HARDCOVER,
    ProductType.PHOTOBOOK_HARDCOVER,
    ProductType.PHOTOBOOK,
];

export const photoBookFlypBookIds = [
    ProductType.FLYPBOOK_SOFTCOVER,
    ProductType.FLYPBOOK_HARDCOVER,
    ProductType.PHOTOBOOK_HARDCOVER,
    ProductType.PHOTOBOOK,
    ProductType.FLYPBOOK_SERIES_SOFTCOVER,
];

export const photobooksSoftCover = [
    ProductType.PHOTOBOOK,
    ProductType.FLYPBOOK_SOFTCOVER,
    ProductType.FLYPBOOK_SERIES_SOFTCOVER,
];

export const photobooksSeries = [
    ProductType.FLYPBOOK_SERIES_SOFTCOVER,
];

export const photobooksHardCover = [
    ProductType.PHOTOBOOK_HARDCOVER,
    ProductType.FLYPBOOK_HARDCOVER,
];

export const wallCalendarIds = [
    ProductType.WALL_CALENDAR,
    ProductType.WALL_CALENDAR_WITH_HOOK_PRODUCT_TYPE_ID,
    ProductType.FAMILY_PLANNER_CALENDAR,
];

export const wallCalendarWithoutPlannerIds = [
    ProductType.WALL_CALENDAR,
    ProductType.WALL_CALENDAR_WITH_HOOK_PRODUCT_TYPE_ID,
];

export const wallCalendarWithHookIds = [
    ProductType.WALL_CALENDAR_WITH_HOOK_PRODUCT_TYPE_ID,
    ProductType.FAMILY_PLANNER_CALENDAR,
];

export const editablePostersIds = [
    ProductType.POSTERS,
    ProductType.POSTERS_BUSINESS,
    ProductType.POSTERS_LARGE,
];

export const postersIds = [
    ProductType.POSTERS,
    ProductType.POSTERS_BUSINESS,
    ProductType.POSTERS_LARGE,
    ProductType.ARTISTIC_POSTERS,
];

export const allowedOneCard = [
    ProductType.WALL_CALENDAR,
    ProductType.WALL_CALENDAR_WITH_HOOK_PRODUCT_TYPE_ID,
    ProductType.FAMILY_PLANNER_CALENDAR,
    ProductType.POSTERS,
    ProductType.POSTERS_LARGE,
    ProductType.ARTISTIC_POSTERS,
    ProductType.POSTCARDS_BUSINESS,
    ProductType.TYPE_PLACE_CARD,
    ProductType.PHOTOBOOK,
    ProductType.PHOTOBOOK_HARDCOVER,
    ProductType.FLYPBOOK_HARDCOVER,
    ProductType.FLYPBOOK_SOFTCOVER,
    ProductType.FLYPBOOK_SERIES_SOFTCOVER,
];

export const allowedQuantityOne = [
    ProductType.WALL_CALENDAR,
    ProductType.WALL_CALENDAR_WITH_HOOK_PRODUCT_TYPE_ID,
    ProductType.FAMILY_PLANNER_CALENDAR,
    ProductType.POSTERS,
    ProductType.ARTISTIC_POSTERS,
    ProductType.POSTERS_LARGE,
    ProductType.POSTCARDS_BUSINESS,
    ProductType.TYPE_PLACE_CARD,
    ProductType.PHOTOBOOK,
    ProductType.PHOTOBOOK_HARDCOVER,
    ProductType.FLYPBOOK_HARDCOVER,
    ProductType.FLYPBOOK_SOFTCOVER,
    ProductType.FLYPBOOK_SERIES_SOFTCOVER,
    ProductType.TYPE_PREMIUM,
    ProductType.INVOICE_FEE,
    ProductType.PRINTED_INVOICE_FEE,
    ProductType.TYPE_VARIABLE_DATA_FEE,
    ProductType.TYPE_MANUALLY_LOOK_FEE,
    ProductType.TYPE_BLANK_ENVELOPES,
];

export const businessCardProducts = [
    ProductType.BUSINESS_CARDS,
];

export const cardProducts = [
    ProductType.BUSINESS_CARDS,
    ProductType.CORRESPONDENCE_CARDS,
    ProductType.INVITATIONS,
    ProductType.GREETING_CARDS,
    ProductType.POSTCARDS,
    ProductType.WEDDING_INVITATION,
    ProductType.APPOINTMENT_CARDS,
    ProductType.DRIVING_INSTRUCTIONS,
    ProductType.CHURCH_PROGRAM,
    ProductType.MENU,
    ProductType.TYPE_PLACE_CARD,
    ProductType.THANK_YOU_CARD,
    ProductType.RSVP_CARDS,
    ProductType.POSTCARDS_BUSINESS,
    ProductType.INVITATIONS_BUSINESS,
    ProductType.BAPTISM_INVITATIONS,
    ProductType.CONFIRMATION_INVITATIONS,
    ProductType.BIRTHDAY_ANNOUNCEMENT_CARDS,
];

export const productDesigner = [
    ProductType.PHOTOBOOK,
    ProductType.PHOTOBOOK_HARDCOVER,
    ProductType.FLYPBOOK_SOFTCOVER,
    ProductType.FLYPBOOK_HARDCOVER,
    ProductType.WALL_CALENDAR,
    ProductType.WALL_CALENDAR_WITH_HOOK_PRODUCT_TYPE_ID,
    ProductType.FAMILY_PLANNER_CALENDAR,
    ProductType.FLYPBOOK_SERIES_SOFTCOVER,
];

export const requireUnitLabel = [
    ProductType.WALL_CALENDAR,
    ProductType.POSTERS,
    ProductType.POSTERS_LARGE,
    ProductType.ARTISTIC_POSTERS,
];

export const singlePaperProduct = [
    ProductType.WALL_CALENDAR,
    ProductType.WALL_CALENDAR_WITH_HOOK_PRODUCT_TYPE_ID,
    ProductType.FAMILY_PLANNER_CALENDAR,
    ProductType.POSTERS,
    ProductType.ARTISTIC_POSTERS,
    ProductType.POSTERS_LARGE,
    ProductType.PHOTOBOOK,
    ProductType.PHOTOBOOK_HARDCOVER,
    ProductType.FLYPBOOK_HARDCOVER,
    ProductType.FLYPBOOK_SOFTCOVER,
];

export const placeCards = [
    ProductType.PLACEMENT_CARDS,
    ProductType.TYPE_PLACE_CARD,
];

export const nonPersonalisedProducts = [
    ProductType.ARTISTIC_POSTERS,
    ProductType.ACTIVITY_POSTER,
    ProductType.POSTER_FRAME,
    ProductType.POSTER_HANGER,
];

export default ProductType;
