import { combineReducers } from 'redux';
import category from './category';
import price from './price';
import ui from './ui';
import request from './request';
import birthPosters from './birthPosters/reducer';
import controls from './controls/reducer';
import design from './design/reducer';

export default combineReducers({
  category,
  price,
  ui,
  request,
  birthPosters,
  controls,
  design,
});
