import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import get from 'lodash/get';

import endpoints from './endpoints';
import global from '../util/global';

const isServer = typeof window === 'undefined';

const UNAUTHORIZED = 401;

const clientInfo = {
  platform: 5,
};

// init memory and diskCache cache
const defaultTtlMs = 5 * 60 * 1000; // 5mn
const MaxPayloadSizeForMemoryCache = 1e6;
// const MaxCachedPayloadsInMemory = 1000;

const memoryCache = undefined as any;
let diskCache = undefined as any;

const requestsWaitingResponse = {} as { [key: string]: Promise<any> };

if (isServer) {
  const fs = require('fs');
  const cacheManager = require('cache-manager');
  const fsStore = require('cache-manager-fs-hash');

  const casheDir =  process.env.CACHE_PATH || 'apiCache';

  fs.mkdirSync(casheDir, { recursive: true });

  diskCache = cacheManager.caching({
    store: fsStore,
    options: {
      path: casheDir,
      ttl: defaultTtlMs,
      subdirs: false,
    },
  });

  // const LRU = require('lru-cache');
  // memoryCache = new LRU({
  //   max: MaxCachedPayloadsInMemory,
  //   maxAge: defaultTtlMs,
  // });
}

const getExpiringFromResponseHeader = (res: AxiosResponse) => {
  const expires = res.headers['expires'];
  return expires ? Date.parse(expires) - Date.now() : defaultTtlMs;
};

async function cachedAxios(
  config: AxiosRequestConfig,
  requestId: string,
  requestStart: Date,
  logger: Console | {
    [key: string]: (args: string) => void,
  },
): Promise<any> {
  const cacheKey = JSON.stringify(config);
  const cacheOnServer = isServer && config.method && config.method.toUpperCase() === 'GET';

  let source;
  let result;


  if (cacheOnServer) {
    do {
      // Try fetch from memory cache
      result = memoryCache && memoryCache.get(cacheKey);

      if (result) {
        source = 'memory';
        break;
      }

      // Try fetch from disck cache
      result = await diskCache.get(cacheKey);
      if (result) {
        // memory cache gets busted when we exceed the number of payloads allowed or when we restart the process
        // cache back in memory when necessary
        if (memoryCache && JSON.stringify(result).length < MaxPayloadSizeForMemoryCache) {
          memoryCache.set(
            cacheKey,
            result,
            getExpiringFromResponseHeader(result),
          );
        }

        source = 'disk';
        break;
      }
    } while (false);
  }

  if (!result) {
    // Try fetch from HTTP
    delete config.adapter;
    source = 'http';

    try {
      const req = requestsWaitingResponse[cacheKey] || Axios(config);
      requestsWaitingResponse[cacheKey] = req;

      const response = await req;
      delete requestsWaitingResponse[cacheKey];

      result = {...response};
      delete result.config;
      delete result.request;
    } catch (err) {
      delete requestsWaitingResponse[cacheKey];
      return err.response;
    }

    const expiresIn = getExpiringFromResponseHeader(result);

    if (cacheOnServer && expiresIn > 0) {
      if (memoryCache && JSON.stringify(result).length < MaxPayloadSizeForMemoryCache) {
        memoryCache.set(cacheKey, result, expiresIn);
      }

      diskCache.set(cacheKey, result, {
        ttl: expiresIn,
      });
    }
  }

  const requestTime = new Date().getTime() - requestStart.getTime();
  logger.debug(`API RESPONSE #${requestId}: ${requestTime}ms from ${source}`);

  return result;
}

// const checkResponse = (response: any, keepSession: any, url: string) =>
//   new Promise(async (resolve, reject) => {
//     const shouldTryToRefreshToken =
//       response.status === UNAUTHORIZED &&
//       !url.includes(endpoints.login) &&
//       !url.includes(endpoints.externalLogin) &&
//       !url.includes(endpoints.recoveryRequest) &&
//       !url.includes(endpoints.recoveryChangePassword) &&
//       !url.includes(endpoints.register);

//     if (shouldTryToRefreshToken) {
//       reject();
//     } else {
//       resolve(response);
//     }
//   });

let startRequestNumber = 0;
const apiRequest = (
  url: string,
  data: string | any,
  method: string = 'GET',
  resultField: string,
  keepSession: boolean = true,
  maxRetries: number = 3,
) =>
  new Promise(
    async (resolve, reject): Promise<any | any[]> => {

      let loggedSuccessfullyAlready = false;

      let logger: any;
      if (isServer) {
        logger = require('express-logger-unique-req-id').getLogger();
      } else {
        logger = console;
      }

      startRequestNumber += 1;

      const requestNumber = startRequestNumber;
      let retry = 0;

      let newUrl: string;

      if (url.indexOf('http') === 0) {
        newUrl = url;
      } else {
        const apiRoot =
          method.toUpperCase() === 'GET'
            ? global('apiRootGet')
            : global('apiRootPost');

        const isOldAPI = /app-api/.test(url);
        const finalApiRoot = isOldAPI ? apiRoot.replace(/\/[a-z]{2}$/, '') : apiRoot;
        newUrl = finalApiRoot + url;
      }

      const options: AxiosRequestConfig = {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (isServer) {
        options.headers['Accept-Encoding'] = 'gzip';
        options.headers['User-Agent'] = 'gelatoservice-op-category-product';
      }

      if (method === 'POST') {
        const cookie = global('cookie');

        if (isServer && cookie) {
          options.headers.Cookie = cookie;
        }

        options.data = JSON.stringify(
          Object.assign({}, data, {
            clientInfo,
          }),
        );
      } else if (typeof data === 'string' && data) {
        // FIXME: @Mikhail implment that it uses some of querystring library to concatanate it correctly
        if (url.indexOf('?') !== -1) {
          newUrl += `&${data}`;
        } else {
          newUrl += `?${data}`;
        }
      } else if (data) {
        newUrl += `?${Object.keys(data)
          .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
          )
          .join('&')}`;
      }

      const isUnauthorized = (r?: { status: number }) => r && r.status === UNAUTHORIZED;
      const request = async () => {
        retry += 1;

        const requestId = `${requestNumber}/${retry}: ${newUrl}`;
        const requestStart = new Date();

        logger.debug(`API REQUEST #${requestId} ${JSON.stringify(data)}`);

        try {
          const result: any = await cachedAxios(
            {
              url: newUrl,
              ...options,
            },
            requestId,
            requestStart,
            logger,
          ); // .then(response => checkResponse(response, keepSession, newUrl));

          // login as a guest
          if (!loggedSuccessfullyAlready && isUnauthorized(result)) {
            await apiRequest(endpoints.registerGuest, {
              keepLogin: true,
            }, 'POST', 'data').then((data: any) => {

              // store refresh token to cookies for API to work
              if (typeof document !== 'undefined') {
                document.cookie = `RTC=${data.refreshToken.token};path=/;expires=${new Date(data.refreshToken.expiresAt).toUTCString()}`;
              }
              loggedSuccessfullyAlready = true;
            });

            request();
            return;
          }

          if (!result) {
            throw 'no response';
          }

          if (result.data && result.data.error && (result.data.error.code || result.data.error.message)) {
            let error = result.data.error;

            if (error) {
              error = {
                ...result.error,
                details: JSON.stringify(error.details),
                trace: JSON.stringify(error.trace),
              };
            } else {
              error = result.errorMessage;
            }

            throw (error);
          } else {
            resolve(get(result.data, resultField));
          }
        } catch (e) {
          const error = `API ERROR #${requestId}`;
          logger.warn(error);

          if (retry < maxRetries) {
            setTimeout(request, retry * 1000);
          } else {
            reject( `${error}: ${JSON.stringify(e)}`);
          }
        }
      };

      // noinspection JSIgnoredPromiseFromCall
      request();
    },
  );

export default apiRequest;
