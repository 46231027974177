import React from 'react';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';

import styles from './styles.css';

interface Props {
  thumbUrl: string;
  linkTo: string;
  isFoilAvailable?: boolean;
  isNew?: boolean;
}

const ThumbLikeSection: React.FC<Props> = React.memo(({ thumbUrl, linkTo, isFoilAvailable, isNew }) => (
  <Translation>
    {t => (
      <Link
        id={`thumbFooterCarousel_link_${linkTo}`}
        to={linkTo}
        key={`${thumbUrl}_TLSL`}
      >
        <div className={styles.likeThumbnail}>
          {(isFoilAvailable || isNew) ?
            <div className={styles.labelWrapper}>
              {isNew ? <div className={styles.newLabel}>{t('txt_new')}</div> : null}
              {isFoilAvailable ? <div className={styles.foilLabel}>{t('txt_with_foil')}</div> : null}
            </div> : null
          }
          <img src={thumbUrl} alt='' />
        </div>
      </Link>
    )}
  </Translation>
));

export default ThumbLikeSection;
