import { AnyAction } from 'redux';
import { ControlsState } from '../types';

const setInputControlValue = (state: ControlsState, { name, sectionId, value, valid }: AnyAction) => ({
  ...state,
  sections: {
    ...state.sections,
    [sectionId]: {
      ...state.sections[sectionId],
      [name]: {
        ...state.sections[sectionId][name],
        value,
        valid,
        active: true,
      },
    },
  },
});

export default setInputControlValue;
