import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import memoize from 'memoize-one';
import isDeepEqual from 'lodash.isequal';

import styles from './Root.css';
import { categorySelectors } from '../../store/category';
import { composeRoutes } from '../../routes/routes';

import apiTypings from '../../api/optimalprint-sdk';

interface Props extends RouteComponentProps<any> {
  categories: { [s: number]: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo };
}

const animationClass = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive,
};

class Root extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return (
      !isDeepEqual(nextProps.location, this.props.location) ||
      !isDeepEqual(nextProps.categories, this.props.categories)
    );
  }

  renderRoutes = memoize((categories: any) => {
    const routes = composeRoutes(categories);

    return (
      <React.Fragment>
        {
          routes
            .map(({ path, Component, exact, ...params }) => (
                <Route
                  key={path}
                  path={path}
                  exact={exact}
                >
                  {(props) => (
                    <CSSTransition
                      in={props.match != null}
                      timeout={300}
                      classNames={animationClass}
                      unmountOnExit
                    >
                      {props.match ? <Component {...props} {...params} key={props.match.url} /> : <div />}
                    </CSSTransition>
                  )}
                </Route>
              ),
            )
        }
      </React.Fragment>
    );
  }, isDeepEqual);

  render() {
    const { location, categories } = this.props;
    return (
      <div className={styles.home}>
        <Switch location={location}>
          {this.renderRoutes(categories)}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  categories: categorySelectors.getCategories(state),
});


export default connect(mapStateToProps)(withRouter(Root));
