import { AnyAction } from 'redux';
import { ControlsState } from '../types';

const deactivateInputControl = (state: ControlsState, { name, sectionId }: AnyAction) => ({
  ...state,
  sections: {
    ...state.sections,
    [sectionId]: {
      ...state.sections[sectionId],
      [name]: {
        ...state.sections[sectionId][name],
        active: false,
      },
    },
  },
});

export default deactivateInputControl;
