import React from 'react';
import classnames from 'classnames/bind';
// @ts-ignore
import filled from './trustpilot-star-filled.png';
// @ts-ignore
import empty from './trustpilot-star-empty.png';

import styles from './styles.css';

const cn = classnames.bind(styles);

interface Props {
  price?: string | number | undefined;
  discountPrice?: string | number;
  vatLabel?: string | undefined;
  envelopesLabel?: string | undefined;
  shipmentLabel?: string | undefined;
  deliveryPrice?: string | number;
  trustPilotStarsCount?: number | undefined;
  trustPilotReviewsCount?: number | string | undefined;
  showEnvelopesNote?: boolean;
}

const Price: React.FC<Props> = ({ price, discountPrice, deliveryPrice, vatLabel, envelopesLabel, shipmentLabel, trustPilotStarsCount, trustPilotReviewsCount, showEnvelopesNote }) => {
  const isDiscount = discountPrice && discountPrice !== price;
  return (
    <React.Fragment>
      <div className={cn('headContainer')}>
        {price && (
          <div>
            <span>
              <span className={cn('price', { crossed: isDiscount })}>{price}</span>
              {isDiscount && <span className={cn('price', 'red')}>{discountPrice}</span>}
            </span>
            <span className={cn('totalTitle')}>{vatLabel}</span>
          </div>
        )}
      </div>
      {showEnvelopesNote && (
        <div className={cn('envelopesNote')}>
          {envelopesLabel}
        </div>
      )}
      {deliveryPrice && (
        <div className={cn('shipmentSection')}>
          {`${shipmentLabel} ${deliveryPrice}`}
        </div>
      )}
    </React.Fragment>
  );
};

export default Price;
