/* SELECTOR FUNCTIONS
These are pure functions that get the app state as a parameter and return some data from it, needed in the components.
Together with the operations, the selectors are part of the public interface of the 'duck'.
These functions make sense when you have a more complex app state.
*/

import get from 'idx';
import apiTypings from '../../api/optimalprint-sdk';
import { getDesign } from '../category/selectors';

export const getPriceFormat = (state: any): string => state.price.priceFormat;

export const getPrices = (state: any, categoryId: number, productId: number) => {
  return get(state, _ => _.price[categoryId][productId].prices) as apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[] | undefined;
};

export const getUpsells = (state: any, categoryId: number, productId: number) => {
  return get(state, _ => _.price[categoryId][productId].upsells) as apiTypings.AppBundle.Api.Entity.Product.V1.ProductUpsellItems | undefined;
};

export const getFrames = (state: any, categoryId: number, productId: number) => {
  return get(state, _ => {
    const { upsells } = _.price[categoryId][productId];
    return (upsells ? upsells.posterBindings : []) as apiTypings.AppBundle.Api.Entity.Product.V1.UpsellItem[];
  }) || [];
};

export const getShipmentPrices = (state: any, categoryId: number, designId: string): apiTypings.ShipmentBundle.Entity.API.ShipmentPrice.ShipmentPriceListV2Response.ProductShipmentPriceList[] | undefined => {
  const design = getDesign(state, categoryId, designId);
  if (!design) {
    return undefined;
  }
  const { productId } = design;
  return get(state, _ => _.price[categoryId][productId].shipmentPrices) as apiTypings.ShipmentBundle.Entity.API.ShipmentPrice.ShipmentPriceListV2Response.ProductShipmentPriceList[] | undefined;
};
