import { ControlsState } from '@categoryProduct/store/controls/types';
import ActionTypes from '../actionTypes';
import createReducer from '../../../util/createReducer';
import activateSection from './activateSection';
import deactivateSectionControls from './deactivateSectionControls';
import setInputControlValue from './setInputControlValue';
import registerInputControl from './registerInputControl';
import deactivateInputControl from './deactivateInputControl';
import touchSection from './touchSection';
import touchAllSections from './touchAllSections';
import recheckInputControl from './recheckInputControl';
import setInitialControlsValues from './setInitialControlsValues';
import clearAllSections from './clearAllSections';

export const initialState: ControlsState = {
  sections: {},
  touchedSections: [],
  activeSectionId: '',
  initialValues: {},
  selectorOptions: {},
};

const controls = createReducer(initialState, {
  [ActionTypes.ACTIVATE_SECTION]: activateSection,
  [ActionTypes.DEACTIVATE_SECTION_CONTROLS]: deactivateSectionControls,
  [ActionTypes.REGISTER_INPUT_CONTROL]: registerInputControl,
  [ActionTypes.DEACTIVATE_INPUT_CONTROL]: deactivateInputControl,
  [ActionTypes.SET_INPUT_VALUE]: setInputControlValue,
  [ActionTypes.TOUCH_SECTION]: touchSection,
  [ActionTypes.TOUCH_ALL_SECTIONS]: touchAllSections,
  [ActionTypes.RECHECK_INPUT_CONTROL]: recheckInputControl,
  [ActionTypes.SET_INITIAL_CONTROLS_VALUES]: setInitialControlsValues,
  [ActionTypes.CLEAR_ALL_SECTIONS]: clearAllSections,
});

export default controls;
