import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import setInputControlValue from '@categoryProduct/store/controls/operation/setInputControlValue';
import registerInputControl from '@categoryProduct/store/controls/operation/registerInputControl';
import getControlValue from '@categoryProduct/store/controls/selector/getControlValue';
import deactivateInputControl from '@categoryProduct/store/controls/operation/deactivateInputControl';
import getControlRecheck from '@categoryProduct/store/controls/selector/getControlRecheck';
import { Store } from '@categoryProduct/store/typings';
import cn from 'classnames';
import styles from './index.css';

interface OwnProps {
  initialValue: string;
  onChange: Function;
  validator: Function;
  className?: string;
  type?: string;
  section: string;
  name: string;
  filter?: Function;
}

interface Props extends OwnProps {
  dispatch: Dispatch;
  value: any;
  recheck: boolean;
}

const SimpleInput = ({ initialValue, onChange, validator, className, name, section, dispatch, type, filter, value, recheck }: Props) => {
  const [ inputValue, setInputValue ] = useState(initialValue);
  const [ isValid, setValid ] = useState(false);
  const [ lastFiltered, setLastFiltered ] = useState<any>(null);
  const [ isTouched, setTouched ] = useState(false);

  useEffect(() => {
    const valid = validator ? validator(initialValue) : false;
    setValid(valid);
    dispatch(registerInputControl(name, section, true, valid, initialValue));
    return () => {
      dispatch(deactivateInputControl(name, section));
    };
  }, []);

  const checkValue = (v: string) => {
    let newValue = v;
    if (filter) {
      const filterResult = filter(newValue);
      if (filterResult !== null) {
        setLastFiltered(filterResult);
        newValue = filterResult;
      } else {
        newValue = lastFiltered !== null ? lastFiltered : newValue;
      }
    }

    const validationResult = validator(newValue);
    setTouched(true);
    setValid(validationResult);
    dispatch(setInputControlValue(name, section, newValue, validationResult));
    setInputValue(newValue);
    if (onChange) onChange(newValue, validationResult);
  };

  useEffect(() => {
    if (value !== undefined) {
      checkValue(value);
    }
  }, [ recheck ]);

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  const onChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    checkValue(value);
  };

  return (
    <div className={cn(className, { [styles.touched]: isTouched, [styles.invalid]: !isValid })}>
      <input
        className={styles.input}
        type={type || 'text'}
        value={inputValue}
        onChange={onChangeWrapper}
      />
    </div>
  );
};

const mapStateToProps = (state: Store, { section, name }: OwnProps) => ({
  value: getControlValue(state, section, name),
  recheck: getControlRecheck(state, section, name),
});
export default connect(mapStateToProps)(SimpleInput);
