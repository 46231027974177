/* SELECTOR FUNCTIONS
These are pure functions that get the app state as a parameter and return some data from it, needed in the components.
Together with the operations, the selectors are part of the public interface of the 'duck'.
These functions make sense when you have a more complex app state.
*/
import get from 'idx';
import { createSelector } from 'reselect';
import { DesignFamily } from '@categoryProduct/typings';
import { filterFamilies, getEnabledAndSelectedFilters, getSelectedFilters } from '../../util/category';
import apiTypings from '../../api/optimalprint-sdk';

export const getCategory = (state: any, categoryId: number) => state.category.categories[categoryId] as apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo;

export const getDefaultFiltersForCategory = (state: any, categoryId: number) => {
  if (!state.category) {
    return [];
  }

  const filters = state.category.categories[categoryId].defaultFilters;
  return filters.map((f: Filter) => f.tag);
};

export const getSubCategories = (state: any, categoryId: number, parentCategoryId?: number) => {
  let parentId = parentCategoryId || categoryId;

  // category (thank-you-cards : 12) should not be treated as parent category
  if (parentCategoryId === 12) {
    parentId = categoryId;
  }

  return Object.values(state.category.categories as {
    [s: number]: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo;
  }).filter((c) => c.parentCategoryId === parentId);
};

export const getBreadcrumbsCategories = (state: { category: { categories: { [s: string]: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo } } }, categoryId: number) => {
  const getParentCategories = (parentCatId: number): apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo[] => {
    const parentCategory = Object.values(state.category.categories).find(c => c.categoryId === parentCatId);
    return parentCategory ? [ parentCategory, ...getParentCategories(parentCategory.parentCategoryId) ] : [];
  };

  const category = get(state, _ => _.category.categories[categoryId]);
  return category ? getParentCategories(category.parentCategoryId) : [];
};

export const getCategories = (state: any) => state.category.categories as { [s: string]: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo };

export const getImagesData = (state: any) => state.category.imagesData as { [s: string]: string };

export const getDesignFamilies = (state: any, categoryId: number) => (state.category.designs[categoryId] || {}) as {
  [s: string]: DesignFamily;
};

export const getDesignFamiliesCount = (state: any, categoryId: number) => (get(state, _ => _.category.designs[categoryId].totalDesignsCount) || 0) as number;

export const getDesignFamilyByDesignId = (
  state: any,
  categoryId: number,
  designId: string,
) => {
  const families = getDesignFamilies(state, categoryId);

  return Object.values(families).find(
    (family: any) => family.designs
      && family.designs.find((design: any) => design.designId === designId),
  ) as DesignFamily;
};

export const getDesign = (
  state: any,
  categoryId: number,
  designId: string,
) => {
  const families = getDesignFamilies(state, categoryId);

  return Object.values(families)
    .reduce((acc, family) => (family.designs ? [ ...acc, ...family.designs ] : acc),
      [] as apiTypings.AppBundle.Api.Entity.Design.V1.IDesign[])
    .find(design => design.designId === designId);
};

export const getPaperFormats = (state: any) => state.category.paperFormats;

export const getFrameFormats = (state: any) => state.category.frameFormats;

export const getFrameAlternatives = (state: any) => state.category.frameAlternatives;

export const getSimilarProducts = (state: any, categoryId: number, designId: number) => {
  return get(state, _ => _.category.similarProducts[categoryId][designId]) as apiTypings.AppBundle.Api.Entity.Design.V1.SimilarProduct[] | undefined;
};

const getFiltersFromState = (state: any): Filters => state.category.filters;

export const getSelectedFiltersFromState = createSelector(
  [ getFiltersFromState ],
  (filters) => {
    const { selectedFilters } = getEnabledAndSelectedFilters(filters);
    return selectedFilters;
  },
);

export const getEnabledFiltersFromState = createSelector(
  [ getFiltersFromState ],
  (filters) => {
    const { enabledFilters } = getEnabledAndSelectedFilters(filters);
    return enabledFilters;
  },
);

export const getVisibleFamilies = createSelector(
  [ getSelectedFiltersFromState, getFiltersFromState, getDesignFamilies ],
  (selectedFilters, availableFilters, designFamilies) => {
    const familiesKeys: string[] = Object.keys(designFamilies);
    const reMappedFamilies: DesignFamily[] = familiesKeys.map((key: string) => designFamilies[key]);
    const { families } = filterFamilies(
      reMappedFamilies,
      getSelectedFilters(availableFilters, selectedFilters),
      availableFilters,
    );
    return families;
  },
);

export const getFavourites = (state: any) => state.category.favourites;
