import React from 'react';
import styles from './index.css';
import { getSpotFinishImageById } from '@categoryProduct/component/util/getStaticImageUrl';
import { Translation, withTranslation } from 'react-i18next';

// to be picked up by script-collector
withTranslation()(({ t }) => {
  return (
    <>
      {t('txt_spot_finish_2_tooltip_description')}
      {t('txt_spot_finish_3_tooltip_description')}
      {t('txt_spot_finish_4_tooltip_description')}
    </>
  );
});

interface Props {
  id: number;
}

export default ({ id }: Props) => {
  return (
    <Translation>
      {(t) => (
        <span className={styles.foilTooltipContent}>
          <div><img src={getSpotFinishImageById(id)} alt='' /></div>
          <span>{t(`txt_spot_finish_${id}_tooltip_description`)}</span>
        </span>
      )}
    </Translation>
  );
};
