import { AnyAction } from 'redux';
import { ControlsState } from '../types';

const touchSection = (state: ControlsState, { sectionId }: AnyAction) => {
  const inList = state.touchedSections.indexOf(sectionId) >= 0;
  if (inList) {
    return {...state};
  }
  return {
    ...state,
    touchedSections: [...state.touchedSections, sectionId],
  };
};

export default touchSection;
