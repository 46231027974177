import { Store } from '../../typings';
import getDesignData from './getDesignData';

const getPageSize = (store: Store) => {
  const design = getDesignData(store);
  return design && design.structure[0] && {
     width: parseInt(design.structure[0].width, 10),
     height: parseInt(design.structure[0].height, 10),
     bleed: parseInt(design.structure[0].bleed, 10),
  }
};

export default getPageSize;
