import { JsonDesign } from 'browser-editor';

export interface DesignData extends JsonDesign {
  encPublicDesignId: string;
  categoryId: number;
  productId: number;
  orientationId: number;
  paperFormatId: number;
}

export interface DesignState {
  designData: DesignData | null;
  designId: string;
  designType: DesignType | undefined;
  designStatus: DesignStatus | undefined;
}

export enum DesignType {
  PUBLIC_DESIGN = 'public',
  CUSTOMER_DESIGN = 'customer',
}

export enum DesignStatus {
  DESIGN_LOADING = 'loading',
  DESIGN_READY = 'ready',
  DESIGN_FAILED = 'failed',
}

export enum BABY_ORIENTATIONS {
  'HORIZONTAL' = 1,
  'VERTICAL'= 2,
}

export enum BABY_FACING {
  'LEFT'= 0,
  'RIGHT' = 1,
}
