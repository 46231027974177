import React from 'react';
import classNames from 'classnames/bind';
import { Translation } from 'react-i18next';
import Text from '@categoryProduct/component/common/Text';

import styles from './styles.css';

type Props = {
  id: number,
  selectedId: number,
  onClick: (id: number) => void
  isDisabled: boolean,
  imageUrl: string,
};

const cn = classNames.bind(styles);

export default (props: Props) => {
  const {
    id,
    selectedId,
    isDisabled,
    imageUrl,
  } = props;
  const isActive = id === selectedId;
  const onClick = () => {
    if (!isDisabled) {
      props.onClick(id);
    }
  };

  return (
    <Translation>
      {t => (
        <div
          id={`frame_${id}`}
          className={cn('square', {
            'squareSelected': isActive,
            'squareDisabled': isDisabled,
            'squareOnly': !imageUrl,
          })}
          key={id}
          onClick={onClick}
        >
          {imageUrl && id
            ? (
              <div className={styles.squareImageWrapper}>
                <img src={imageUrl} alt='' />
              </div>
            )
            : (
              <div className={cn('squareContentOnly')}>
                <Text small>{t('txt_api_spot_finish_none_name_short')}</Text>
              </div>
            )}
        </div>
      )}
    </Translation>
  );
};
