const crossSellUrls = {
  "en-XX" : "/my-photo-products",
  "en-CA" : "/en/my-photo-products",
  "en-GB" : "/my-photo-products",
  "en-AU" : "/my-photo-products",
  "en-IE" : "/my-photo-products",
  "en-NZ" : "/my-photo-products",
  "en-US" : "/en/my-photo-products",
  "cs-CZ" : "/moje-fotoprodukty",
  "no-NO" : "/mine-fotoprodukter",
  "sv-SE" : "/mina-fotoprodukter",
  "dk-DK" : "/mine-fotoprodukter",
  "fr-CA" : "/fr/my-photo-products",
  "fr-FR" : "/mes-produits-photo",
  "fr-CH" : "/fr/mes-produits-photo",
  "fr-BE" : "/fr/mes-produits-photo",
  "es-ES" : "/mis-productos-fotograficos",
  "de-AT" : "/meine-fotoprodukte",
  "de-DE" : "/meine-fotoprodukte",
  "de-CH" : "/de/meine-fotoprodukte",
  "nl-BE" : "/nl/mijn-fotoproducten",
  "nl-NL" : "/mijn-fotoproducten",
  "pt-PT" : "/minha-foto-produtos",
  "pt-BR" : "/minha-foto-produtos",
  "pl-PL" : "/moje-foto-produkty",
  "it-IT" : "/miei-prodotti-fotografici",
  "en-SG" : "/my-photo-products",
  "es-CL" : "/mis-productos-fotograficos",
  "en-IN" : "/my-photo-products",
};

export default crossSellUrls;
