import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { EditorControls } from 'browser-editor';
import { BABY_ORIENTATIONS } from '@categoryProduct/store/design/types';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import getBabyOrientation from '@categoryProduct/store/design/selector/getBabyOrientation';
import getBabyFacingControl from '@categoryProduct/store/birthPosters/selector/getBabyFacingControl';
import { Store } from '../../typings';

import refreshBabyLength from './refreshBabyLength';

const changeBabyIllustration = (
  editor: EditorControls | null,
  illustrationData: apiTypings.AppBundle.Api.Entity.Design.V1.IBirthPosterIllustration,
  babyIndex: number
) => (
  dispatch: ThunkDispatch<Store, void, AnyAction>,
  getState: () => Store
) => {
  if (!editor || !illustrationData) {
    return;
  }

  const state = getState();
  const section = `step${babyIndex + 1}`;
  const babyOrientation = getBabyOrientation(state, section);
  const babyFacing = getBabyFacingControl(state, babyIndex);

  const orientation = babyOrientation === BABY_ORIENTATIONS.VERTICAL ? 'Ver' : 'Hor';

  const targetImage = `image${orientation}${babyFacing}`;

  const babyImageName = `birthPosterBaby_${babyIndex}`;
  const imageUrl = illustrationData[targetImage].v;
  editor.replaceImage(
    babyImageName,
    imageUrl,
    {
      width: illustrationData[targetImage].spw,
      height: illustrationData[targetImage].sph,
    },
    illustrationData[targetImage].cpi,
    illustrationData[targetImage].it,
    illustrationData.key,
    babyFacing === 'Right'
  );
  dispatch(refreshBabyLength(editor, babyIndex));
};

export default changeBabyIllustration;
