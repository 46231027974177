import { AnyAction } from 'redux';
import { ControlsState } from '../types';
import getInitialValue from '../selector/getInitialValue';
import { Store } from '@categoryProduct/store/typings';

const registerInputControl = (state: ControlsState, { name, sectionId, value, flatValue, valid }: AnyAction) => {
  const updatedSection = {...state.sections[sectionId]} || {};
  const inputControl = updatedSection[name];

  const predefinedValue = getInitialValue({controls: state} as Store, sectionId, name);

  let initialValue = value;
  if (predefinedValue !== null) {
    initialValue = flatValue ? predefinedValue : { value: predefinedValue };
  }

  if (!inputControl) {
    updatedSection[name] = {value: initialValue, valid, flatValue, active: true, recheck: 1};
  } else {
    updatedSection[name].active = true;
  }

  return {
    ...state,
    sections: {
      ...state.sections,
      [sectionId]: updatedSection,
    },
  };
};

export default registerInputControl;
