/* ACTION CREATOR FUNCTIONS
Put here the functions that return an action object that can be dispatched
HINT: Always use functions for consistency, don't export plain objects
*/

import { createAction } from 'redux-actions';

export const types = {
  designFamilySet: 'category/designFamily/SET',
  designFamiliesSet: 'category/designFamilies/SET',
  designFamiliesDelete: 'category/designFamilies/DELETE',
  categoriesSet: 'category/list/SET',
  availableFiltersSet: 'category/filters/SET',
  similarProductsSet: 'category/designSimilarProducts/SET',
  paperFormatsSet: 'category/paperFormats/SET',
  frameFormatsSet: 'category/frameFormats/SET',
  frameAlternativesSet: 'category/frameAlternatives/SET',
  base64Images: 'category/images/SET',
  ecommerceDataSet: 'category/ecommerceData/SET',
  gtmFormatsSet: 'category/gtmFormats/SET',
  favouritesAdd: 'category/favourites/ADD',
  favouritesRemove: 'category/favourites/DELETE',
};

export const designFamilySet = createAction(types.designFamilySet);
export const designFamiliesSet = createAction(types.designFamiliesSet);
export const designFamiliesDelete = createAction(types.designFamiliesDelete);
export const listSet = createAction(types.categoriesSet);
export const availableFiltersSet = createAction(types.availableFiltersSet);
export const similarProductsSet = createAction(types.similarProductsSet);
export const paperFormatsSet = createAction(types.paperFormatsSet);
export const frameFormatsSet = createAction(types.frameFormatsSet);
export const frameAlternativesSet = createAction(types.frameAlternativesSet);
export const base64Images = createAction(types.base64Images);
export const ecommerceDataSet = createAction(types.ecommerceDataSet);
export const gtmFormatsSet = createAction(types.gtmFormatsSet);
export const favouritesAdd = createAction(types.favouritesAdd);
export const favouritesRemove = createAction(types.favouritesRemove);
