const getEditorSize = (rect: ClientRect | DOMRect, pageSize: { width: number, height: number }, maxHeight: number) => {
  const height = (pageSize.height * rect.width) / pageSize.width;
  if (height < maxHeight) {
    return {
      height,
      width: rect.width,
    };
  }
  const width = (pageSize.width * maxHeight) / pageSize.height;
  const maxWidth = rect.width;
  if (width > maxWidth) {
    return {
      width: maxWidth,
      height: (pageSize.height * maxWidth) / pageSize.width,
    };
  }
  return {
    width,
    height: maxHeight,
  };
};

export default getEditorSize;
