import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Translation } from 'react-i18next';

import Text from '@categoryProduct/component/common/Text';
import { Store } from '@categoryProduct/store/typings';
import { categorySelectors } from '@categoryProduct/store/category';
import FrameOption from '@categoryProduct/component/Product/FramesTypeList/FrameOption';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';

import styles from '@categoryProduct/component/Product/Product.css';
// import { getOrderedUpsellIds } from '@categoryProduct/component/util/TrimFoilHelper';
import ownStyles from './styles.css';

interface OwnProps {
  currentFrameId: number;
  onClick: (designUrl: string, encPublicDesignId: string, categoryId: number, productId: number) => void;
}

interface Props extends OwnProps {
  framesData: any[];
}

const FramesTypeList = (props: Props) => {
  const { framesData, onClick, currentFrameId } = props;

  if (!framesData.length) {
    return null;
  }
  let selectedFrame = framesData.find(frame => frame.product.id === currentFrameId);
  selectedFrame = selectedFrame || framesData[0];

  const renderedList = () => {
    return framesData
      .map((frameData) => {
        const frame = frameData.product;
        const { id, thumbnailUrl } = frame;
        return (
          <React.Fragment key={id}>
            <div className={ownStyles.upsellOptionsContainer}>
              <FrameOption
                id={id}
                imageUrl={thumbnailUrl}
                selectedId={currentFrameId}
                designUrl={frameData.designUrl}
                categoryId={frameData.categoryId}
                encPublicDesignId={frameData.encPublicDesignId}
                productId={frameData.product.productId}
                isDisabled={false}
                onClick={onClick}
              />
            </div>
          </React.Fragment>
        );
      });
  };

  return (
    <Translation>
      {t => (
        <div id='FramesTypeList' className={styles.infoWrapper}>
          <Text bold darkBlue className={`${styles.infoTitle} ${ownStyles.noBreak}`}>{t('txt_frame_colour')}:</Text>
          <div className={styles.infoValuesWrapper}>
            <Text darkBlue className={styles.infoTitle}>{selectedFrame.product.name}</Text>
            <div className={styles.formatContainer}>
              {renderedList()}
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state: Store) => ({
  framesData: categorySelectors.getFrameAlternatives(state),
});

export default connect(mapStateToProps)(FramesTypeList);
