import ActionTypes from '../actionTypes';
import setPageSize from './setPageSize';
import createReducer from '../../../util/createReducer';
import setIllustrations from './setIllustrations';

export const birthPosterInitialState = {
  pageSize: {
    width: 1,
    height: 1,
  },
  illustrations: [],
};

const birthPosters = createReducer(birthPosterInitialState, {
  [ActionTypes.BIRTH_POSTERS_SET_PAGE_SIZE]: setPageSize,
  [ActionTypes.BIRTH_POSTERS_SET_ILLUSTRATIONS]: setIllustrations,
});

export default birthPosters;
