import React, { useState } from 'react';
import { Translation } from 'react-i18next';

import styles from './SortBy.css';
import { History } from 'history';

interface Props {
  history: History;
  selectedSorting: SelectType;
  sortOptions: SelectType[];
  onChange: (selected: SelectType) => void;
}

const SortBy: React.FC<Props> = ({ history, selectedSorting, sortOptions, onChange }) => {
  const [selected, updateSelected] = useState(selectedSorting ? selectedSorting.value : sortOptions[0].value);
  const updateSort = (selected: SelectType) => () => {
    updateSelected(selected.value);
    onChange(selected);
  };
  return (
    <Translation>
      {t => (
        <div className={styles.sortContainer}>
          {sortOptions.map(item => (
            <div key={`${item.value}_${item.label}`} className={styles.sortItem} onClick={updateSort(item)}>
              <div className={selected === item.value ? styles.selectedWrap : styles.unselectedWrap}>
                <span className={selected === item.value ? styles.selected : styles.unselected} />
              </div>
              <span className={styles.sortItemLabel}>{t(item.label as string)}</span>
            </div>
          ))}
        </div>
      )}
    </Translation>
  );
};

export default SortBy;
