/* OPERATIONS = REDUX THUNKS
This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator, ex: simpleQuack
Complex operations involve returning a thunk that dispatches multiple actions in a certain order, ex: complexQuack
*/
import { Dispatch } from 'redux';
import * as actions from './actions';
import { History } from 'history';


export const saveLastCategoryHistory = async (
  dispatch: Dispatch,
  history: History,
) => {
  dispatch(
    actions.lastVisitedCategorySet({
      lastVisitedCategoryHistory: history,
    }),
  );
};
