import global from './global';
import { AppBundle } from '../api/optimalprint-sdk';
import memoize from 'memoize-one';

// fetch parent categories
export const getParentCategories = (categories: { [s: string]: AppBundle.Api.Entity.Category.V1.CategoryInfo }, parentCatId: number): AppBundle.Api.Entity.Category.V1.CategoryInfo[] => {
    const parentCategory = categories[parentCatId];
    return parentCategory ? [parentCategory, ...getParentCategories(categories, parentCategory.parentCategoryId)] : [];
};

export default memoize((categories: { [s: string]: AppBundle.Api.Entity.Category.V1.CategoryInfo }, categoryId: number) => {
    const category = categories[categoryId];
    return Boolean(category && global('ab_previews'));
});
