import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './index.css';

interface Props {
  text: string;
  url: string;
  selected: boolean
}

const cn = classNames.bind(styles)
export default ({text, url, selected}: Props) => (
  <Link to={url} className={cn('simpleListOption', { 'active': selected })}>
    {text}
  </Link>
);
