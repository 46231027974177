import { AnyAction } from 'redux';
import {BirthPostersState} from '../types';

const setPageSize = (state: BirthPostersState, {width, height}: AnyAction) => ({
  ...state,
  pageSize: {
    width,
    height,
  },
});

export default setPageSize;
