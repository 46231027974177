import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router';
import { DesignFamily } from '@categoryProduct/typings';
import { categoryOperations } from '@categoryProduct/store/category';
import { priceOperations } from '@categoryProduct/store/price';
import fetchIllustrations from '@categoryProduct/store/birthPosters/operation/fetchIllustrations';
import { Store } from '@categoryProduct/store/typings';

interface RouteParams {
  // eslint-disable-next-line camelcase
  category_id: string;
  // eslint-disable-next-line camelcase
  design_id: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  numberOfBabies: number;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

export default (props: Props) => {
  const {
    dispatch,
    match: {
      params: { category_id: categoryId, design_id: designId },
    },
  } = props;

  return [
    categoryOperations.fetchDesignFamily(dispatch, undefined, Number(categoryId), designId)
      .then(async (family: DesignFamily) => {
        // fetch pricing
        const productIds = family.designs.map(design => design.productId)
          .reduce((acc, id) => (acc.indexOf(id) > -1 ? acc : [ ...acc, id ]), [] as number[]);

        await Promise.all([
          priceOperations.fetchPrices(dispatch, Number(categoryId), productIds),
        ]);
      }),
    fetchIllustrations(),
  ];
};
