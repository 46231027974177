import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Translation } from 'react-i18next';
import Button from '../../common/Button';
import Text from '../../common/Text';
import apiTypings from '../../../api/optimalprint-sdk';
import styles from './styles.css';
import formatPrice from '../../../util/formatPrice';

interface Props {
  isOpen: boolean;
  onChange: (typeId: number) => void;
  paperTypes: apiTypings.AppBundle.Api.Entity.Product.V1.IUpsellItem[];
  selectedTypeId: number;
  selectedQuantity: number;
  itemPrices: apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[];
  priceFormat: string;
}

const CompareCardStock: React.FC<Props> = ({
  isOpen,
  paperTypes,
  selectedTypeId,
  selectedQuantity,
  itemPrices,
  onChange,
  priceFormat,
}) => {
  const [selected, setSelected] = useState(selectedTypeId);

  const formatOption = (
    price: apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice,
    upsellItem: apiTypings.AppBundle.Api.Entity.Product.V1.IUpsellItem,
  ) => {
    const isDiscount = price && (price.ip !== price.ipi);

    const reularPrice = () => (
      <span
        className={classnames({
          [styles.crossed]: isDiscount,
        })}
      >
        {formatPrice(price.ipi, priceFormat)}
      </span>
    );

    const discountedPrice = () => isDiscount && (
      <span
        className={styles.red}
      >
        {formatPrice(price.ip, priceFormat)}
      </span>
    );

    const { id, imageUrl, description, name } = upsellItem;

    return (
      <div
        id={`paperFormat_${id}`}
        key={id}
        className={classnames(styles.card, {
          [styles.active]: selected === id,
        })}
        onClick={() => setSelected(id)}
      >
        <img className={classnames('lazy', styles.cardImage)} src={imageUrl} />
        <div className={styles.nameWrapper}>
          <Text className={styles.cardHeader} bold>{name}</Text>
          <Text className={styles.price}>
            {price.q ? <span>{reularPrice()} {discountedPrice()} / item</span> : 'included'}
          </Text>
        </div>
        <Text className={styles.mainText}>{description}</Text>
      </div>
    );
  };

  const RenderModal = (
    <Translation>
      {t => (
        <div
          className={classnames(styles.container, {
            [styles.hidden]: !isOpen,
          })}
        >
          <div className={styles.modal}>
            <Text
              header2
              darkBlue
              bold
              className={styles.componentHeader}
            >
              {t('txt_select_paper_type')}
            </Text>
            <div className={styles.content}>
              {paperTypes.map(upsell => formatOption(
                (upsell.prices && upsell.prices.find(p => p.q === selectedQuantity) || {}) as apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice,
                upsell,
              ))}
              <Button
                id='comparePaper_submitButton'
                onClick={() => onChange(selected)}
                backBtn
                className={styles.selectButton}
              >
                {t('txt_button_submit')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );

  return typeof document !== 'undefined' ? ReactDOM.createPortal(
    RenderModal,
    document.body,
  ) : null;
};

export default CompareCardStock;
