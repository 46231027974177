import apiTypings from '@categoryProduct/api/optimalprint-sdk';

export const collectUrls = (illustrations: apiTypings.AppBundle.Api.Entity.Design.V1.IBirthPosterIllustration[]) => {
  return illustrations.reduce((acc, iObj) => {
    [ 'imageVerLeft', 'imageVerRight', 'imageHorLeft', 'imageHorRight' ].forEach(name => {
      const possibleUrl = iObj[name] && iObj[name].v;
      if (possibleUrl) {
        acc.push(possibleUrl.replace(/ph=([0-9]+)/, 'ph=2048').replace(/pw=([0-9]+)/, 'pw=2048'));
      }
    });

    return acc;
  }, [] as string[]);
};

export default (urls: string[]) => {
  const fetchImage = (url: string) => {
    return new Promise(resolve => {
      const image = new Image();
      image.onload = () => {
        resolve(image);
      };
      image.src = url;
    });
  };

  let p = Promise.resolve();
  urls.forEach(url => {
    p = p.then(() => fetchImage(url)) as Promise<void>;
  });
};
