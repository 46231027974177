import { DesignFamily } from '@categoryProduct/typings';
import { DesignData } from '@categoryProduct/store/design/types';
import { BIRTH_POSTERS_CATEGORY_IDS } from '@categoryProduct/util/birthPoster/categoryIds';

interface Family {
  [key: string]: DesignFamily,
}

const getSelectorData = (family: Family, designData: DesignData) => {
  const resultFamily = family[0] || family[-1];
  const designsInFamily = (resultFamily.designs || []);
  const currentDesignPaperFormatId = designData.structure[0].paperFormatId;
  const sameOrientaionDesigns = designsInFamily.filter(d => d.orientationId === designData.orientationId);
  const sameFormatDesigns = designsInFamily.filter(d => d.paperFormatId === currentDesignPaperFormatId);
  const finalSetOfDesigns = sameFormatDesigns.length ? sameFormatDesigns : sameOrientaionDesigns;

  return finalSetOfDesigns.reduce((acc, design) => {
    const pathnameArr = window.location.pathname.split('/');
    pathnameArr[pathnameArr.length - 1] = design.designId;
    pathnameArr[pathnameArr.length - 2] = String(design.categoryId);
    const url = pathnameArr.join('/');

    const data = {
      designId: design.designId,
      categoryId: design.categoryId,
      url,
    };
    switch (design.categoryId) {
      case BIRTH_POSTERS_CATEGORY_IDS.SINGLE_BABY:
        acc['1'] = data;
        break;
      case BIRTH_POSTERS_CATEGORY_IDS.TWIN_BABY:
        acc['2'] = data;
        break;
      case BIRTH_POSTERS_CATEGORY_IDS.TRIPLET_BABY:
        acc['3'] = data;
        break;
      default:
        break;
    }

    return acc;
  }, {});
};

export default getSelectorData;
