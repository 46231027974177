import ActionTypes from '../actionTypes';

const setInputControlValue = (name: string, sectionId: string, value: any, valid: boolean) => ({
  type: ActionTypes.SET_INPUT_VALUE,
  name,
  sectionId,
  value,
  valid,
});

export default setInputControlValue;
