import React from 'react';
import cn from 'classnames';

import styles from './Card.css';

export default ({ useNewPreviews = true }) => (
  <div className={cn(styles.card, { [styles.oldCard]: !useNewPreviews })}>
    <div className={styles.content}>
      <div className={cn(styles.cardImage, styles.cardLoading, { [styles.oldCardImage]: !useNewPreviews })} />
    </div>
  </div>
);
