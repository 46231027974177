import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import _ from 'lodash';
import get from 'idx';

import { types } from './actions';

const mergeFamilies = (temp1: any, temp2: any) => _.mergeWith(temp1, temp2, (x, y) => {
  if (_.isArray(x) && x[0] && x[0].designId) {
    return [...x, ...y].reduce((acc, a) => acc.some((d: any) => d.designId === a.designId) ? acc : [...acc, a], []);
  }
});

const designsReducer = handleActions<any>(
  {
    [types.designFamiliesDelete]: (
      state,
      { payload: { categoryId } },
    ) => {
      state[categoryId] = undefined;
      return state;
    },
    [types.designFamiliesSet]: (
      state,
      { payload: { categoryId, data, totalDesignsCount } },
    ) => {
      const newData = Object.keys(data).reduce((acc, key) => {
        let oldFamily = get(state, _ => _[categoryId][key]);
        let incomingFamily = data[key];

        // old family could be orphan; stored in [-1]
        if (!oldFamily) {
          const orphanFamily = get(state, _ => _[categoryId][-1]) as any;
          const orphanFamilyId = get(orphanFamily, _ => _.designs[0].familyId);
          if (orphanFamilyId && orphanFamilyId === incomingFamily.designs[0].familyId) {
            oldFamily = incomingFamily;
            // orphan should be merge into new data to keep category page designs order
            incomingFamily = orphanFamily;
          }
        }

        acc[key] = oldFamily ? mergeFamilies(oldFamily, incomingFamily) : incomingFamily;
        return acc;
      }, {});

      return {
        ...state,
        [categoryId]: {
          ...state[categoryId],
          ...newData,
          totalDesignsCount,
        },
      };
    },

    [types.designFamilySet]: (
      state,
      { payload: { categoryId, data } },
    ) => {
      const designFamilies = (state[categoryId] || {});

      const keyThatExists = Object.keys(designFamilies).find(key => {
        const designFamilyIdFromState = get(designFamilies, _ => _[key].designs[0].familyId);
        const designFamilyIdFromData = get(data, _ => _.designs[0].familyId);

        if (
          typeof designFamilyIdFromState === 'number'
          && typeof designFamilyIdFromData === 'number'
          && designFamilyIdFromState === designFamilyIdFromData
        ) {
          return true;
        }

        return false;
      });

      const key = keyThatExists && parseInt(keyThatExists, 10) >= 0 ? keyThatExists : -1;

      return {
        ...state,
        [categoryId]: mergeFamilies(state[categoryId], { [key]: data }),
      };
    },
  },
  [],
);

const categoriesReducer = handleActions<any>(
  {
    [types.categoriesSet]: (
      state,
      { payload: { data } },
    ) => ({
      ...state,
      ...data,
    }),
  },
  {},
);

const similarProductsReducer = handleActions<any>(
  {
    [types.similarProductsSet]: (
      state,
      { payload: { categoryId, designId, data } },
    ) => ({
      ...state,
      [categoryId]: {
        ...state.categoryId,
        [designId]: data,
      },
    }),
  },
  [],
);

const paperFormatsReducer = handleActions<any>(
  {
    [types.paperFormatsSet]: (
      state,
      { payload: { data } },
    ) => data,
  },
  [],
);

const frameFormatsReducer = handleActions<any>(
  {
    [types.frameFormatsSet]: (
      state,
      { payload: { data } },
    ) => data,
  },
  [],
);

const frameAlternativesReducer = handleActions<any>(
  {
    [types.frameAlternativesSet]: (
      state,
      { payload: { designs } },
    ) => designs,
  },
  [],
);

const filtersReducer = handleActions<any>(
  {
    [types.availableFiltersSet]: ( // We'll rely on browser cache to populate filters on different categories
      state,
      { payload: { data } },
    ) => data,
  },
  {},
);

const imagesDataReducer = handleActions<any>(
  {
    [types.base64Images]: (
      state,
      { payload: { data } },
    ) => ({
      ...state,
      ...data,
    }),
  },
  {},
);

const gtmFormatsDataReducer = handleActions<any>(
  {
    [types.gtmFormatsSet]: (
      state,
      { payload: { data } },
    ) => ({
      ...state,
      ...data,
    }),
    [types.designFamiliesDelete]: (
      state,
    ) => ({}),
  },
  {},
);

const favouritesReducer = handleActions<any>(
  {
    [types.favouritesAdd]: (
      state,
      { payload: { data } },
    ) => ([...state, ...data]),
    [types.favouritesRemove]: (
      state,
      { payload: { data } },
    ) => (state.filter((f: string) => data.indexOf(f) === -1)),
  },
  [],
);

const reducer = combineReducers({
  designs: designsReducer,
  favourites: favouritesReducer,
  categories: categoriesReducer,
  similarProducts: similarProductsReducer,
  filters: filtersReducer,
  paperFormats: paperFormatsReducer,
  frameFormats: frameFormatsReducer,
  frameAlternatives: frameAlternativesReducer,
  imagesData: imagesDataReducer,
  gtmFormats: gtmFormatsDataReducer,
});

export default reducer;

