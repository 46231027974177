import { AnyAction } from 'redux';
import { ControlsState } from '../types';

const setInitialControlsValues = (state: ControlsState, { initialValues }: AnyAction) => ({
  ...state,
  initialValues: {
    ...initialValues,
  },
});

export default setInitialControlsValues;
