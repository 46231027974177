import React from 'react';
import classnames from 'classnames';

import styles from './carousel.css';
import iconStyles from '../commonStyles/icon.css';
import { debounce } from 'lodash';

interface Props {
  selectedIndex?: number | undefined;
  nextItemPreview?: boolean;
}

export default class Carousel extends React.Component<Props> {
  state = {
    containerWidth: 0,
    listLeft: 0,
    showLeftButton: false,
    showRightButton: false,
  };

  container = React.createRef<HTMLDivElement>();
  list = React.createRef<HTMLDivElement>();

  componentDidMount() {
    // IE11 needs more time to get node size (in other case it return 0)
    setTimeout(this.calculateSize, 0);

    window.addEventListener('resize', this.debouncedCalculate);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedCalculate);
  }

  calculateSize = () => {
    this.setState({
      containerWidth: 0,
    });

    if (!this.list.current || !this.container.current) {
      return;
    }

    // calculate container width
    const containerWidth = this.container.current.offsetWidth;
    const itemWidth = this.list.current.children[0].getBoundingClientRect().width;
    const itemPreviewSize = this.props.nextItemPreview ? itemWidth / 3 : 0;

    const newContainerWidth = Math.floor(containerWidth / itemWidth) * itemWidth + itemPreviewSize;

    // calculate initial list offset to make selectedItem visible
    const listLeft = this.getInitialLeft(newContainerWidth);

    // show buttons?
    const listWidth = this.list.current.children[0].getBoundingClientRect().width * this.list.current.children.length;
    const showLeftButton = listLeft < 0;
    const showRightButton = Math.abs(listLeft - containerWidth) < listWidth;

    this.setState({
      showLeftButton,
      showRightButton,
      listLeft,
      containerWidth: newContainerWidth,
    });
  };

  debouncedCalculate = debounce(this.calculateSize, 100, { leading: true });

  slide = (e: React.MouseEvent, dir: string) => {
    if (!this.list.current || !this.container.current) {
      return;
    }

    const containerWidth = this.container.current.offsetWidth;
    const listLeft = this.list.current.offsetLeft;
    const listWidth = this.list.current.children[0].getBoundingClientRect().width * this.list.current.children.length;

    const curPos = listLeft || 0;
    const before = (curPos + containerWidth);
    const after = listWidth + (curPos - containerWidth);

    let left;
    if (dir === 'next') {
      left = (after < containerWidth) ? curPos - after : curPos - containerWidth;
    } else {
      left = (before >= 0) ? 0 : curPos + containerWidth;
    }

    const showLeftButton = left < 0;
    const showRightButton = Math.abs(left - containerWidth) < listWidth;

    this.setState({ showLeftButton, showRightButton, listLeft: left });
  };

  getInitialLeft = (containerWidth: number) => {
    const { selectedIndex } = this.props;

    if (
      !selectedIndex
      || !this.list.current
      || !this.container.current
      || (this.container.current && !this.list.current.children)
    ) {
      return 0;
    }

    const selectedItemLeft = (this.list.current.children[selectedIndex] as any).offsetLeft;

    const listLeft = this.list.current.offsetLeft;
    const itemWidth = this.list.current.children[0].getBoundingClientRect().width;
    const listWidth = itemWidth * this.list.current.children.length;


    let curPos = listLeft || 0;

    for (let i = 0; i <= Math.floor(containerWidth / itemWidth); i += 1) {
      if (selectedItemLeft < Math.abs(curPos - containerWidth)) {
        break;
      }

      const after = listWidth + (curPos - containerWidth);
      curPos = (after < containerWidth) ? curPos - after : curPos - containerWidth;
    }

    return curPos;
  };

  prev = (e: React.MouseEvent) => this.slide(e, 'prev');
  next = (e: React.MouseEvent) => this.slide(e, 'next');

  render = () => {
    const { showLeftButton, showRightButton, listLeft, containerWidth } = this.state;

    return (
      <div className={styles.wrap}>
        <i
          onClick={this.prev}
          className={classnames(iconStyles.icon, styles.iconLeft, { [styles.hidden]: !showLeftButton })}
        />
        <div
          ref={this.container}
          className={styles.content}
          style={{ width: containerWidth ? containerWidth : 'auto' }}
        >
          <div
            ref={this.list}
            className={styles.list}
            style={{ left: listLeft }}
          >
            {(this.props.children as Element[]).map((e, i) => <div key={i}>{e}</div>)}
          </div>
        </div>
        <i
          onClick={this.next}
          className={classnames(iconStyles.icon, styles.iconRight, { [styles.hidden]: !showRightButton })}
        />
      </div>
    );
  };
}
