import apiTypings from '../../api/optimalprint-sdk';
import { isArtistic, isPoster } from '@categoryProduct/component/util/productTypeIdsHelper';

export const selectPriceObjectOfTheUpsell = (
  upsellItems: apiTypings.AppBundle.Api.Entity.Product.V1.IUpsellItem[],
  selectedUpsellId: number,
  selectedQuantity: number,
) => {
  const selectedVariant = upsellItems.find((item) => item.id === selectedUpsellId);
  if (!selectedVariant || !selectedVariant.prices || !selectedVariant.prices.length) {
    return null;
  }

  return selectedVariant.prices.find((priceObj) => priceObj.q === selectedQuantity);
};

export const accumulateProductPrice = (
  acc: apiTypings.AppBundle.Api.Entity.Product.V1.IProductPrice,
  priceObject?: apiTypings.AppBundle.Api.Entity.Product.V1.IProductPrice,
) => {
  if (!priceObject) return acc;

  return {
    q: acc.q,
    ip: acc.ip + priceObject.ip,
    ipi: acc.ipi + priceObject.ipi,
    nip: acc.nip + priceObject.nip,
    np: acc.np + priceObject.np,
    p: acc.p + priceObject.p,
    pi: acc.pi + priceObject.pi,
  } as apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice;
};

export const isFrameAvailable = (frames: apiTypings.AppBundle.Api.Entity.Product.V1.UpsellItem[], frameId: number) => {
  return Boolean(frames && frames.filter(f => f.id === frameId)[0]);
}

export const shouldApplyFrame = (frameId: number, productTypeId: number, newPreviews: boolean, imageIndex: number) => {
  const isPosterProduct = isPoster(productTypeId);
  const isArtisticPosterProduct = isArtistic(productTypeId);
  const useNewPreviews = !isArtisticPosterProduct && newPreviews;

  if (isArtisticPosterProduct) {
    // 3rd image is on wall
    // first image scaled incorrectly
    return frameId && imageIndex === 1;
  }

  if (useNewPreviews && isPosterProduct) {
    // 1st image is 3d
    return frameId && imageIndex >= 1;
  }

  return Boolean(frameId);
}
