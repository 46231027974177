import React from 'react';
import { connect } from 'react-redux';
import { categorySelectors } from '@categoryProduct/store/category';

import FilterItem from './FilterItem';
import styles from './FilterSlider.css';

interface Props {
  items: Filter[];
  enabledFilters: number[];
  selectedFilters: number[];
  clickToFilter: (tag: number) => void;
  inRow?: boolean;
}

const FilterSlider: React.FC<Props> = ({ items, enabledFilters, selectedFilters, clickToFilter, inRow }) => (
  <div className={styles.containerRow}>
    {items.map(item => (
      <FilterItem
        key={`${item.tag}_${item.tagTr}`}
        icon={item.icon}
        title={item.shortTagTr || item.tagTr}
        color={item.color}
        value={item.value}
        onClick={() => clickToFilter(item.tag)}
        isDisabled={enabledFilters.indexOf(item.tag) === -1}
        isSelected={selectedFilters.indexOf(item.tag) > -1}
      />))}
  </div>
);

const mapStateToProps = (state: any) => ({
  selectedFilters: categorySelectors.getSelectedFiltersFromState(state),
  enabledFilters: categorySelectors.getEnabledFiltersFromState(state),
});

export default connect(mapStateToProps)(FilterSlider);
