import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css?global';
import './index.css';

interface Props {
  children: React.ReactElement;
  content: React.ReactElement;
}

export default (props: Props) => {
  const { children, content } = props;
  const ArrowContent = <div className='rc-tooltip-arrow-inner' />;

  return (
    <Tooltip
      placement='top'
      overlay={content}
      arrowContent={ArrowContent}
    >
      {children}
    </Tooltip>
  );
};
