export const DEFAULT_START_MONTH = 0; // January
export const DEFAULT_START_DATE = 1; // 1st of the month
export const SWITCH_YEAR_MONTH = 9;  // October
export const NUMBER_OF_MONTHS = 36;  // 3 years

export const getStartDate = (date: Date) => {
    let year = date.getFullYear();
    if (date.getMonth() >= SWITCH_YEAR_MONTH) {
        year += 1;
    }

    return new Date(year, DEFAULT_START_MONTH, DEFAULT_START_DATE);
};

export const getPossibleMonthsToStart = (date: Date) => {
    const startDate = getStartDate(date);
    return [...Array(NUMBER_OF_MONTHS).keys()].map(index => new Date((new Date(startDate)).setMonth(startDate.getMonth() + index)));
};
