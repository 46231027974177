// Please see docs for API here: https://uk-test.optimalprint.net/api/doc
export default {
  calendarBackgrounds: '/api/configuration/design-editor/v1/backgrounds/list',
  calendarEventsAddCustom: '/api/customer/calendar-event/v1/add',
  calendarEventsCustom: '/api/customer/calendar-event/v1/list',
  calendarEventsDefault: '/api/calendar-event/v1/list',
  calendarEventsPosition: '/api/calendar-event/v1/position',
  calendarEventsUpdateCustom: '/api/customer/calendar-event/v1/update',
  calendarEventsUpdateList: '/app-api/events/custom', // FIXME: replace with new API entry poiny when it's implemented
  cartAddressUpdate: '/api/customer/cart/v1/address/update',
  cartDesignAdd: '/api/customer/cart/v1/design/add',
  cartGet: '/api/customer/cart/v1/get',
  cartItemDuplicate: '/api/customer/cart/v1/item/duplicate',
  cartItemEnvelopeUpdate: '/api/customer/cart/v1/item/envelope/update',
  cartItemQuantityUpdate: '/api/customer/cart/v1/item/quantity/update',
  cartItemRemove: '/api/customer/cart/v1/item/remove',
  cartItemSubstrateUpdate: '/api/customer/cart/v1/item/substrate/update',
  cartLock: '/api/customer/cart/v1/lock',
  cartPublicDesignAdd: '/api/customer/cart/v1/public-design/add',
  cartShipmentMethodList: '/api/customer/cart/v1/shipping-method/list',
  cartShipmentMethodUpdate: '/api/customer/cart/v1/shipping-method/update',
  cartUnlock: '/api/customer/cart/v1/unlock',
  cartVoucherAdd: '/api/customer/cart/v1/voucher/add',
  cartVoucherRemove: '/api/customer/cart/v1/voucher/remove',
  categoryDesignList: '/api/category/v1/designs/list',
  categoryFilterList: '/api/category/v1/filter/list',
  categoryList: '/api/category/v1/list',
  categoryMatchingProductsList: '/api/category/v1/matching-products/list',
  categorySimilarProductsList: '/api/category/v1/similar-products/list',
  categoryShipmentPriceList: '/api/shipment-price/v2/list',
  changePassword: '/api/customer/settings/password/v1/update',
  contentHome: 'api/content/app/v1/home/get',
  createPaymentSession: '/api/payment/adyen-payment-session/v2/create',
  customerDesignAdd: '/api/customer/design/v2/add',
  customerDesignDuplicate: '/api/customer/design/v1/duplicate',
  customerDesignGet: '/api/customer/design/v1/get',
  customerDesignList: '/api/customer/design/v1/list',
  customerDesignRemove: '/api/customer/design/v1/remove',
  customerDesignUpdate: '/api/customer/design/v2/update',
  customerProductAdd: '/api/customer/cart/v1/product/add',
  designFamily: '/api/category/v1/design-family/get',
  designUpsellList: '/api/customer/design/v1/upsell/list',
  ecommerceData: '/api/design/v1/ecommerce-data/list',
  externalLogin: '/api/auth/v1/login-external',
  favouriteAdd: '/api/customer/design/favourite/v1/add',
  favouriteList: '/api/customer/design/favourite/v1/list',
  favouriteRemove: '/api/customer/design/favourite/v1/remove',
  galleryCreateBatch: '/api/customer/gallery/v1/create-batch',
  galleryList: '/api/customer/gallery/v1/list',
  importFromUrl: '/api/customer/gallery/v1/import',
  initCategoryAbTests: '/posters/calendars',
  login: '/api/auth/v1/login',
  logout: '/api/auth/v1/logout',
  orderDetails: '/api/customer/order/v1/details',
  orderList: '/api/customer/order/v1/list',
  paperFormats: '/api/paper-format/v1/list',
  frameFormats: '/api/category/v1/fmc-alternatives/get',
  paymentCreditCardAdd: '/api/payment/credit-card/v1/add',
  paymentCreditCardCharge: '/api/payment/credit-card/v2/charge',
  paymentCreditCardChargeStoredCard: '/api/payment/credit-card/v2/charge-stored-card',
  paymentCreditCardList: '/api/payment/credit-card/v1/list',
  paymentCreditCardRemove: '/api/payment/credit-card/v1/remove',
  photoSourceAdd: '/api/customer/photo-source/v1/add',
  photoSourceList: '/api/customer/photo-source/v1/list',
  photoSourceUpdate: '/api/customer/photo-source/v1/update',
  ping: '/s/images/spacer.gif',
  postPurchase: '/api/customer/cart/v1/post-purchase/campaign',
  priceList: '/price-list?isNativeApp=1',
  productPriceList: '/api/product/v1/price/list',
  productUpsellList: '/api/product/v1/upsell/list',
  designUpsellListWithPreview: '/api/design/v1/upsell/list',
  publicDesignGet: '/api/design/v1/get',
  pushNotificationsStatusUpdate: '/api/customer/push-notifications/v1/status/update',
  recoveryChangePassword: '/api/auth/v1/password-recovery/change-password',
  recoveryRequest: '/api/auth/v1/password-recovery/request-recovery',
  refreshToken: '/api/auth/v1/refresh-token',
  register: '/api/auth/v1/register',
  registerGuest: '/api/auth/v1/register-guest',
  sendEmail: '/api/communication/v1/email/send',
  seriesAdd: '/api/customer/photobook-series/v1/add',
  seriesList: '/api/customer/photobook-series/v1/list',
  seriesRemove: '/api/customer/photobook-series/v1/remove',
  seriesUpdate: '/api/customer/photobook-series/v1/update',
  shipmentAddressAdd: '/api/customer/shipment-address/v1/add',
  shipmentAddressList: '/api/customer/shipment-address/v1/list',
  shipmentAddressRemove: '/api/customer/shipment-address/v1/remove',
  shipmentAddressUpdate: '/api/customer/shipment-address/v1/update',
  translation: '/api/translation/v2/list',
  upload: '/api/customer/gallery/v1/upload',
  gtmProducts: '/app-api/category/gtm-products',
  pixlee: '/api/product/v1/pixlee-widget/get',
  listBirthPostersIllustrations: '/api/design/v1/illustrations-for-birth-posters/list',
};
