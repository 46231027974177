import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Translation } from 'react-i18next';

import Text from '@categoryProduct/component/common/Text';
import { Store } from '@categoryProduct/store/typings';
import { priceSelectors } from '@categoryProduct/store/price';
import FrameOption from '@categoryProduct/component/Product/FramesList/FrameOption';
import UpsellOptionPrice from '@categoryProduct/component/Product/UpsellLists/UpsellOptionPrice';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';

import styles from '@categoryProduct/component/Product/Product.css';
import { addNoneOption } from '@categoryProduct/component/util/TrimFoilHelper';
import ownStyles from './styles.css';

interface OwnProps {
  categoryId: string;
  productId: number;
  selectedFrameId: number;
  quantity: number;
  onClick: (id: number) => void;
}

interface Props {
  priceFormat: string;
  getFrames: Function;
}

const cn = classNames.bind(styles);

const FramesList = (props: OwnProps & Props) => {
  const { getFrames, categoryId, productId, priceFormat, onClick, selectedFrameId, quantity } = props;
  const frames: apiTypings.AppBundle.Api.Entity.Product.V1.UpsellItem[] = getFrames(categoryId, productId);

  if (!frames.length || selectedFrameId === -1) {
    return null;
  }

  const framesIds = frames.reduce((acc: number[], frame: any) => [ ...acc, frame.id ], []);
  const selectedFrame = frames.filter(f => f.id === selectedFrameId)[0];
  const selectedFramePrice = selectedFrame.prices.find((p: apiTypings.AppBundle.Api.Entity.Product.V1.IProductPrice) => p.q === quantity);

  const renderedList = () => {
    return addNoneOption(framesIds, 0)
      .map((frameId: number) => {
        const frame = frames.filter(f => f.id === frameId)[0];
        const { id, thumbnailUrl } = frame;

        return (
          <React.Fragment key={id}>
            <div className={ownStyles.upsellOptionsContainer}>
              <FrameOption
                id={id}
                imageUrl={thumbnailUrl}
                selectedId={selectedFrameId}
                isDisabled={false}
                onClick={onClick}
              />
            </div>
          </React.Fragment>
        );
      });
  };

  return (
    <Translation>
      {t => (
        <div id='FramesList' className={styles.infoWrapper}>
          <Text bold darkBlue className={styles.infoTitle}>{t('txt_frames')}:</Text>
          <div className={styles.infoValuesWrapper}>
            <Text darkBlue className={styles.infoTitle}>{selectedFrame.name}</Text>
            <div style={{ height: '25px' }}>
              {selectedFramePrice && (
                <UpsellOptionPrice
                  price={selectedFramePrice}
                  priceFormat={priceFormat}
                  isDisabled={false}
                  className='frameOptionPriceStatic'
                  isVisible
                />
              )}
            </div>
            <div className={styles.formatContainer} style={{ marginTop: 0 }}>
              {renderedList()}
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state: Store) => {
  return {
    priceFormat: priceSelectors.getPriceFormat(state),
    getFrames: (categoryId: number, productId: number) => priceSelectors.getFrames(state, categoryId, productId),
  };
};

export default connect(mapStateToProps)(FramesList);
