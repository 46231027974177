import 'intersection-observer'; // polyfill for lazyload images
import 'react-app-polyfill/ie11';

import React, { Suspense } from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useSSR } from 'react-i18next';
import configureStore from './redux';
import './i18n';
import Root from './component/Root';
import yall from 'yall-js/dist/yall.min.mjs';

export const store = configureStore((window as any).OP.CategoryProduct.serverState);

const BaseApp = () => {
  useSSR((window as any).OP.CategoryProduct.initialI18nStore, (window as any).OP.CategoryProduct.initialLanguage);
  return (
    <Suspense fallback={<div>Still loading i18n...</div>}>
      <BrowserRouter>
        <Provider store={store}>
          <Root />
        </Provider>
      </BrowserRouter>
    </Suspense>
  );
};

hydrate(<BaseApp />, document.getElementById('root'));

// observe for lazy loaded images and load them only when they appear on screen. not included in react.
document.addEventListener('DOMContentLoaded', yall({
  observeChanges: true,
  idleLoadTimeout: 1,
  threshold: 200,
}));

if (module.hot) {
  module.hot.accept();
}
