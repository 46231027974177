import cn from 'classnames';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'idx';
import { Translation } from 'react-i18next';
import queryString from 'query-string';

import * as styles from './Card.css';
import * as iconStyles from '@categoryProduct/component/commonStyles/icon.css';
import { categoryOperations, categorySelectors } from '@categoryProduct/store/category';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import NotFound from '@categoryProduct/component/NotFound';
import global from '@categoryProduct/util/global';
import Formats from './Formats';

import Tooltip from '@categoryProduct/component/common/Tooltip';
import FilterTooltipContent from '@categoryProduct/component/Category/FilterTooltipContent';
import LazyImage from '@categoryProduct/component/LazyImage';
import { isArtistic, isCalendar } from '@categoryProduct/component/util/productTypeIdsHelper';
import getImagePreviews from '@categoryProduct/component/util/getImagePreviews';
import { findFilterbyTag } from '@categoryProduct/util/category';
import isSmallScreen from '@categoryProduct/component/util/isSmallScreen';
import { getPreviewUrl, getProductPageUrl } from './cardUtils';
import { Design, DesignFamily } from '@categoryProduct/typings';

interface Props {
  categories: { [s: string]: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo };
  category: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo;
  family: DesignFamily;
  favourites: string[];
  paperFormats: apiTypings.AppBundle.Api.Entity.PaperFormat.V1.PaperFormat[];
  imagesData?: { [s: string]: string };
  useNewPreviews: boolean;
  onClick?: (designId: string) => void;
  dispatch: any;
  selectedFilters: SimplifiedFilter[];
}

// TODO: improve type
interface State {
  selectedDesign: Design;
  isImageLoaded: boolean;
  hover: boolean;
  lastLoadedImg?: string;
}

interface SimplifiedFilter {
  name: string;
  value: string;
}

const PRELOAD_PREVIEWS_TIMEOUT = 300;

class Card extends React.PureComponent<Props, State> {
  isPreviewLoaded = false;
  preloadPreviewsTimeout: number | null = null;
  imageRef = React.createRef();

  state = {
    selectedDesign: {} as Design,
    isImageLoaded: false,
    hover: false,
  } as State;

  static getDerivedStateFromProps(props: Props, state: State) {
    const {
      family,
    } = props;

    if (!family) {
      return null;
    }

    const selectedDesign = state.selectedDesign.id ? state.selectedDesign : family.designs[0];

    return {
      selectedDesign,
    } as Partial<State>;
  }

  componentDidMount(): void {
    // hack :( - this fixes Product -> Category position restore
    this.setState({});
  }

  // select design by format and color
  selectDesignByFormatAndColor = (format: number, color: string) => {
    const { family } = this.props;
    const { selectedDesign: { productTypeId } } = this.state;

    let design = family.designs.find(
      design => design.paperFormatId === format && design.color === color && design.productTypeId === productTypeId,
    );

    if (!design) {
      design = family.designs.find(design => design.paperFormatId === format && design.productTypeId === productTypeId);
    }

    if (!design) {
      design = family.designs.find(design => design.paperFormatId === format);
    }

    if (design && design.url) {
      this.setState({
        selectedDesign: design,
      });
    }
  };

  onImageLoad = (e: any, imageUrl: string) => {
    if (e.target.src === imageUrl) {
      this.setState({
        lastLoadedImg: imageUrl,
        isImageLoaded: true,
      });
    }
  };

  onClickHandler = () => {
    this.props.onClick && this.props.onClick(this.state.selectedDesign.designId);
  };

  onCardMouseEnter = () => {
    const { family, category, selectedFilters, useNewPreviews } = this.props;
    const { upsellOptions, productTypeId } = this.state.selectedDesign;
    const artistic = isArtistic(productTypeId);
    const isNewPreview = useNewPreviews && !artistic;

    this.preloadPreviewsTimeout = window.setTimeout(() => {
      if (!this.isPreviewLoaded && family && family.designs) {
        family.designs
          .filter(design => design.categoryIds && design.categoryIds.indexOf(category.categoryId) >= 0)
          .forEach((design) => {
            if (design && design.previews && design.previews.length) {
              const image = new Image();
              const url = design.previews[0].image;
              image.src = getPreviewUrl(url, selectedFilters, upsellOptions, isNewPreview);
            }
          });

        this.isPreviewLoaded = true;
      }
    }, PRELOAD_PREVIEWS_TIMEOUT);
    this.setState({ hover: true });
  };

  onCardMouseLeave = () => {
    if (this.preloadPreviewsTimeout) {
      clearTimeout(this.preloadPreviewsTimeout);
      this.preloadPreviewsTimeout = null;
    }

    this.setState({ hover: false });
  };

  render = () => {
    const { family, paperFormats, category, categories, dispatch, favourites, useNewPreviews, selectedFilters } = this.props;
    const {
      selectedDesign,
      lastLoadedImg,
      isImageLoaded,
    } = this.state;

    if (!selectedDesign) return <NotFound />;

    const {
      designId,
      spotFinishes,
      isNew,
      productTypeId,
      color: selectedColor,
      hasMp,
      paperFormatId: selectedPaperFormatId,
      previews,
      isFolded,
      upsellOptions,
      url,
    } = selectedDesign;

    const artistic = isArtistic(productTypeId);
    const calendar = isCalendar(productTypeId);
    const previewSize = isSmallScreen() ? 'small' : 'medium';
    const isNewPreview = useNewPreviews && !artistic;

    const isFavourite = favourites && favourites.some(f => f === designId);

    const images = getImagePreviews(categories, category.categoryId, selectedDesign)
      .map(i => i
        .replace('medium', previewSize)
        .replace('big', previewSize)
        .replace('large', previewSize),
      );

    const imageUrl = getPreviewUrl(images[0], selectedFilters, upsellOptions, isNewPreview);

    let selectedImages: string[] = [ imageUrl ];
    if (isArtistic(productTypeId)) {
      selectedImages = [ images[0], images[2] ]
        .map(p => p.replace('medium_perspective-', 'medium_3d-'));
    } else if (isCalendar(productTypeId) && !isNewPreview) {
      selectedImages = previews.slice(0, 2).map(p =>
        p.image
          .replace('listing_large', 'small_3d')
          .replace('big_perspective', 'small_3d'));
    }

    const formats = family && family.designs ? family.designs.filter(design => design.categoryIds && design.categoryIds.indexOf(category.categoryId) >= 0).reduce(
      (acc, design) => {
        const old = acc[design.paperFormatId] || {
          colors: [],
        };

        // prevent double colors
        if (old.colors.indexOf(design.color) >= 0) {
          return acc;
        }

        acc[design.paperFormatId] = {
          ...old,
          colors: [ ...old.colors, design.color ],
        };
        return acc;
      },
      {} as {
        [formatId: string]: {
          colors: string[];
        };
      },
    ) : {};

    const selectedColors = get(formats, _ => _[selectedPaperFormatId].colors) as string[] | undefined;
    const isColorsNoExist = !selectedColors || selectedColors.length < 2;

    const renderFormats = () => {
      if (!formats || Object.keys(formats).length <= 1) {
        return null;
      }

      const selectedFormat = paperFormats.find(f => f.productTypeId === productTypeId && f.paperFormatId === selectedPaperFormatId);

      return (
        <Fragment>
          <div className={styles.selectedFormatText}>{selectedFormat && selectedFormat.name}</div>

          <Formats
            categoryId={category.categoryId}
            family={family}
            paperFormats={paperFormats}
            selectedDesignId={designId}
            onMouseEnter={formatId => this.selectDesignByFormatAndColor(formatId, selectedColor)}
          />
        </Fragment>
      );
    };

    const renderFormatsSmall = () => {
      if (!formats || !Object.keys(formats).length) {
        return null;
      }

      const renderedFormatsCount = Object.keys(formats).length;

      return (
        <Translation>
          {t => (
            <div className={styles.formatSmall}>
              {renderedFormatsCount} {renderedFormatsCount > 1 ? t('txt_formats') : t('txt_format')}
            </div>
          )}
        </Translation>
      );
    };

    const renderColors = () => {
      if (!selectedColors || selectedColors.length < 2) {
        return;
      }

      const sortedColors = [ ...selectedColors ].sort();
      const colors = sortedColors.map(
        (color: string, index) => {
          return (
            <span
              className={cn(styles.color, {
                [styles.active]: color === selectedColor,
              })}
              style={{ background: color }}
              key={index}
              onMouseEnter={() => {
                this.selectDesignByFormatAndColor(selectedPaperFormatId, color);
              }}
            />
          );
        },
      );

      return (
        <div className={styles.colors}>
          {colors}
        </div>
      );
    };

    const renderColorsSmall = () => {
      if (!selectedColors || selectedColors.length < 2) {
        return null;
      }

      const sortedColors = [ ...selectedColors ].sort();
      const colors = sortedColors.map(
        (color: string, index) => {
          return (
            <span
              className={cn(styles.colorSmall)}
              style={{ background: color }}
              key={index}
            />
          );
        },
      );

      return (
        <div className={styles.colorsSmall}>{colors}</div>
      );
    };

    const getMpLink = () => {
      const queries = {
        design_id: designId,
        acid: category.categoryId,
        show_related_designs: 1,
      };


      if (category.dbName.toLowerCase().indexOf('wedding_invitations') >= 0) {
        queries['wedding_kit'] = 1;
      }

      return `${global('urlRoot')}/matching_products?${queryString.stringify(queries)}`;
    };

    const onFavButtonClicked = () => {
      if (!isFavourite) {
        categoryOperations.favouritesAdd(dispatch, designId)
          .catch(() => {
            // TODO: add warning of failed action
          });
      } else {
        categoryOperations.favouritesRemove(dispatch, designId)
          .catch(() => {
            // TODO: add warning of failed action
          });
      }
    };

    const productPageUrl = getProductPageUrl(url, selectedFilters, upsellOptions);
    const isFormatsAvailable = !artistic && (hasMp || Object.keys(formats).length > 1);
    const isFormatsVisible = isFormatsAvailable && (this.state.hover || typeof window === 'undefined');
    let isImageReady = false;

    if (this.imageRef && this.imageRef.current) {
      isImageReady = !!(this.imageRef.current as any).complete;
    }

    return (
      <Translation>
        {t =>
          <div
            className={cn(styles.card, {
              [styles.oldCard]: !isNewPreview,
              [styles.artisticCard]: artistic,
              [styles.cardLoaded]: isImageReady || isImageLoaded,
            })}
            onMouseEnter={this.onCardMouseEnter}
            onMouseLeave={this.onCardMouseLeave}
            id={`design-${designId}${!isColorsNoExist ? '-with-colors' : ''}`}
          >
            <div
              onClick={onFavButtonClicked}
              className={cn(styles.favourite)}
            >
              <i
                className={cn(iconStyles.icon, styles.favouritesIcon, {
                  [styles.favouritesIconFilled]: isFavourite,
                })}
              />
            </div>
            <div className={styles.content}>
              <div className={styles.cardHeader}>
                {spotFinishes.length > 1 && (
                  <Tooltip content={<FilterTooltipContent id={spotFinishes[1]} />}>
                    <span
                      className={cn(
                        styles.onDesktop,
                        styles.label,
                        styles.labelFoil,
                        styles[`spot_finish_color_${spotFinishes[1]}`],
                      )}
                    >
                      {t('txt_with_foil')}
                    </span>
                  </Tooltip>
                )}
                {isNew && <span className={styles.label}>{t('txt_new')}</span>}
              </div>
              <Link
                className={cn(styles.cardImageWrap, {
                  [styles.calendarContent]: calendar && !isNewPreview,
                })}
                id={`cardDesign_Link_${designId}`}
                to={productPageUrl}
                onClick={this.onClickHandler}
              >
                <div
                  className={cn(styles.cardImage, {
                    [styles.oldCardImage]: !isNewPreview,
                    [styles.isFolded]: isFolded,
                    [styles.calendarCardImage]: calendar && !isNewPreview,
                    [styles.cardImageLoading]: !isImageReady && !isImageLoaded,
                    [styles.cardImageLoaded]: isImageReady || isImageLoaded,
                  }, 'card_image_preview_wrapper')}
                >
                  <LazyImage
                    onLoad={this.onImageLoad}
                    placeholder={lastLoadedImg || selectedImages[0]}
                    url={selectedImages[0]}
                    imageRef={this.imageRef}
                  />
                </div>
                {selectedImages[1] && (
                  <div
                    className={cn(styles.cardImage, {
                      [styles.oldCardImage]: !isNewPreview,
                      [styles.artisticCardImage]: artistic,
                      [styles.calendarCardImage]: calendar,
                      [styles.cardImageLoading]: !isImageReady && !isImageLoaded,
                      [styles.cardImageLoaded]: isImageReady || isImageLoaded,
                    }, 'card_image_preview_wrapper')}
                  >
                    <LazyImage
                      placeholder={selectedImages[1]}
                      url={selectedImages[1]}
                    />
                  </div>
                )}
              </Link>

              <div className={cn(styles.onMobile, styles.onMobileCardDetails)}>
                <div className={styles.detailsSmall}>
                  {renderFormatsSmall()}
                  {renderColorsSmall()}
                  {spotFinishes.length > 1 && (
                    <div className={styles.fullWidth}>
                      <span
                        className={cn(
                          styles.noDesktop,
                          styles.label,
                          styles.labelFoil,
                          styles[`spot_finish_color_${spotFinishes[1]}`],
                        )}
                      >
                        {t('txt_with_foil')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {isFormatsVisible && (
              <div className={cn(styles.onDesktop, styles.onDesktopCardDetails)}>
                <div className={styles.details}>
                  {renderFormats()}
                  {renderColors()}
                  {hasMp && (
                    <a className={styles.matchingLink} href={getMpLink()}>
                      {t('txt_view_matching_products_lnk')}
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
        }</Translation>
    );
  };
}

const mapSingleFilter = (state: any) => (tag: number) => {
  const filter: Filter | null = findFilterbyTag(tag, state.category.filters);
  return filter ? { name: filter.filterName, value: filter.filterValue } : {};
};

const mapStateToProps = (state: any) => {
  return {
    categories: state.category.categories,
    imagesData: categorySelectors.getImagesData(state),
    paperFormats: categorySelectors.getPaperFormats(state),
    favourites: categorySelectors.getFavourites(state),
    selectedFilters: (categorySelectors.getSelectedFiltersFromState(state)
      .map(mapSingleFilter(state)) as SimplifiedFilter[]),
  };
};

export default connect(mapStateToProps)(Card);
