import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import { categoryOperations, categorySelectors } from '@categoryProduct/store/category';

import * as styles from './index.css';
import * as iconStyles from '@categoryProduct/component/commonStyles/icon.css';

type OwnProps = {
  designId: string,
};

type Props = {
  favourites: string[],
  dispatch: Dispatch,
} & OwnProps;

const FavouriteIcon: React.FunctionComponent<Props> = (
  {
    favourites,
    dispatch,
    designId,
  }) => {
  const isFavourite = favourites && favourites.some(f => f === designId);
  const onFavButtonClicked = () => {
    try {
      if (!isFavourite) {
        categoryOperations.favouritesAdd(dispatch, designId);
      } else {
        categoryOperations.favouritesRemove(dispatch, designId);
      }
    } catch (e) {
      // TODO: add warning of failed action
    }
  };

  return (
    <i
      onClick={onFavButtonClicked}
      className={cn(iconStyles.icon, styles.favouritesIcon, {
        [styles.favouritesIconFilled]: isFavourite,
      })}
    />
  );
};

const mapStateToProps = (state: any) => ({
  favourites: categorySelectors.getFavourites(state),
});

export default connect(mapStateToProps)(FavouriteIcon);
