import { Store } from '../../typings';
import getDesignData from './getDesignData';

const getCategoryId = (store: Store) => {
  const data = getDesignData(store);

  return data ? data.categoryId : -1;
};

export default getCategoryId;
