import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { categorySelectors } from '@categoryProduct/store/category';
import { priceSelectors } from '@categoryProduct/store/price';
import { Store } from '@categoryProduct/store/typings';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import styles from './styles.css';

interface OwnProps {
  id: number;
  imageUrl: string;
  frameId: number;
  onClick: (event: React.MouseEvent) => void;
  categoryId: string;
  productId: number;
  paperFormatId: number;
}

interface Props extends OwnProps {
  getFrames: Function;
  paperFormat: apiTypings.AppBundle.Api.Entity.PaperFormat.V1.PaperFormat;
}

const PreviewWithFrame = (props: Props) => {
  const { onClick, imageUrl, frameId, id, getFrames, categoryId, productId, paperFormat } = props;
  const [imageSize, storeImageSize] = useState({width: 0, height: 0});

  const [paddings, setPaddings] = useState({ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0});

  const frames: apiTypings.AppBundle.Api.Entity.Product.V1.UpsellItem[] = getFrames(categoryId, productId);
  const selectedFrame = frames.filter(f => f.id === frameId)[0];
  const frameMaskPositions = (selectedFrame as any).maskPos;
  const previewWithFrame = (selectedFrame as any).previewUrl;

  const posterImage = useRef<HTMLImageElement>();

  const onLoadHandler = () => {
    if (posterImage === undefined) {
      return;
    }
    const img = posterImage.current;
    if (img) {
      // if image width equal (Safari) we need to get width after image will rendered in DOM
      if (img.width === img.naturalWidth) {
        setTimeout(() => {
          storeImageSize({
            width: img.width,
            height: img.height,
          }, 200);
        });
      } else {
        storeImageSize({
          width: img.width,
          height: img.height,
        });
      }
    }
  };

  const realPosterWidth = paperFormat.finalWidth;

  const fitThumbnailInFrame = () => {
    const scale = imageSize.width / (realPosterWidth + frameMaskPositions.li * 2);
    const newPaddings = {
      paddingLeft: frameMaskPositions.li * scale,
      paddingRight: frameMaskPositions.li * scale,
      paddingTop: frameMaskPositions.ti * scale,
      paddingBottom: frameMaskPositions.bi * scale,
    };
    setPaddings(newPaddings);
  };

  useEffect(() => {
    fitThumbnailInFrame();
  }, [imageSize, frameId]);

  if (previewWithFrame) {
    return (
      <>
        <img
          id={`thumb_image_${id}`}
          src={previewWithFrame}
          alt=''
          onClick={onClick}
        />
      </>
    );
  }

  return Boolean(selectedFrame) && (
    <>
      <img
        id={`thumb_image_${id}`}
        src={imageUrl}
        alt=''
        onClick={onClick}
        onLoad={onLoadHandler}
        ref={posterImage}
        style={paddings}
      />
      <img
        id={`thumb_image_frame_${id}`}
        className={styles.frameImage}
        src={selectedFrame.flatMaskUrl}
        style={imageSize}
        alt=''
      />
    </>
  );
};

const mapStateToProps = (state: Store, props: OwnProps) => ({
  getFrames: (categoryId: number, productId: number) => priceSelectors.getFrames(state, categoryId, productId),
  paperFormat: categorySelectors.getPaperFormats(state).find((pf: any) => pf.paperFormatId === props.paperFormatId),
});

export default connect(mapStateToProps)(PreviewWithFrame);
