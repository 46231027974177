import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import { UpsellOptions, Design } from '@categoryProduct/typings';

export const EMPTY_FILTERS: Filters = {
  age: [],
  color: [],
  foil: [],
  folded: [],
  gender: [],
  nfotos: [],
  orient: [],
  size: [],
  trim: [],
  style: [],
  theme: [],
};

export const mapAvailableUpsells = (design: Design) => {
  if (!design.availableUpsellOptions) {
    console.error('availableUpsellOptions is empty for design', design);
    design.availableUpsellOptions = [];
  }

  const mappedOptions = design.availableUpsellOptions
    .reduce((acc: UpsellOptions, combination: apiTypings.AppBundle.Api.Entity.Design.V1.UpsellOption) => {
      acc[combination.trim] = acc[combination.trim] || [];

      if (combination.spotFinish && acc[combination.trim].indexOf(combination.spotFinish) === -1) {
        acc[combination.trim].push(combination.spotFinish);
      }

      return acc;
    }, { 1: [ 1 ] });

  return {
    ...design,
    upsellOptions: mappedOptions,
  };
};
