import * as React from 'react';

import './carousel.raw.css';
import { Carousel, CarouselProps } from 'react-responsive-carousel';

interface Props extends CarouselProps {
    selectedItemIndex?: number;
    perPage?: number;
    children: JSX.Element[];
}

export default React.memo<Props>(({ selectedItemIndex = -1, children, perPage = 1, ...props }) => {
    if (!children) {
        return null;
    }

    // if (children.length % perPage !== 0) {
    //     console.log(children);
    //     children = [
    //         ...children.slice(0, children.length - children.length % perPage),
    //         ...children.slice(children.length - perPage, children.length),
    //     ];
    // }

    const chunked = typeof selectedItemIndex !== 'undefined' && selectedItemIndex > -1;

    return (
        <Carousel
            {...props}
            selectedItem={chunked ? Math.floor(selectedItemIndex / perPage) : undefined}
        >
            {
                children
                    .reduce((acc, item, index) => {
                        if (!item) {
                            return acc;
                        }

                        const chunkIndex = Math.floor(index / perPage);

                        if (!acc[chunkIndex]) {
                            acc[chunkIndex] = [];
                        }

                        acc[chunkIndex].push(item);

                        return acc;
                    }, [] as JSX.Element[][])
                    .map((e, index) => <div key={index}>{e}</div>)
            }
        </Carousel>
    );
});
