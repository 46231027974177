import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Translation } from 'react-i18next';
import Button, { ButtonState } from '@categoryProduct/component/common/Button';
import { Store } from '@categoryProduct/store/typings';
import activateSection from '@categoryProduct/store/controls/operation/activateSection';
import isSectionValid from '@categoryProduct/store/controls/selector/isSectionValid';
import touchSection from '@categoryProduct/store/controls/operation/touchSection';

import styles from './styles.css';

interface OwnProps {
  nextSection: string;
  ifSectionValid: string;
}

interface Props extends OwnProps {
  isSectionValid: boolean;
  dispatch: Dispatch;
}

const NextStepButton: React.FC<Props> = ({ nextSection, ifSectionValid, isSectionValid, dispatch }: Props) => {
  const onNextStep = () => {
    if (isSectionValid) {
      dispatch(activateSection(nextSection));
    } else {
      dispatch(touchSection(ifSectionValid));
    }
  };
  return (
    <Translation>
      {t => (
        <div>
          <Button
            backBtn
            onClick={onNextStep}
            state={ButtonState.undefined}
            classNameWrapper={styles.customWrapper}
          >
            {t('txt_button_next_step')}
          </Button>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state: Store, { ifSectionValid }: OwnProps) => ({
  isSectionValid: isSectionValid(state, ifSectionValid),
});
export default connect(mapStateToProps)(NextStepButton);
