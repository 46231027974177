import React from 'react';
import classNames from 'classnames/bind';
import { Translation } from 'react-i18next';

import formatPrice from '@categoryProduct/util/formatPrice';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';

import styles from './priceStyles.css';

const cn = classNames.bind(styles);

type Props = {
  price: apiTypings.AppBundle.Api.Entity.Product.V1.IProductPrice | undefined | null,
  priceFormat: string,
  isVisible: boolean,
  isDisabled: boolean,
  className?: string,
};

export default (props: Props) => {
  const {
    priceFormat,
    price,
    isVisible,
    isDisabled,
    className,
  } = props;

  const isDiscount = Boolean(price && (price.ip !== price.ipi));
  const renderPrice = (p?: number) => p && priceFormat ? formatPrice(p, priceFormat) : undefined;
  const regularPrice = price && (
    <span className={cn({ 'crossed': isDiscount })}>
        {`${renderPrice(price.ipi)}`}
      </span>
  );
  const discountedPrice = price && (
    <Translation>
      {t => (
        <React.Fragment>
          {isDiscount && (
            <span className={styles.red}>{price.ip > 0 ? `${renderPrice(price.ip)}` : t('txt_free')}</span>
          )}
          {price.ip > 0 && <span> / {t('txt_cart_item')}</span>}
        </React.Fragment>
      )}
    </Translation>
  );

  return (
    <span className={cn('upsellOptionPrice', { 'hidden': !isVisible, 'show': isVisible, 'disabled': isDisabled, [className || '']: Boolean(className) })}>
      {regularPrice} {discountedPrice}
    </span>
  );
};
