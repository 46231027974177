import { handleActions } from 'redux-actions';
import { types } from './actions';

const historyReducer = handleActions<any>(
  {
    [types.lastVisitedCategorySet]: (
      state,
      { payload: {
        lastVisitedCategoryHistory,
      } },
    ) => ({
      ...state,
      lastVisitedCategoryHistory,
    }),
  },
  {},
);

export default historyReducer;
