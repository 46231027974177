import { createBrowserHistory, History } from 'history';
import queryString from 'query-string';
import { clone, isArray, isEmpty, uniq } from 'lodash';

export const setParamInLocation = (history: History, attr: string, value: string | string[], onlyOne: boolean = false): string => {
  try {
    const location = history && history.location;
    const parsed = clone(queryString.parse(location.search)) as object;
    const parsedValue = (parsed[attr] || '');
    let newValue: string[] = parsedValue.split(' ');

    if (isArray(value)) {
      newValue = [ ...newValue, ...value ];
    } else {
      newValue = onlyOne ? [ value ] : [ ...newValue, value ];
    }

    parsed[attr] = uniq(newValue.filter(item => item)).join('+');

    const search = Object.keys(parsed)
      // filter empty and defaults
      .filter(key => !isEmpty(parsed[key]) && parsed[key] != 1)
      .map(item => (`${item}=${parsed[item]}`)).join('&');

    history.push({ search });
    return search;
  } catch (e) {
    console.error(e);
  }

  return '';
};

export const removeParamFromLocation = (history: History, attr: string, value: string): string => {
  try {
    const location = history && history.location;
    const parsed = clone(queryString.parse(location.search)) as object;
    const parsedValue = (parsed[attr] || '');
    const newValue: string[] = parsedValue.split(' ');
    newValue.splice(newValue.indexOf(value), 1);
    parsed[attr] = uniq(newValue.filter(item => item)).join('+');
    const search = Object.keys(parsed).map(item => (`${item}=${parsed[item]}`)).join('&');

    history.push({ search });
    return search;
  } catch (e) {
    console.error(e);
  }

  return '';
};

export const getParamFromLocation = (history: History, attr: string): string | string[] | null => {
  try {
    const location = history && history.location;
    const parsed = queryString.parse(location.search);
    return (parsed[attr] || null);
  } catch (e) {
    console.error(e);
  }

  return null;
};

export const setLocation = (_: History, url: string) => {
  /* FIXME: why here we create new history (possibly to not merge with old params) */
  /* like old: xxx?test=1 and we simple replace yyy?new=2, without merging */
  /* as when deleting this, I see product?spot=2?trim=2 */
  const history = createBrowserHistory();
  history.replace({ pathname: url });
  return history;
};

export const setLocationSearch = (history: History, search: string) => {
  try {
    history.replace({ search });
  } catch (e) {
    console.error(e);
  }
};

export const normalizeOptimalprintQueries =
  (query: string | any): { [k: string]: any } => {
    const queryObject = typeof query === 'string' ? queryString.parse(query) : query;
    return Object.keys(queryObject).reduce((acc, key) => {
      let v = queryObject[key];

      if (typeof v === 'string') {
        v = v.replace(/<[^>]+>/g, '');
        if (`${v}`.toLowerCase() === 'on') {
          v = true;
        }

        if (`${v}`.toLowerCase() === 'off') {
          v = false;
        }
      }

      return { ...acc, [`${key}`.toLowerCase()]: v };
    }, {});
  };
