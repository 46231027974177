import { Store } from '../../typings';
import isControlValid from './isControlValid';

const isAllSectionsValid = (store: Store): boolean => {
  if (!store.controls.sections) {
    return false;
  }
  return !Object.keys(store.controls.sections).some(sectionId => (
    Object.keys(store.controls.sections[sectionId]).some(controlName => !isControlValid(store, sectionId, controlName))
  ));
};

export default isAllSectionsValid;
