import { AnyAction } from 'redux';
import { ControlsState } from '../types';

const recheckInputControl = (state: ControlsState, { name, sectionId }: AnyAction) => {
  const recheck = state.sections[sectionId] && state.sections[sectionId][name] && state.sections[sectionId][name].recheck
    ? state.sections[sectionId][name].recheck || 1
    : 1;

  return {
    ...state,
    sections: {
      ...state.sections,
      [sectionId]: {
        ...state.sections[sectionId],
        [name]: {
          ...state.sections[sectionId][name],
          recheck: recheck + 1,
        },
      },
    },
  };
};

export default recheckInputControl;
