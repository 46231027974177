import ActionTypes from '../actionTypes';
import { InitialControlsValues } from '../types';

const setInitialControlsValues = (initialValues: InitialControlsValues, clearAllSections = true) => ({
  type: ActionTypes.SET_INITIAL_CONTROLS_VALUES,
  initialValues,
  clearAllSections,
});

export default setInitialControlsValues;
