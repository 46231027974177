import React from 'react';
import classNames from 'classnames/bind';

import getImagesRootUrl from '@categoryProduct/util/getImagesRootUrl';
import styles from './FilterItem.css';
import Text from '@categoryProduct/component/common/Text';
import { getSpotFinishImageById } from '@categoryProduct/component/util/getStaticImageUrl';

export interface Props {
  onClick: () => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  title?: string;
  color?: string;
  icon?: string;
  text?: string;
  value?: string;
  accessibilityLabel?: string;
}

const cn = classNames.bind(styles);

const renderColor = (color: string, isDisabled: boolean = false, isSelected: boolean = false) => {
  const isGradient = (color.length > 7);
  let addStyles;

  if (isGradient) {
    const gradient = color.split(',');
    const linGrad = `${gradient[0]}, ${gradient[1]} ${gradient[2]}, ${gradient[3]} ${gradient[4]}`;
    addStyles = { background: `linear-gradient(${linGrad})` };
  } else {
    addStyles = { backgroundColor: color };
  }

  return (
    <span className={cn('iconWrapper', { disabledRound: isDisabled, selectedRound: isSelected })}>
      <span className={cn('round')} style={addStyles} />
    </span>
  );
};

const renderImageSquare = (id: number, text?: string, isDisabled: boolean = false, isSelected: boolean = false) => {
  const imageUrl = getSpotFinishImageById(id);

  return (
    <React.Fragment>
      <div className={cn('square', 'squareWithImage', { disabledSquare: isDisabled, selectedSquare: isSelected })}>
        {imageUrl
          ? <img src={imageUrl} alt='' />
          : text && <Text>{text}</Text>
        }
      </div>
      {text && (
        <span className={cn('title', { disabledText: isDisabled })}>
          {id !== 1 ? text : ''}
        </span>
      )}
    </React.Fragment>
  );
};

const FilterItem: React.FC<Props> = (props: Props) => {
  const {
    title,
    text,
    onClick,
    icon,
    color,
    value,
    isDisabled,
    isSelected,
  } = props;
  const onClickHandler = () => {
    if (isDisabled && !isSelected) return;
    onClick();
  };
  const baseUrl = getImagesRootUrl();

  const renderControl = () => {
    const filterValue = value ? Number(value) : -1;

    // correct case: icon is present, but not defined (no foil item)
    if ((icon || icon === '') && color) {
      return renderImageSquare(filterValue, title, isDisabled, isSelected);
    }

    return (
      <React.Fragment>
        {color && renderColor(color, isDisabled, isSelected)}
        {icon && <img
          className={cn('square', { disabledSquare: isDisabled, selectedSquare: isSelected })}
          src={baseUrl + icon}
          alt=''
        />}
      </React.Fragment>
    );
  };

  return (
    <div
      id={`filterItem_div_${color || text || title}`}
      onClick={onClickHandler}
      className={styles.wrapper}
    >
      {renderControl()}
      {text && <span>{text}</span>}
      {!color && title && <span className={cn('title', { disabledText: isDisabled })}>{title}</span>}
    </div>
  );
};

export default FilterItem;
