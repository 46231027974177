import { Dispatch } from 'redux';
import { EditorControls } from 'browser-editor';
import { Store } from '../../typings';
import getBabyData from '../selector/getBabyData';

const debounceDelay = 200;
let debounceTimeoutId: any = null;

const updatePosterPlaceholder = (
  editor: EditorControls,
  section: string,
  babyIndex: number,
  noDebounce = false
) => (
  dispatch: Dispatch,
  getState: () => Store
) => {
  const babyData = getBabyData(getState(), section, babyIndex);


  if (noDebounce) {
    editor.updateTextPlaceholders(babyData);
    return;
  }

  clearTimeout(debounceTimeoutId);
  debounceTimeoutId = setTimeout(() => {
    editor.updateTextPlaceholders(babyData);
  }, debounceDelay);
};

export default updatePosterPlaceholder;
