import React, { useEffect } from 'react';
import apiRequest from '@categoryProduct/api/apiRequest';
import endpoints from '@categoryProduct/api/endpoints';
import global from '@categoryProduct/util/global';

const SCRIPT_PATH = 'https://assets.pxlecdn.com/assets/pixlee_widget_1_0_0.js';
const FALLBACK_API_KEY = 'T4GRZcKKDU0RH81JMQ8O';
const PIXLEE_EVENT_NAME = 'widgetNumPhotos';

interface Props {
  productId: number;
  productType: number;
  categoryId: number;
  familyId: number;
}

enum WIDGET_TYPES {
  CATEGORY = 'category',
  PRODUCT = 'product',
}

interface WidgetOptions {
  widgetId: number;
  widgetType: WIDGET_TYPES.CATEGORY | WIDGET_TYPES.PRODUCT;
  skuId: string | null;
  categoryId: number | null;
  categoryName: string | null;
  subscribedEvents?: string[];
}

interface ResponseWidgetData extends WidgetOptions {
  fallbackWidget: WidgetOptions;
}

interface Response {
  enabled: boolean;
  apiKey?: string;
  widget: ResponseWidgetData;
}

const injectPixleeScript = function () {
  const script = document.createElement('script');
  script.src = SCRIPT_PATH;
  script.async = true;
  document.body.appendChild(script);
};

const addWidget = (data: WidgetOptions, watchFallback?: boolean) => {
  let widgetInitializer;
  const options = data;

  switch (data.widgetType) {
    case WIDGET_TYPES.CATEGORY:
      widgetInitializer = window.Pixlee.addCategoryWidget;
      break;
    case WIDGET_TYPES.PRODUCT:
      widgetInitializer = window.Pixlee.addProductWidget;
      break;
    default:
      widgetInitializer = window.Pixlee.addSimpleWidget;
  }

  if (watchFallback) {
    options.subscribedEvents = [ PIXLEE_EVENT_NAME ];
  }

  widgetInitializer(options);
  window.Pixlee.resizeWidget();
};

const initWidget = (widgetData: WidgetOptions, apiKey: string) => {
  window.PixleeAsyncInit = function () {
    window.Pixlee.init({ apiKey });
    addWidget(widgetData, true);
  };

  injectPixleeScript();
};

export default (props: Props) => {
  useEffect(() => {
    let widgetMain: WidgetOptions;
    let widgetFallback: WidgetOptions;

    const onMessage = (e: MessageEvent) => {
      if (e.data) {
        let message;
        try {
          message = JSON.parse(e.data);
        } catch (error) {
        }

        if (message && message.eventName && message.eventName === PIXLEE_EVENT_NAME) {

          if (message.data.photoCount < 1) {
            window.Pixlee.close();
            window.removeEventListener('message', onMessage);
            addWidget(widgetFallback);
          }
        }
      }
    };

    const requestData = {
      ...props,
      locale: global('locale'),
    };
    apiRequest(endpoints.pixlee, requestData, 'GET', 'data')
      .then((answer) => {
        const response = answer as Response;

        if (response.widget) {
          const responseWidgetData = response.widget;

          if (!response.enabled) {
            return;
          }

          // get fallback widget data
          if (responseWidgetData.fallbackWidget) {
            widgetFallback = responseWidgetData.fallbackWidget;
            delete responseWidgetData.fallbackWidget;
          }

          // get main widget data
          widgetMain = responseWidgetData;

          // create main widget
          initWidget(widgetMain, response.apiKey || FALLBACK_API_KEY);

          // subscribe to add fallback widget
          window.addEventListener('message', onMessage, false);
        }
      });
  }, []);

  return <div id='pixlee_container' />;
};
