import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from '../../typings';
import getControlValue from '../selector/getControlValue';
import isControlHasFlatValue from '../selector/isControlHasFlatValue';
import setInputControlValue from './setInputControlValue';

const updateInputControlValue = (
  name: string,
  sectionId: string,
  value: any,
  valid: boolean
) => (
  dispatch: ThunkDispatch<Store, void, AnyAction>,
  getState: () => Store
) => {
  const state = getState();
  const isFlatValue = isControlHasFlatValue(state, name, sectionId);

  let updatedValue = getControlValue(state, sectionId, name);
  if (isFlatValue) {
    updatedValue = value;
  } else {
    updatedValue = {...updatedValue, value, data: undefined};
  }

  dispatch(setInputControlValue(name, sectionId, updatedValue, valid));
};

export default updateInputControlValue;
