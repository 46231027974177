import global from '@categoryProduct/util/global';

export const formatDate = (date: string | Date, options?: object, locale?: string) => {
  // if date has no time, stick to 00:00:00 to have correct event date
  const dateWithTime = typeof date === 'string' && date.length === 10 ? `${date}T00:00:00` : date;

  const dateObj = new Date(dateWithTime);
  const dateLocale = locale || global('locale');
  // const monthStr = translateText(__('txt_long_name_month_' + dateObj.getMonth()));

  return dateObj.toLocaleDateString(dateLocale, options);
};

export default formatDate;
