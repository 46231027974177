import React, { useContext } from 'react';
import { Store } from '@categoryProduct/store/typings';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Translation } from 'react-i18next';
import Button, { ButtonState } from '@categoryProduct/component/common/Button';
import isAllSectionsValid from '@categoryProduct/store/controls/selector/isAllSectionsValid';
import EditorControlsContext from '@categoryProduct/util/context/EditorControlsContext';
import apiRequest from '@categoryProduct/api/apiRequest';
import endpoints from '@categoryProduct/api/endpoints';
import { incrementBasketItemsCount } from '@categoryProduct/util/updateBadgesCount';
import getDesignId from '@categoryProduct/store/design/selector/getDesignId';
import getCategoryId from '@categoryProduct/store/design/selector/getCategoryId';
import isCustomerDesign from '@categoryProduct/store/design/selector/isCustomerDesign';
import crossSellUrls from '@categoryProduct/config/crossSellUrls';
import touchAllSections from '@categoryProduct/store/controls/operation/touchAllSections';

interface OwnProps {
}

interface Props extends OwnProps {
  allSectionsValid: boolean;
  designId: string;
  categoryId: number;
  dispatch: Dispatch;
  isCustomerDesign: boolean;
}

const AddToBasketButton = ({ categoryId, designId, allSectionsValid, isCustomerDesign, dispatch }: Props) => {
  const [ btnState, setBtnState ] = React.useState(ButtonState.undefined);
  const quantity = 1;
  const editorControls = useContext(EditorControlsContext);

  const onAddToBasket = async () => {

    if (!allSectionsValid) {
      dispatch(touchAllSections());
      return;
    }

    try {
      setBtnState(ButtonState.loading);

      const jsonDesign = editorControls ? editorControls.exportDesign() : null;

      if (!jsonDesign) {
        throw 'no design structure to export';
      }

      const apiContext = 1;
      const actionToCrossSellAction = 1;
      const clientPlatform = 5; // web desktop

      const redirectToCrossSell = (encOrderItemId: string) => {
        (window as any).location = (crossSellUrls[(window as any).OP.CategoryProduct.locale] || '/my-photo-products') + `?encOrderItemId=${encOrderItemId}`;
      };

      if (isCustomerDesign) {
        const updateDesignParams = {
          customerDesignId: parseInt(designId, 10),
          structure: jsonDesign.structure,
          clientPlatform,
        };

        await apiRequest(endpoints.customerDesignUpdate, updateDesignParams, 'POST', 'data');
        setTimeout(() => redirectToCrossSell(''), 2000);
      } else {
        const addDesignParams = {
          encPublicDesignId: designId,
          categoryId,
          structure: jsonDesign.structure,
          clientPlatform,
          crossSellContext: apiContext,
          crossSellAction: actionToCrossSellAction,
        };

        const addedDesign = await apiRequest(endpoints.customerDesignAdd, addDesignParams, 'POST', 'data');
        const { customerDesignId } = addedDesign as any;
        const addToCartParams = {
          customerDesignId,
          quantity,
        };
        const added = await apiRequest(endpoints.cartDesignAdd, addToCartParams, 'POST', 'data') as any;
        incrementBasketItemsCount();
        setTimeout(() => redirectToCrossSell(added.encOrderItemId), 2000);
      }
      setBtnState(ButtonState.success);
    } catch (e) {
      setBtnState(ButtonState.failed);
      setTimeout(() => setBtnState(ButtonState.undefined), 5000);
    }
  };

  return (
    <Translation>
      {t => (
        <div>
          <Button
            fullWidth
            backBtn
            onClick={onAddToBasket}
            state={btnState}
            errorMessage={t('txt_design_add_to_cart_failed')}
          >
            {t('txt_add_to_basket')}
          </Button>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state: Store) => ({
  allSectionsValid: isAllSectionsValid(state),
  designId: getDesignId(state),
  categoryId: getCategoryId(state),
  isCustomerDesign: isCustomerDesign(state)
});

export default connect(mapStateToProps)(AddToBasketButton);
