import React from 'react';
import classnames from 'classnames/bind';

import styles from './styles.css';

const cn = classnames.bind(styles);

interface Props {
  price?: string | number | undefined;
  discountPrice?: string | number;
  vatLabel?: string| undefined;
  shipmentLabel?: string| undefined;
  deliveryPrice?: string | number;
}

const Price: React.FC<Props> = ({ price, discountPrice, deliveryPrice, vatLabel, shipmentLabel }) => {
  const isDiscount = discountPrice && discountPrice !== price;
  return (
    <>
      <div>
        <span>
          <span className={cn('price', { crossed: isDiscount })}>{price}</span>
          {isDiscount && <span className={cn('price', 'red')}>{discountPrice}</span>}
        </span>
        <span className={cn('totalTitle')}>{vatLabel}</span>
      </div>
      {deliveryPrice
        && (
          <div className={cn('shipmentSection')}>
            {`${shipmentLabel} ${deliveryPrice}`}
          </div>
        )}
    </>
  );
};

export default Price;
