import { Dispatch } from 'redux';
import { EditorControls } from 'browser-editor';
import { Store } from '../../typings';
import getControlValue from '@categoryProduct/store/controls/selector/getControlValue';
import getBabyOrientation from '@categoryProduct/store/design/selector/getBabyOrientation';
import { BABY_ORIENTATIONS } from '@categoryProduct/store/design/types';

const controlName = 'babyLength';
const controlUnitsName = 'babyLengthUnits';
const BABY_IMAGE_ELEMENT_NAME = 'birthPosterBaby_';
const INCHES_TO_CM = 2.54;

const refreshBabyLength = (
  editor: EditorControls,
  babyIndex: number
) => (
  dispatch: Dispatch,
  getState: () => Store
) => {
  const state = getState();
  const section = `step${babyIndex + 1}`;

  const babyOrientation = getBabyOrientation(state, section);
  const babyLength = getControlValue(state, section, controlName);

  const babyLengthUnits = getControlValue(state, section, controlUnitsName).value;

  // TODO: use util (AND IT"S ACTUALLY CM TO MM)
  const mm2cm = (value: number) => value * 10;

  const nameTemplate = BABY_IMAGE_ELEMENT_NAME + Number(babyIndex);

  const propertyToChange = babyOrientation === BABY_ORIENTATIONS.VERTICAL ? 'height' : 'width';
  // TODO: use util
  let sizeToSet = babyLengthUnits === 'm' ? mm2cm(babyLength) : mm2cm(babyLength * INCHES_TO_CM);
  // TODO size to set instead of 0
  if (sizeToSet === 0) {
    sizeToSet = 400;
  }

  editor.changeImageSize(nameTemplate, { [propertyToChange]: sizeToSet }, babyLengthUnits);
};

export default refreshBabyLength;
