import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';

import { Store } from '@categoryProduct/store/typings';
import styles from '@categoryProduct/component/Product/Product.css';
import Text from '@categoryProduct/component/common/Text';
import { priceSelectors } from '@categoryProduct/store/price';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import { Expand, ExpandStyles } from '@categoryProduct/component/common/Animation';
import {
  getAvailableSpotFinishes,
  getOrderedUpsellIds,
  getSpotFinishesForSelectedTrimId,
  isOnlyDefaultUpsellValue,
} from '@categoryProduct/component/util/TrimFoilHelper';
import { Design } from '@categoryProduct/typings';
import SpotFinishOption from './SpotFinishOption';
import upsellListsStyles from './styles.css';

import SpotFinishOptionPrice from './UpsellOptionPrice';

type Props = {
  priceFormat: string,
  getUpsells: (cId: number, pId: number) => apiTypings.AppBundle.Api.Entity.Product.V1.ProductUpsellItems | undefined,
};

type OwnProps = {
  selectedSpotFinishId: number,
  selectedTrimId: number,
  categoryId: number,
  selectedDesign: Design,
  quantity: number,
  selectUpsell: (
    ids: apiTypings.AppBundle.Api.Entity.Product.V1.IUpsellItem[],
    id: number,
  ) => apiTypings.AppBundle.Api.Entity.Product.V1.IUpsellItem | null,
  onClick: (id: number) => void,
};

const SpotFinishList = (props: OwnProps & Props) => {
  const {
    getUpsells,
    selectUpsell,
    selectedTrimId,
    priceFormat,
    selectedSpotFinishId,
    quantity,
    categoryId,
    selectedDesign: { upsellOptions, productId },
    onClick,
  } = props;
  const spotFinishes = getAvailableSpotFinishes(upsellOptions);

  // if no options or only options is default ones
  if (!spotFinishes || (spotFinishes.length === 1 && spotFinishes[0] === 1)) {
    return null;
  }

  const spotsAvailableForSelectedTrim = getSpotFinishesForSelectedTrimId(upsellOptions, selectedTrimId);
  const upsellCombinationsAreUnavailable = isOnlyDefaultUpsellValue(spotsAvailableForSelectedTrim);
  const upsells = getUpsells(categoryId, productId);

  const renderedList = getOrderedUpsellIds(spotFinishes)
    .map((id: number) => {
      const spotFinish = upsells && selectUpsell(upsells.spotFinishes, id);

      if (!spotFinish) {
        return null;
      }

      const price = spotFinish.prices && spotFinish.prices
        .find((p: apiTypings.AppBundle.Api.Entity.Product.V1.IProductPrice) => p.q === quantity);
      return (
        <React.Fragment key={id}>
          {id === 1 && <div className={upsellListsStyles.flexBreakLineElement} />}
          <div className={upsellListsStyles.upsellOptionsContainer}>
            <SpotFinishOption
              id={id}
              selectedId={selectedSpotFinishId}
              isDisabled={id !== 1 && upsellCombinationsAreUnavailable}
              onClick={onClick}
            >
              {price && (
                <SpotFinishOptionPrice
                  price={price}
                  priceFormat={priceFormat}
                  isDisabled={upsellCombinationsAreUnavailable}
                  isVisible
                />
              )}
            </SpotFinishOption>
          </div>
        </React.Fragment>
      );
    });

  return (
    <Translation>
      {t => (
        <div id='foil_box' className={cn(styles.infoWrapper, upsellListsStyles.withOverflowMessage)}>
          {upsells && <Text bold darkBlue className={styles.infoTitle}>{t('txt_foil')}:</Text>}
          <div className={styles.infoValuesWrapper}>
            <div className={styles.formatContainer} style={{ marginTop: 0 }}>
              {renderedList}
            </div>
            <Expand in={upsellCombinationsAreUnavailable}>
              <div
                className={cn(
                  styles.note,
                  upsellListsStyles.upsellNote,
                  { [ExpandStyles.exitDone]: !upsellCombinationsAreUnavailable }
                )}
              >
                {t('txt_disabled_foil_note')}
              </div>
            </Expand>
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state: Store) => ({
  priceFormat: priceSelectors.getPriceFormat(state),
  getUpsells: (categoryId: number, productId: number) => priceSelectors.getUpsells(state, categoryId, productId),
});

export default connect(mapStateToProps)(SpotFinishList);
