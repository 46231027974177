import isSmallScreen from '@categoryProduct/component/util/isSmallScreen';
import {
  isOnlyDefaultUpsellValue,
  getSpotFinishesForSelectedTrimId,
  getAvailableTrims,
  getAvailableSpotFinishes,
} from '@categoryProduct/component/util/TrimFoilHelper';
import { UpsellOptions } from '@categoryProduct/typings';
import { getPreviewWithSelectedUpsellInUrl } from '@categoryProduct/component/util/getImagePreviews';

interface SimplifiedFilter {
  name: string;
  value: string;
}

/*
* get first intersected value between applied and available filter values
* we need all applied filters, filter name to search and all available values of current filter
* */
export const getFilterValueToApply = (selectedFilters: SimplifiedFilter[], filterName: string, availableFilterValues: number[]) => {
  const appliedFilterValues = selectedFilters
    .filter((f: SimplifiedFilter) => f.name === filterName)
    .map((e: SimplifiedFilter) => Number(e.value));

  if (!appliedFilterValues.length || !availableFilterValues.length) {
    return null;
  }

  const canApply = appliedFilterValues.filter(v => availableFilterValues.indexOf(v) >= 0);
  return canApply.length ? canApply.reverse()[0] : null;
};

/*
* we should clear all default values of available url effects
* trim and foil with value `1` treated as normal design and should not have this values in the link
* WHY: it's simpler to apply any available effect and then filter default ones at the end
* */
export const cleanDefaultUpsellEffects = (url: string) => url
  .replace(/(_trim_1|_spot_1)/g, '')
  .replace(/[?|&](spot|trim)=1/g, '');

export const cleanUpsellEffects = (url: string) => url
  .replace(/(_trim_[0-9]|_spot_[0-9])/g, '')
  .replace(/[?|&](spot|trim)=[0-9]/g, '');

/*
* get product page url of design based on applied filters in the app
*   if NO filters applied: return same default url
*   if trim AND foil: affected with both url parameters
*   if trim OR foil: affected with 1 url parameter
* */
export const getProductPageUrl = (url: string, selectedFilters: SimplifiedFilter[], upsellOptions: UpsellOptions) => {
  const trims = getAvailableTrims(upsellOptions);
  const spotFinishes = getAvailableSpotFinishes(upsellOptions);
  const trimEffectToApply = getFilterValueToApply(selectedFilters, 'trim', trims);
  const foilEffectToApply = getFilterValueToApply(selectedFilters, 'foil', spotFinishes);
  const productUrl = cleanUpsellEffects(url);
  const sign = productUrl.indexOf('?') > 0 ? '&' : '?';

  // if we have both filters, but no `isCombinationAvailable` => we should pick FOIL or TRIM upsell
  if (trimEffectToApply && foilEffectToApply) {
    const spotFinishedToCombine = getSpotFinishesForSelectedTrimId(upsellOptions, trimEffectToApply);
    const isCombinationAvailable = !isOnlyDefaultUpsellValue(spotFinishedToCombine);

    if (isCombinationAvailable) {
      return `${productUrl}?spot=${foilEffectToApply}&trim=${trimEffectToApply}`;
    }
  }

  if (foilEffectToApply) {
    return `${productUrl}${sign}spot=${foilEffectToApply}`;
  } else if (trimEffectToApply) {
    return `${productUrl}${sign}trim=${trimEffectToApply}`;
  }

  return url;
};

const changePreviewSizeLargeToMedium = (url: string) => url
  .replace('big', 'medium')
  .replace('large', 'medium');

const transformToProperMainPreviewVersion = (url: string, useNewPreviews: boolean) => useNewPreviews
  ? url.replace('listing_medium-', 'medium_perspective-')
  : url.replace('medium_perspective-', 'listing_medium-');

/*
* get preview design image url of design based on applied filters in the app
* we apply 1 of url effects: foil or trim. Currently it's not possible to have both of them at the same time
* NOTE: we also have some custom size modifications in the start and in the end of this function
* NOTE 2: FOIL have more priority, so if both filters applied we would see FOILED design image preview
* */
export const getPreviewUrl = (url: string, selectedFilters: SimplifiedFilter[], upsellOptions: UpsellOptions, useNewPreviews: boolean): string => {
  const trims = getAvailableTrims(upsellOptions);
  const spotFinishes = getAvailableSpotFinishes(upsellOptions);
  // there is no small new previews
  const previewSize = isSmallScreen() && !useNewPreviews ? 'small' : 'medium';
  const mediumSizePreviewUrl = changePreviewSizeLargeToMedium(url);
  let imageUrl = transformToProperMainPreviewVersion(mediumSizePreviewUrl, useNewPreviews);

  const trimEffectToApply = getFilterValueToApply(selectedFilters, 'trim', trims);
  const foilEffectToApply = getFilterValueToApply(selectedFilters, 'foil', spotFinishes);

  // if we have both filters, but no `isCombinationAvailable` => we should pick FOIL or TRIM upsell
  if (trimEffectToApply && foilEffectToApply) {
    const spotFinishedToCombine = getSpotFinishesForSelectedTrimId(upsellOptions, trimEffectToApply);
    const isCombinationAvailable = !isOnlyDefaultUpsellValue(spotFinishedToCombine);

    if (isCombinationAvailable) {
      return getPreviewWithSelectedUpsellInUrl(imageUrl, trimEffectToApply, foilEffectToApply);
    }
  }

  /*
  *  If we apply not default value, then we clear out all effects
  *  If we apply default (1), then we do not clear other effect, as this default one will be filtered at the end
  * */
  if (foilEffectToApply) {
    imageUrl = imageUrl
      .replace(/_(spot|trim)_[0-9]/g, '')
      .replace('3d-', `3d_spot_${foilEffectToApply}-`);
    imageUrl = imageUrl.replace('perspective-', `perspective_spot_${foilEffectToApply}-`);
  } else if (trimEffectToApply) {
    imageUrl = imageUrl
      .replace(/_(spot|trim)_[0-9]/g, '')
      .replace(/(-p[0-9]-)/, `_trim_${trimEffectToApply}$1`);
  }

  // get correct size preview in the end
  return cleanDefaultUpsellEffects(imageUrl)
    .replace('small', previewSize)
    .replace('medium', previewSize)
    .replace('big', previewSize)
    .replace('large', previewSize);
};
