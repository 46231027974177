import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import styles from './styles.css';

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const FrameSizeGuide = ({ isOpen, onClick }: Props) => {

  const RenderModal = (
    <div
      className={classnames(styles.container, {
        [styles.hidden]: !isOpen,
      })}
    >
      <div className={styles.modal}>
        <div className={styles.closeButton} aria-label="Close" onClick={onClick}>×</div>
        <img className={styles.image} src='/s/assets/images/frames/frameSizeGuide.jpg' onClick={onClick} />
      </div>
    </div>
  );

  return typeof document !== 'undefined' ? ReactDOM.createPortal(
    RenderModal,
    document.body,
  ) : null;
};

export default FrameSizeGuide;
