import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Translation } from 'react-i18next';
import cn from 'classnames';
import setInputControlValue from '@categoryProduct/store/controls/operation/setInputControlValue';
import registerInputControl from '@categoryProduct/store/controls/operation/registerInputControl';
import deactivateInputControl from '@categoryProduct/store/controls/operation/deactivateInputControl';
import getControlValue from '@categoryProduct/store/controls/selector/getControlValue';
import { Store } from '@categoryProduct/store/typings';
import ListOption from './ListOption';
import defaultStyles from './index.css';

interface OwnProps {
  options: any[];
  initialValue: any;
  onSelect?: Function;
  styles: any;
  optionRender?: Function;
  section: string;
  name: string;
}

interface Props extends OwnProps {
  dispatch: Dispatch;
  selectedValue: any;
}

export interface SimpleListOption {
  id: string;
  label: string;
  value: string;
}

const SimpleList = (props: Props) => {
  const { options, onSelect, initialValue, styles, optionRender, dispatch, section, name, selectedValue } = props;

  useEffect(() => {
    dispatch(registerInputControl(name, section, false, true, initialValue));
    return () => {
      dispatch(deactivateInputControl(name, section));
    };
  }, []);

  const onSelectWrapper = (value: any) => {
    dispatch(setInputControlValue(name, section, value, true));
    if (onSelect) onSelect(value);
  };

  const restoreDataByValue = (value: any) => {
    const fullValue = options.find(option => option.value === value.value);
    if (!fullValue) {
      return;
    }

    dispatch(setInputControlValue(name, section, fullValue, true));
    if (onSelect) onSelect(fullValue);
  };

  useEffect(() => {
    if (!selectedValue) {
      return;
    }
    if (!selectedValue.data) {
      restoreDataByValue(selectedValue);
      return;
    }
  }, [selectedValue]);

  return (
    <Translation>
      {t => (
        <div className={cn(defaultStyles.simpleList, styles.simpleList || '')}>
          {options.map((option: any) => {
            const isActive = selectedValue ? selectedValue.value === option.value : false;
            return (
              <ListOption key={option.id} option={option} active={isActive} onSelect={onSelectWrapper} styles={styles}>
                {optionRender ? optionRender({ ...option, label: t(option.label) }) : t(option.label)}
              </ListOption>
            );
          })}
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state: Store, { section, name }: OwnProps) => ({
  selectedValue: getControlValue(state, section, name),
});
export default connect(mapStateToProps)(SimpleList);
