import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Translation, withTranslation } from 'react-i18next';
import Text from '@categoryProduct/component/common/Text';
import setInputControlValue from '@categoryProduct/store/controls/operation/setInputControlValue';
import registerInputControl from '@categoryProduct/store/controls/operation/registerInputControl';
import { Store } from '@categoryProduct/store/typings';
import { getDesignFamilies } from '@categoryProduct/store/category/selectors';
import getCategoryId from '@categoryProduct/store/design/selector/getCategoryId';
import { DesignFamily } from '@categoryProduct/typings';
import getDesignData from '@categoryProduct/store/design/selector/getDesignData';
import { DesignData } from '@categoryProduct/store/design/types';
import Option from './Option';
import getSelectorData from './getSelectorData';

import styles from './index.css';

interface OwnProps {
  section: string;
}

interface Props extends OwnProps {
  dispatch: Dispatch;
  designData: DesignData | null;
  family: {
    [key: string]: DesignFamily,
  };
}

// to be picked up by script-collector
withTranslation()(({ t }) => {
  return (
    <>
      {t('txt_single')}
      {t('txt_duo')}
      {t('txt_trio')}s
    </>
  );
});

const getTextKeyByValue = (value: number) => ({
  1: 'txt_single',
  2: 'txt_duo',
  3: 'txt_trio',
}[value]);

const controlName = 'numberOfBabies';

const BabyNumberSelector = (props: Props) => {
  const { section, dispatch, family, designData } = props;
  const selectorData = Object.keys(family).length && designData ? getSelectorData(family, designData) : {};
  const selectedValue = Object.keys(selectorData).reduce((acc, key) => {
    if (selectorData[key].designId === designData!.encPublicDesignId) {
      return Number(key);
    }
    return acc;
  }, 0);

  useEffect(() => {
    dispatch(registerInputControl(controlName, section, true, true, selectedValue));
  }, []);

  useEffect(() => {
    dispatch(setInputControlValue(controlName, section, selectedValue, true));
  }, [ selectedValue ]);

  return (
    <div>
      <Translation>
        {t => (
          <div className={styles.optionsWrapper}>
            <Text darkBlue>{t('txt_number_of_babies')}:</Text>
            {Object.keys(selectorData).map((key) => {
              return (
                <Option
                  key={selectorData[key].designId}
                  text={t(getTextKeyByValue(Number(key)))}
                  url={selectorData[key].url}
                  selected={selectorData[key].designId === designData!.encPublicDesignId}
                />
              );
            })}
          </div>
        )}
      </Translation>
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const categoryId = getCategoryId(state);
  return {
    family: getDesignFamilies(state, categoryId),
    designData: getDesignData(state),
  };
};

export default connect(mapStateToProps)(BabyNumberSelector);
