import { Store } from '../../typings';
import getDesignData from './getDesignData';

const getProductId = (store: Store) => {
  const data = getDesignData(store);

  return data ? data.productId : -1;
};

export default getProductId;
