import { Dispatch } from 'redux';
import global from '@categoryProduct/util/global';
import apiRequest from '@categoryProduct/api/apiRequest';
import endpoints from '@categoryProduct/api/endpoints';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import setIllustrations from '../action/setIllustrations';

const fetchIllustrations = () => async (
  dispatch: Dispatch
) => {
  const params = {
    locale: global('locale'),
  };

  const illustrations = await apiRequest(
    endpoints.listBirthPostersIllustrations,
    params,
    'GET',
    'data.illustrations'
  ) as apiTypings.AppBundle.Api.Entity.Design.V1.IBirthPosterIllustration[];

  dispatch(setIllustrations(illustrations));
  return illustrations;
};

export default fetchIllustrations;
