import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Translation } from 'react-i18next';
import { Store } from '@categoryProduct/store/typings';
import EditorControlsContext from '@categoryProduct/util/context/EditorControlsContext';
import getControlValue from '@categoryProduct/store/controls/selector/getControlValue';
import getIllustrations from '@categoryProduct/store/birthPosters/selector/getIllustrations';
import getBabyOrientation from '@categoryProduct/store/design/selector/getBabyOrientation';
import { BABY_ORIENTATIONS } from '@categoryProduct/store/design/types';
import Text from '@categoryProduct/component/common/Text';
import changeBabyIllustration from '@categoryProduct/store/birthPosters/operation/changeBabyIllustration';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import preloadImages, { collectUrls } from '@categoryProduct/view/ProductBirthPoster/BabyIllustrationSelector/preloadImages';
import SimpleList from '../Controls/SimpleList';

import styles from './index.css';

interface OwnProps {
  section: string;
  babyIndex: number;
}

interface Props extends OwnProps {
  currentValue: () => any;
  illustrations: apiTypings.AppBundle.Api.Entity.Design.V1.IBirthPosterIllustration[];
  babyOrientation: number | null;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const controlName = 'illustration';

interface IllustrationOption {
  label: string;
  value: string;
  data: apiTypings.AppBundle.Api.Entity.Design.V1.IBirthPosterIllustration,
  id: string;
}

let isImagesPreloaded = false;
const BabyIllustrationSelector = ({ section, illustrations, babyOrientation, babyIndex, dispatch }: Props) => {
  let options: (IllustrationOption | null)[] = [];

  useEffect(() => {
    if (illustrations.length && !isImagesPreloaded) {
      isImagesPreloaded = true;
      const urls = collectUrls(illustrations);
      setTimeout(() => {
        // delay preload action
        preloadImages(urls);
      }, 2000);
    }
  }, [ illustrations ]);

  if (illustrations && illustrations.length > 0) {
    const orientation = babyOrientation === BABY_ORIENTATIONS.VERTICAL ? 'Ver' : 'Hor';
    const targetImage = `image${orientation}Left`;

    options = illustrations.map(illustration => {
      if (illustration[targetImage]) {
        return {
          label: illustration.label,
          value: illustration.key,
          data: illustration,
          id: illustration.key,
        };
      }
      return null;
    }).filter(v => v !== null);
  }

  const editorControls = useContext(EditorControlsContext);

  const onChange = (newIllustration: IllustrationOption) => {
    dispatch(changeBabyIllustration(editorControls, newIllustration.data, babyIndex));
  };

  const optionRender = (option: any) => {
    return (
      <div
        className={styles.illustration}
        style={{ backgroundImage: `url(${option.data.thumbnailUrl})` }}
      />
    );
  };

  if (!options.length) {
    return null;
  }

  return (
    <>
      <Translation>
        {t => (
          <div className={styles.backgroundSelector}>
            <Text darkBlue>
              {t('txt_illustration')}
              :
            </Text>
            {options.length > 0 && (
              <SimpleList
                section={section}
                name={controlName}
                options={options}
                initialValue={options[0]}
                optionRender={optionRender}
                onSelect={onChange}
                styles={styles}
              />
            )}
          </div>
        )}
      </Translation>
    </>
  );
};

const mapStateToProps = (state: Store, { section }: OwnProps) => ({
  currentValue: getControlValue(state, section, controlName),
  babyOrientation: getBabyOrientation(state, 'step2'),
  illustrations: getIllustrations(state),
});

export default connect(mapStateToProps)(BabyIllustrationSelector);
