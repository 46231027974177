import React from 'react';
import cn from 'classnames';
import { Translation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import Button from '../../common/Button';
import Text from '../../common/Text';
// import Carousel from './Carousel';
import Carousel from '../../CarouselNew';
import ThumbLikeSection from './ThumbLikeSection';
import styles from './styles.css';
import apiTypings from '../../../api/optimalprint-sdk';
import global from '../../../util/global';
import shouldUseNewPreviews from '../../../util/shouldUseNewPreviews';
import { connect } from 'react-redux';
import { isUndefined } from 'lodash';
import isSmallScreen from '@categoryProduct/component/util/isSmallScreen';
import { isArtistic } from '@categoryProduct/component/util/productTypeIdsHelper';

const NUMBER_SIMILAR_DESIGNS = 12;

interface Props {
  categories: { [s: string]: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo };
  similarProducts?: apiTypings.AppBundle.Api.Entity.Design.V1.SimilarProduct[];
  category: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo;
  seeAllLink: string;
  light?: boolean;
  compact?: boolean;
}

const SimilarProduct: React.FC<Props> = React.memo((props) => {
  const {
    categories,
    light,
    compact,
    similarProducts,
    seeAllLink,
    category: { categoryId },
  } = props;

  if (!similarProducts || !similarProducts.length) {
    return null;
  }

  const useFoil = !isUndefined(global('ab_spot-finish')) ? global('ab_spot-finish') : true;
  const useNewPreviews = shouldUseNewPreviews(categories, categoryId);

  return (
    <Translation>
      {t => (
        <div
          className={cn(styles.container, {
            [styles.noBorder]: light,
            [styles.compact]: compact,
          })}
        >
          <Text header5 className={cn(styles.subCategoryText, { [styles.small]: light })}>
            {t('txt_product_page_other_designs_you_might_like')}
          </Text>
          <div
            className={cn(styles.similarProductsWrapper, {
              [styles.oldPreviews]: !useNewPreviews,
              [styles.withBorder]: compact,
            })}
          >
            <Carousel nextItemPreview>
              {similarProducts && similarProducts.slice(0, NUMBER_SIMILAR_DESIGNS).map(
                similarProduct => {
                  const artistic = isArtistic(similarProduct.productTypeId);
                  const isNewPreview = !artistic && useNewPreviews;
                  const previewSize = isSmallScreen() && !isNewPreview ? 'small' : 'medium';

                  let image = similarProduct.thumbnail;
                  image = image.replace('small', previewSize);

                  if (isNewPreview) {
                    image = image.replace('_3d', '_perspective');
                  }

                  return (
                    <ThumbLikeSection
                      key={`${image}_WrWL`}
                      thumbUrl={image}
                      isFoilAvailable={!compact && useFoil && similarProduct.spotFinishes.some(s => (s > 1))}
                      linkTo={similarProduct.url}
                    />
                  );
                })
              }
            </Carousel>

            <div className={cn(styles.seeAllLikeProductsButtonWrapper, { [styles.hidden]: light })}>
              <a id='seeAllSimilarProducts_link' href={seeAllLink} className={styles.seeAllLikeProductsButtonLink}>
                <Button transparent blueBorder roundCorner fullWidth>
                  {t('label_see_all_orderflow')}
                </Button>
              </a>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
});

const mapStateToProps = (state: any) => {
  return {
    categories: state.category.categories,
  };
};

export default connect(
  mapStateToProps,
)(SimilarProduct);
