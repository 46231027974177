import React from 'react';
import { connect } from 'react-redux';
import ReactPlaceholder from 'react-placeholder';
import { Translation } from 'react-i18next';

import { isEntityFetched } from '@categoryProduct/store/request/selectors';
import Button from '@categoryProduct/component/common/Button';
import { getOrderedUpsellObjects } from '@categoryProduct/component/util/TrimFoilHelper';
import FilterSlider from './FilterSlider';

import 'react-placeholder/lib/reactPlaceholder.css?global';
import styles from './styles.css';

interface OwnProps {
  clickToFilter: (tag: number) => void;
  resetFilter: () => void;
  categoryId: number;
}

interface Props extends OwnProps {
  filters: Filters;
  isAllDesignsAreFetched: boolean;
}

interface SingleFilter {
  items: Filter[];
  onClick: (tag: number) => void;
  isReady: boolean;
  inRow?: boolean;
}

const placeholderStyles = {
  backgroundColor: '#e8e8e8',
  width: '100%',
  height: '30px',
  margin: '15px 10px 15px 0',
};

const SingleFilter = (props: SingleFilter) => (
  <ReactPlaceholder
    showLoadingAnimation
    type='rect'
    ready={props.isReady}
    style={placeholderStyles}
  >
    <FilterSlider items={props.items} clickToFilter={props.onClick} inRow={Boolean(props.inRow)} />
  </ReactPlaceholder>
);

const FiltersContainer = (props: Props) => {
  const {
    filters,
    clickToFilter,
    resetFilter,
    isAllDesignsAreFetched,
  } = props;

  return (
    <Translation>
      {t => (
        <div id='filters_wrapper' className={styles.category}>
          {filters.color && filters.color.length > 1 && (
            <div id='color-filter-box'>
              <span className={styles.categoryName}>{t('txt_filter_color')}</span>
              <SingleFilter items={filters.color} onClick={clickToFilter} isReady={isAllDesignsAreFetched} inRow />
            </div>
          )}
          {filters.foil && filters.foil.length > 1 && (
            <div id='spotfinish-filter_wrapper' className='spotfinish'>
              <span className={styles.categoryName}>{t('txt_filter_spotFinish')}</span>
              <SingleFilter
                items={getOrderedUpsellObjects(filters.foil)}
                onClick={clickToFilter}
                isReady={isAllDesignsAreFetched}
                inRow
              />
            </div>
          )}
          {filters.trim && filters.trim.length > 1 && (
            <div id='trim-filter_wrapper'>
              <span className={styles.categoryName}>{t('txt_filter_trim')}</span>
              <SingleFilter
                items={getOrderedUpsellObjects(filters.trim)}
                onClick={clickToFilter}
                isReady={isAllDesignsAreFetched}
              />
            </div>
          )}
          {filters.folded && filters.folded.length > 1 && (
            <div id='folded-filter_wrapper'>
              <span className={styles.categoryName}>{t('txt_filter_folded')}</span>

              <SingleFilter items={filters.folded} onClick={clickToFilter} isReady={isAllDesignsAreFetched} />
            </div>
          )}
          {filters.gender && filters.gender.length > 1 && (
            <div id='gender-filter_wrapper'>
              <span className={styles.categoryName}>{t('txt_filter_gender')}</span>
              <SingleFilter items={filters.gender} onClick={clickToFilter} isReady={isAllDesignsAreFetched} />
            </div>
          )}
          {filters.nfotos && filters.nfotos.length > 1 && (
            <div id='nfotos-filter_wrapper'>
              <span className={styles.categoryName}>{t('txt_filter_imageCounter')}</span>
              <SingleFilter items={filters.nfotos} onClick={clickToFilter} isReady={isAllDesignsAreFetched} />
            </div>
          )}
          {filters.orient && filters.orient.length > 1 && (
            <div id='orientation-filter-box'>
              <span className={styles.categoryName}>{t('txt_filter_orientation')}</span>
              <SingleFilter items={filters.orient} onClick={clickToFilter} isReady={isAllDesignsAreFetched} />
            </div>
          )}
          {filters.size && filters.size.length > 1 && (
            <div id='size-filter-box'>
              <span className={styles.categoryName}>{t('txt_filter_format')}</span>
              <SingleFilter items={filters.size} onClick={clickToFilter} isReady={isAllDesignsAreFetched} />
            </div>
          )}
          <Button
            id='resetFilter_Button'
            onClick={resetFilter}
            deleteAllButton
          >
            {t('txt_category_mobile_delete_all_filters')}
          </Button>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state: any, props: OwnProps) => ({
  filters: state.category.filters,
  isAllDesignsAreFetched: isEntityFetched(state, `category/${props.categoryId}`),
});

export default connect(mapStateToProps)(FiltersContainer);
