import React from 'react';
import { useTranslation } from 'react-i18next';
import BabyIndexContext from '@categoryProduct/util/context/BabyIndexContext';
import SimpleSection from '../Controls/SimpleSection';
import BabyNameInput from './BabyNameInput';
import BabyBirthDateInput from './BabyBirthDateInput';
import BabyBirthTimeInput from './BabyBirthTimeInput';
import BabyWeightInput from './BabyWeightInput';
import BabyLengthInput from './BabyLengthInput';
import BabyFacingSelector from '../BabyFacingSelector';
import BabyIllustrationSelector from '../BabyIllustrationSelector';
import AddToBasketButton from '../AddToBasketButton';
import NextStepButton from '../NextStepButton';

import styles from './index.css';

interface Props {
  babyIndex: number;
  numberOfBabies: number;
  isLastStep: boolean;
  titleKey?: string;
}

const BabyDataInput = ({ babyIndex, numberOfBabies, isLastStep, titleKey }: Props) => {
  const { t } = useTranslation();
  const section = `step${1 + babyIndex}`;
  const prevSection = `step${babyIndex}`;
  return (
    <SimpleSection
      title={t(titleKey || 'txt_step_baby', { step: 1 + babyIndex, baby: babyIndex })}
      section={section}
      allowUserExpand
      expandIfSectionValid={prevSection}
    >
      <div className={styles.inputControls}>
        <BabyIndexContext.Provider value={babyIndex}>
          <BabyNameInput section={section} />
          <BabyBirthDateInput section={section} />
          <BabyBirthTimeInput section={section} />
          <BabyWeightInput section={section} />
          <BabyLengthInput section={section} />
          {numberOfBabies > 1 && (
            <>
              <BabyIllustrationSelector section={section} babyIndex={babyIndex} />
              <BabyFacingSelector section={section} babyIndex={babyIndex} />
            </>
          )}
          {isLastStep && <AddToBasketButton />}

          {!isLastStep && <NextStepButton ifSectionValid={section} nextSection={`step${2 + babyIndex}`} />}
        </BabyIndexContext.Provider>
      </div>
    </SimpleSection>
  );
};

export default BabyDataInput;
