const categoriesEnum = {
    CATEGORY_BUSINESS_CARDS: 1,
    CATEGORY_BUSINESS_LETTERHEADS: 2,
    CATEGORY_BUSINESS_CORRESPONDENCE_CARDS: 3,
    CATEGORY_BUSINESS_ENVELOPES: 4,
    CATEGORY_BUSINESS_BROCHURES: 6,
    CATEGORY_BUSINESS_APPOINTMENT_CARDS: 7,
    CATEGORY_POSTCARDS_ID: 8,
    CATEGORY_INV_KIDS_ID: 9,
    CATEGORY_INV_CONFIRMATION_ID: 10,
    CATEGORY_INV_BIRTHDAY_ID: 11,
    CATEGORY_TYC_ID: 12,
    CATEGORY_CHRISTMAS_CARDS_ID: 13,
    CATEGORY_INV_NEW_YEAR_ID: 14,
    CATEGORY_INV_NEWBABY_ID: 15,
    CATEGORY_INV_BAPTISM_ID: 16,
    CATEGORY_INV_PARTY_ID: 17,
    CATEGORY_WEDDING_INV_ID: 18,
    CATEGORY_WEDDING_DRIVING_INSTRUCTIONS_ID: 19,
    CATEGORY_WEDDING_RSVP_ID: 20,
    CATEGORY_WEDDING_CHURCH_PROGRAMS_ID: 21,
    CATEGORY_WEDDING_MENU_ID: 22,
    CATEGORY_PLACE_CARDS_ID: 23,
    CATEGORY_FLYERS: 25,
    CATEGORY_WALL_CALENDAR_ID: 312,
    CATEGORY_ADDRESS_LABELS_ID: 315,
    CATEGORY_POSTERS_ID: 318,
    CATEGORY_BUSINESS_POSTERS_ID: 327,
    CATEGORY_BUSINESS_POSTCARDS_ID: 333,
    CATEGORY_BUSINESS_CHRISTMAS_CARDS_ID: 340,
    CATEGORY_PHOTO_BOOKS: 350,
    CATEGORY_PB_A4_VER_HAR: 351,
    CATEGORY_PB_A4_VER_SOF: 352,
    CATEGORY_PB_A4_HOR_HAR: 353,
    CATEGORY_PB_A4_HOR_SOF: 354,
    CATEGORY_PB_A5_VER_HAR: 355,
    CATEGORY_PB_A5_VER_SOF: 356,
    CATEGORY_PB_A5_HOR_HAR: 357,
    CATEGORY_PB_A5_HOR_SOF: 358,
    CATEGORY_PB_DL_HAR: 359,
    CATEGORY_PB_DL_SOF: 360,
    CATEGORY_BAPTISM_BOY: 365,
    CATEGORY_BAPTISM_GIRL: 366,
    CATEGORY_BAPTISM_PHOTO_ID: 367,
    CATEGORY_BAPTISM_CLASSIC_ID: 368,
    CATEGORY_BAPTISM_CONTEMPORARY_ID: 369,
    CATEGORY_BAPTISM_PORTRAIT_ID: 371,
    CATEGORY_BAPTISM_VINTAGE_ID: 372,
    CATEGORY_BIRTH_ANN_BOY_ID: 389,
    CATEGORY_BIRTH_ANN_GIRL_ID: 390,
    CATEGORY_BIRTH_ANN_PHOTO_ID: 391,
    CATEGORY_BIRTH_ANN_TWINS_ID: 392,
    CATEGORY_BIRTH_ANN_CLASSIC_ID: 393,
    CATEGORY_BIRTH_ANN_CONTEMPORARY_ID: 394,
    CATEGORY_BIRTH_ANN_PORTRAIT_ID: 395,
    CATEGORY_BIRTH_ANN_VINTAGE_ID: 396,
    CATEGORY_INV_PHOTO: 397,
    CATEGORY_INV_CLASSIC: 398,
    CATEGORY_INV_NEW_HOME: 399,
    CATEGORY_INV_DINNER_PARTY: 400,
    CATEGORY_INV_ANNIVERSARY: 401,
    CATEGORY_INV_25_ANNIVERSARY: 402,
    CATEGORY_INV_50_ANNIVERSARY: 403,
    CATEGORY_INV_BARBECUE: 406,
    CATEGORY_INV_GRADUATION: 409,
    CATEGORY_INV_SURPRISE: 415,
    CATEGORY_TYC_CONFIRMATION_ID: 416,
    CATEGORY_TYC_WEDDING_ID: 417,
    CATEGORY_TYC_BAPTISM_ID: 418,
    CATEGORY_TYC_BABY_ID: 419,
    CATEGORY_TYC_FUNERAL_ID: 420,
    CATEGORY_INV_ID: 422,
    CATEGORY_CHRISTMAS_NO_PHOTO_ID: 438,
    CATEGORY_HOLIDAYS_CARD_ID: 439,
    CATEGORY_INV_WEDDING_ARTISTIC_ID: 472,
    CATEGORY_INV_WEDDING_TRADITIONAL_ID: 473,
    CATEGORY_INV_WEDDING_MODERN_ID: 474,
    CATEGORY_INV_WEDDING_SPRING_ID: 475,
    CATEGORY_INV_WEDDING_SUMMER_ID: 476,
    CATEGORY_TYC_GRADUATION: 477,
    CATEGORY_INV_BIRTHDAY_ADULT_ID: 478,
    CATEGORY_TYC_WEDDING_NO_PHOTO_ID: 479,
    CATEGORY_TYC_WEDDING_PHOTO_ID: 480,
    CATEGORY_TYC_WEDDING_NEW_ID: 481,
    CATEGORY_TYC_BABY_NEWBORN_BOY_ID: 482,
    CATEGORY_TYC_BABY_NEWBORN_GIRL_ID: 483,
    CATEGORY_TYC_BABY_NEWBORN_TWIN_ID: 484,
    CATEGORY_TYC_BABY_NEWBORN_NEW_ID: 485,
    CATEGORY_TYC_BAPTISM_BOY_ID: 486,
    CATEGORY_TYC_BAPTISM_GIRL_ID: 487,
    CATEGORY_TYC_BAPTISM_TWIN_ID: 488,
    CATEGORY_TYC_BAPTISM_PHOTO_ID: 489,
    CATEGORY_TYC_BAPTISM_NO_PHOTO_ID: 490,
    CATEGORY_INV_CHRISTMAS_PARTY_ID: 491,
    CATEGORY_INV_PARTY_GENERAL_BIRTHDAY: 492,
    CATEGORY_INV_BIRTHDAY_60_ID: 498,
    CATEGORY_INV_COMMUNION_ID: 502,
    CATEGORY_TYC_COMMUNION_ID: 503,
    CATEGORY_WED_SAVE_THE_DATE_ID: 504,
    CATEGORY_INV_BABY_SHOWER_ID: 505,
    CATEGORY_TYC_BABY_SHOWER_ID: 506,
    CATEGORY_INV_BRIDAL_SHOWER_ID: 507,
    CATEGORY_TYC_BRIDAL_SHOWER_ID: 508,
    CATEGORY_WED_ENGAGEMENT_ID: 509,
    CATEGORY_CHRISTMAS_BUSINESS_CARDS_ID: 512,
    CATEGORY_INV_BIRTHDAY_AGE_1_ID: 516,
    CATEGORY_INV_BIRTHDAY_AGE_2_ID: 517,
    CATEGORY_INV_BIRTHDAY_AGE_3_ID: 518,
    CATEGORY_WEDDING_CLASSIC_ID: 537,
    CATEGORY_WEDDING_ORIGINAL_ID: 538,
    CATEGORY_WEDDING_VINTAGE_ID: 539,
    CATEGORY_WEDDING_RUSTIC_ID: 540,
    CATEGORY_WEDDING_ORIENTAL_ID: 541,
    CATEGORY_WEDDING_PHOTO_ID: 542,
    CATEGORY_WEDDING_FLORAL_ID: 543,
    CATEGORY_WEDDING_LACE_ID: 544,
    CATEGORY_WEDDING_CHALKBOARD_ID: 545,
    CATEGORY_WEDDING_WATERCOLOUR_ID: 546,
    CATEGORY_WEDDING_TYPOGRAPHY_ID: 547,
    CATEGORY_WEDDING_ROMANTIC_ID: 548,
    CATEGORY_WEDDING_SEASONAL_ID: 549,
    CATEGORY_WEDDING_BEACH_ID: 550,
    CATEGORY_WEDDING_DESTINATION_ID: 551,
    CATEGORY_WEDDING_SAME_SEX_ID: 552,
    CATEGORY_WEDDING_MONOGRAM_ID: 553,
    CATEGORY_WEDDING_SCANDINAVIAN_ID: 554,
    CATEGORY_WEDDING_SIMPLE_ID: 555,
    CATEGORY_WEDDING_ELEGANT_ID: 556,
    CATEGORY_CHRISTENING_MENU_ID: 557,
    CATEGORY_COMMUNION_MENU_ID: 559,
    CATEGORY_CONFIRMATION_MENU_ID: 561,
    CATEGORY_FUNERAL_ORDER_OF_SERVICE: 564,
    CATEGORY_FLYPBOOKS: 573,
    CATEGORY_WEDDING_TYC_CLASSIC_ID: 574,
    CATEGORY_WEDDING_TYC_ORIGINAL_ID: 575,
    CATEGORY_WEDDING_TYC_VINTAGE_ID: 576,
    CATEGORY_WEDDING_TYC_RUSTIC_ID: 577,
    CATEGORY_WEDDING_TYC_ORIENTAL_ID: 578,
    CATEGORY_WEDDING_TYC_PHOTO_ID: 579,
    CATEGORY_WEDDING_TYC_FLORAL_ID: 580,
    CATEGORY_WEDDING_TYC_LACE_ID: 581,
    CATEGORY_WEDDING_TYC_CHALKBOARD_ID: 582,
    CATEGORY_WEDDING_TYC_WATERCOLOUR_ID: 583,
    CATEGORY_WEDDING_TYC_TYPOGRAPHY_ID: 584,
    CATEGORY_WEDDING_TYC_ROMANTIC_ID: 585,
    CATEGORY_WEDDING_TYC_SEASONAL_ID: 586,
    CATEGORY_WEDDING_TYC_BEACH_ID: 587,
    CATEGORY_WEDDING_TYC_DESTINATION_ID: 588,
    CATEGORY_WEDDING_TYC_MONOGRAM_ID: 589,
    CATEGORY_WEDDING_TYC_SCANDINAVIAN_ID: 590,
    CATEGORY_WEDDING_TYC_SIMPLE_ID: 591,
    CATEGORY_WEDDING_TYC_ELEGANT_ID: 592,
    CATEGORY_WEDDING_TYC_MODERN_ID: 593,
    CATEGORY_CALENDAR_POSTERS: 602,
    CATEGORY_WALL_CALENDAR_LARGE_LANDSCAPE_DOUBLE_ID: 603,
    CATEGORY_WALL_CALENDAR_LARGE_PORTRAIT_ID: 604,
    CATEGORY_WALL_CALENDAR_MEDIUM_LANDSCAPE_ID: 605,
    CATEGORY_WALL_CALENDAR_MEDIUM_PORTRAIT_ID: 606,
    CATEGORY_INV_CHRISTMAT_NEW_ID: 607,
    CATEGORY_TYC_BABY_NEWBORN_NO_PHOTO_ID: 608,
    CATEGORY_TYC_BABY_NEWBORN_COLLAGE_ID: 609,
    CATEGORY_TYC_BABY_NEWBORN_PHOTO_ID: 610,
    CATEGORY_POSTCARDS_NO_PHOTO_ID: 611,
    CATEGORY_POSTCARDS_PHOTO_ID: 612,
    CATEGORY_POSTCARDS_PHOTO_COLLAGE_ID: 613,
    CATEGORY_TYC_WED_NEW_ID: 614,
    CATEGORY_CHRISTMAS_CARDS_BEST_ID: 629,
    CATEGORY_INV_WEDDING_NEW_ID: 630,
    CATEGORY_FLIPBOOK_ID: 639,
    CATEGORY_PHOTOBOOK_QUICK_ID: 640,
    CATEGORY_PHOTOBOOK_SERIAL_ID: 641,
    CATEGORY_POSTERS_COLLAGE_ID: 642,
    CATEGORY_POSTERS_NATURE_ID: 643,
    CATEGORY_POSTERS_ANIMALS_ID: 644,
    CATEGORY_POSTERS_KIDS_ID: 645,
    CATEGORY_POSTERS_ILLUSTRATIONS_ID: 646,
    CATEGORY_CHRISTMAS_BUSINESS_NEW_ID: 647,
    CATEGORY_CHRISTMAS_BUSINESS_CHRISTMAS_TREE_ID: 648,
    CATEGORY_CHRISTMAS_BUSINESS_CHRISTMAS_ORNAMENT_ID: 649,
    CATEGORY_CHRISTMAS_BUSINESS_TYPOGRAPHIC_ID: 650,
    CATEGORY_CHRISTMAS_BUSINESS_DEER_ID: 651,
    CATEGORY_CHRISTMAS_BUSINESS_SHOWFLAKE_ID: 652,
    CATEGORY_CHRISTMAS_BUSINESS_PHOTO_IN_FRONT_ID: 653,
    CATEGORY_CHRISTMAS_BUSINESS_WINTER_LANDSCAPE_ID: 654,
    CATEGORY_FUNERAL_INV_ID: 655,
    CATEGORY_ARTISTIC_POSTERS_ID: 656,
    CATEGORY_ARTISTIC_POSTERS_NATURE_ID: 657,
    CATEGORY_ARTISTIC_POSTERS_PHOTOGRAPHY_ID: 658,
    CATEGORY_ARTISTIC_POSTERS_MAPS_AND_CITES_ID: 659,
    CATEGORY_ARTISTIC_POSTERS_BLACK_AND_WHITE_ID: 660,
    CATEGORY_ARTISTIC_POSTERS_KIDS_ID: 661,
    CATEGORY_ARTISTIC_POSTERS_FASHION_ID: 662,
    CATEGORY_ARTISTIC_POSTERS_ANIMALS_ID: 663,
    CATEGORY_ARTISTIC_POSTERS_QUOTES_ID: 664,
    CATEGORY_ARTISTIC_POSTERS_ART_ID: 665,
    CATEGORY_ARTISTIC_POSTERS_KITCHEN_ID: 666,
    CATEGORY_TYC_CHRISTMAS_ID: 667,
    CATEGORY_CHRISTMAS_BUSINESS_PARTY_ID: 668,
    CATEGORY_INV_HALLOWEEN_ID: 669,
    CATEGORY_FAMILY_PLANNER_CALENDAR_ID: 670,
    CATEGORY_INV_SECULAR_CONF_ID: 671,
    CATEGORY_TYC_SECULAR_CONF_ID: 672,
    CATEGORY_MENU_SECULAR_CONF_ID: 673,
    CATEGORY_PLACE_CARDS_SECULAR_CONF_ID: 674,
    CATEGORY_INV_CATHOLIC_CONF_ID: 675,
    CATEGORY_TYC_CATHOLIC_CONF_ID: 676,
    CATEGORY_MENU_CATHOLIC_CONF_ID: 677,
    CATEGORY_PLACE_CARDS_CATHOLIC_CONF_ID: 678,
    CATEGORY_CHRISTENING_PLACE_CARDS_ID: 679,
    CATEGORY_ARTISTIC_POSTERS_BOTANICAL_ID: 736,
    CATEGORY_ARTISTIC_POSTERS_MARITIME_ID: 737,
    CATEGORY_ARTISTIC_POSTERS_VINTAGE_ID: 738,
    CATEGORY_ARTISTIC_POSTERS_GOLD_AND_SILVER_ID: 739,
    CATEGORY_ARTISTIC_POSTERS_NEW_ID: 740,
    CATEGORY_ARTISTIC_POSTERS_BEST_SELLERS_ID: 741,

    CATEGORY_ANY_BUSINESS_PRODUCT_ID: 9998,
    CATEGORY_NOT_CHRISTMAS_CARDS_ID: 9999,
};


export const photobookCustomCategories = [
    categoriesEnum.CATEGORY_PHOTO_BOOKS,
    categoriesEnum.CATEGORY_FLYPBOOKS,
    categoriesEnum.CATEGORY_FLIPBOOK_ID,
    categoriesEnum.CATEGORY_PHOTOBOOK_QUICK_ID,
];

export const postcardsCategories = [
    categoriesEnum.CATEGORY_POSTCARDS_ID,
    categoriesEnum.CATEGORY_POSTCARDS_PHOTO_ID,
    categoriesEnum.CATEGORY_POSTCARDS_NO_PHOTO_ID,
    categoriesEnum.CATEGORY_POSTCARDS_PHOTO_COLLAGE_ID,
];

export const weddingStyles = [
    categoriesEnum.CATEGORY_WEDDING_CLASSIC_ID,
    categoriesEnum.CATEGORY_INV_WEDDING_MODERN_ID,
    categoriesEnum.CATEGORY_WEDDING_ORIGINAL_ID,
    categoriesEnum.CATEGORY_WEDDING_VINTAGE_ID,
    categoriesEnum.CATEGORY_WEDDING_RUSTIC_ID,
    categoriesEnum.CATEGORY_WEDDING_ORIENTAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_CLASSIC_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_MODERN_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ORIGINAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_VINTAGE_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_RUSTIC_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ORIENTAL_ID,
];
export const weddingThemes = [
    categoriesEnum.CATEGORY_WEDDING_PHOTO_ID,
    categoriesEnum.CATEGORY_WEDDING_FLORAL_ID,
    categoriesEnum.CATEGORY_WEDDING_LACE_ID,
    categoriesEnum.CATEGORY_WEDDING_CHALKBOARD_ID,
    categoriesEnum.CATEGORY_WEDDING_WATERCOLOUR_ID,
    categoriesEnum.CATEGORY_WEDDING_TYPOGRAPHY_ID,
    categoriesEnum.CATEGORY_WEDDING_ROMANTIC_ID,
    categoriesEnum.CATEGORY_WEDDING_SEASONAL_ID,
    categoriesEnum.CATEGORY_WEDDING_BEACH_ID,
    categoriesEnum.CATEGORY_WEDDING_DESTINATION_ID,
    categoriesEnum.CATEGORY_WEDDING_SAME_SEX_ID,
    categoriesEnum.CATEGORY_WEDDING_MONOGRAM_ID,
    categoriesEnum.CATEGORY_WEDDING_SCANDINAVIAN_ID,
    categoriesEnum.CATEGORY_WEDDING_SIMPLE_ID,
    categoriesEnum.CATEGORY_WEDDING_ELEGANT_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_PHOTO_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_FLORAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_LACE_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_CHALKBOARD_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_WATERCOLOUR_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_TYPOGRAPHY_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ROMANTIC_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_SEASONAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_BEACH_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_DESTINATION_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_MONOGRAM_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_SCANDINAVIAN_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_SIMPLE_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ELEGANT_ID,
];

export const weddingTYCStyles = [
    categoriesEnum.CATEGORY_WEDDING_TYC_CLASSIC_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_MODERN_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ORIGINAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_VINTAGE_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_RUSTIC_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ORIENTAL_ID,
];

export const weddingTYCThemes = [
    categoriesEnum.CATEGORY_WEDDING_TYC_PHOTO_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_FLORAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_LACE_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_CHALKBOARD_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_WATERCOLOUR_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_TYPOGRAPHY_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ROMANTIC_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_SEASONAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_BEACH_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_DESTINATION_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_MONOGRAM_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_SCANDINAVIAN_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_SIMPLE_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ELEGANT_ID,
];

export const weddingSetCategories = [
    categoriesEnum.CATEGORY_WEDDING_INV_ID,
    categoriesEnum.CATEGORY_PLACE_CARDS_ID,
    categoriesEnum.CATEGORY_WEDDING_DRIVING_INSTRUCTIONS_ID,
    categoriesEnum.CATEGORY_WEDDING_RSVP_ID,
    categoriesEnum.CATEGORY_WEDDING_MENU_ID,
    categoriesEnum.CATEGORY_WEDDING_CHURCH_PROGRAMS_ID,
    categoriesEnum.CATEGORY_WED_SAVE_THE_DATE_ID,
];

export const weddingThankYouCards = [
    categoriesEnum.CATEGORY_TYC_WEDDING_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_CLASSIC_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ORIGINAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_VINTAGE_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_RUSTIC_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ORIENTAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_PHOTO_ID,
    categoriesEnum.CATEGORY_TYC_WEDDING_NO_PHOTO_ID,
    categoriesEnum.CATEGORY_TYC_WEDDING_PHOTO_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_FLORAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_LACE_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_CHALKBOARD_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_WATERCOLOUR_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_TYPOGRAPHY_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ROMANTIC_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_SEASONAL_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_BEACH_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_DESTINATION_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_MONOGRAM_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_SCANDINAVIAN_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_SIMPLE_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_ELEGANT_ID,
    categoriesEnum.CATEGORY_WEDDING_TYC_MODERN_ID,
    categoriesEnum.CATEGORY_TYC_WED_NEW_ID,
];

export const wallpaperCategories = [
    categoriesEnum.CATEGORY_WALL_CALENDAR_ID,
    categoriesEnum.CATEGORY_WALL_CALENDAR_MEDIUM_PORTRAIT_ID,
    categoriesEnum.CATEGORY_WALL_CALENDAR_LARGE_PORTRAIT_ID,
    categoriesEnum.CATEGORY_WALL_CALENDAR_MEDIUM_LANDSCAPE_ID,
    categoriesEnum.CATEGORY_WALL_CALENDAR_LARGE_LANDSCAPE_DOUBLE_ID,
    categoriesEnum.CATEGORY_FAMILY_PLANNER_CALENDAR_ID,
];

export const postersCategories = [
    categoriesEnum.CATEGORY_POSTERS_ID,
    categoriesEnum.CATEGORY_POSTERS_ANIMALS_ID,
    categoriesEnum.CATEGORY_POSTERS_COLLAGE_ID,
    categoriesEnum.CATEGORY_POSTERS_ILLUSTRATIONS_ID,
    categoriesEnum.CATEGORY_POSTERS_KIDS_ID,
    categoriesEnum.CATEGORY_POSTERS_NATURE_ID,
    categoriesEnum.CATEGORY_CALENDAR_POSTERS,
];

export const artisticPostersCategories = [
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_NATURE_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_PHOTOGRAPHY_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_MAPS_AND_CITES_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_BLACK_AND_WHITE_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_KIDS_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_FASHION_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_ANIMALS_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_QUOTES_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_ART_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_KITCHEN_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_BOTANICAL_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_MARITIME_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_VINTAGE_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_GOLD_AND_SILVER_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_NEW_ID,
    categoriesEnum.CATEGORY_ARTISTIC_POSTERS_BEST_SELLERS_ID,
];

export const funeralCategories = [
    categoriesEnum.CATEGORY_FUNERAL_INV_ID,
    categoriesEnum.CATEGORY_TYC_FUNERAL_ID,
    categoriesEnum.CATEGORY_FUNERAL_ORDER_OF_SERVICE,
];


export default categoriesEnum;
