import React, { useState } from 'react';
import classnames from 'classnames';
// @ts-ignore
import filled from './trustpilot-star-filled.png';
// @ts-ignore
import empty from './trustpilot-star-empty.png';

import styles from './styles.css';
import iconStyles from '../../commonStyles/icon.css';

import Text from '../../common/Text';

interface Props {
  title: string;
}

const Accordion: React.FC<Props> = ({ title, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div
        className={styles.titlebar}
        onClick={() => setOpen(!open)}
      >
        <Text header5 className={styles.subCategoryText}>{title}</Text>
        <i
          className={classnames(iconStyles.icon, styles.accordionIcon, {
            [styles.open]: open,
          })}
        />
      </div>
      <div
        className={classnames(styles.content, styles.reviewsContainer, {
          [styles.open]: open,
        })}
      >
        {children}
      </div>
    </div >
  );
};

export default Accordion;

