import React from 'react';
import { connect } from 'react-redux';
import { categorySelectors } from '@categoryProduct/store/category';
import { Store } from '@categoryProduct/store/typings';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import styles from '@categoryProduct/component/Product/Product.css';
import Text from '@categoryProduct/component/common/Text';
import SelectWrapper from '@categoryProduct/component/Select';
import { Translation } from 'react-i18next';

interface OwnProps {
  onChange: (e: any) => void;
  value: number;
}

interface Props extends OwnProps {
  sizes: apiTypings.AppBundle.Api.Entity.PaperFormat.V1.PaperFormat[];
}

const FrameSizeSelector = (props: Props) => {
  const { sizes, onChange, value } = props;
  const options = sizes.map((e: any) => {
    return {
      label: e.paperFormat.name,
      value: e.paperFormat.paperFormatId,
      designUrl: e.designs[0].designUrl,
      categoryId: e.designs[0].categoryId,
      productId: e.designs[0].product.productId,
      encPublicDesignId: e.designs[0].encPublicDesignId,
    };
  });

  return (
    <Translation>
      {t =>
        <div id='sizeBox' className={styles.infoWrapper}>
          <Text bold darkBlue className={styles.infoTitle}>{t('txt_size')}:</Text>
          <div className={styles.infoValuesWrapper}>
            <SelectWrapper
              value={value}
              onChange={onChange}
              options={options}
            />
          </div>
        </div>
      }
    </Translation>
  );
};

const mapStateToProps = (state: Store) => ({
  sizes: categorySelectors.getFrameFormats(state),
});

export default connect(mapStateToProps)(FrameSizeSelector);
