import React, { useContext } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import EditorControlsContext from '@categoryProduct/util/context/EditorControlsContext';
import BabyIndexContext from '@categoryProduct/util/context/BabyIndexContext';
import updatePosterPlaceholder from '@categoryProduct/store/birthPosters/operation/updatePosterPlaceholder';
import Text from '@categoryProduct/component/common/Text';
import SimpleInput from '../../Controls/SimpleInput';
import SimpleDropdown from '../../Controls/SimpleSelector';
import styles from './index.css';
import { Store } from '@categoryProduct/store/typings';

interface OwnProps {
  section: string;
}

interface Props extends OwnProps {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const hoursControl = 'birthHour';
const minutesControl = 'birthMin';
const ampmControl = 'birthAmPm';

const BabyBirthTimeInput = ({ section, dispatch }: Props) => {

  const h = new Date().getHours();
  const m = new Date().getMinutes();
  const firstZero = (n: number) => (n < 10 ? `0${n}` : String(n));

  const initialValue = '';
  const initialValueHours = firstZero(h);
  const initialValueMinutes = firstZero(m);
  const { t } = useTranslation();

  const ampmOptions = [
    {
      label: t('txt_24h').toUpperCase(),
      value: '',
      id: '1',
    },
    {
      label: 'AM',
      value: 'AM',
      id: '2',
    },
    {
      label: 'PM',
      value: 'PM',
      id: '3',
    },
  ];
  const editorControls = useContext(EditorControlsContext);
  const babyIndex = useContext(BabyIndexContext);
  const validator = () => {
    return true;
  };
  const onChange = () => {
    if (editorControls) {
      dispatch(updatePosterPlaceholder(editorControls, section, babyIndex));
    }
  };

  return (
    <>
      <Translation>
        {t => (
          <div
            className={styles.birthDate}
          >
            <Text darkBlue>{t('txt_time_of_birth')}:</Text>
            <div className={styles.inputs}>
              <SimpleInput
                className={styles.hours}
                initialValue={initialValueHours}
                validator={validator}
                onChange={onChange}
                section={section}
                type="number"
                name={hoursControl}
              />
              <div className={styles.delimiter}>:</div>
              <SimpleInput
                className={styles.mins}
                initialValue={initialValueMinutes}
                validator={validator}
                onChange={onChange}
                section={section}
                type="number"
                name={minutesControl}
              />
              <SimpleDropdown
                className={styles.ampm}
                initialValue={initialValue}
                onSelect={onChange}
                options={ampmOptions}
                section={section}
                name={ampmControl}
              />
            </div>
          </div>
        )}
      </Translation>
    </>
  );
};

export default connect()(BabyBirthTimeInput);
