import ProductType, { postersIds, wallCalendarIds, placeCards, businessCardProducts, nonPersonalisedProducts } from '../../api/productTypes';

export const isArtistic = (productTypeId: number) => productTypeId === ProductType.ARTISTIC_POSTERS;

export const isNonPersonalisedProduct = (productTypeId: number) => {
  return nonPersonalisedProducts.indexOf(productTypeId) >= 0;
}

export const isPoster = (productTypeId: number) => postersIds.indexOf(productTypeId) >= 0;

export const isCalendar = (productTypeId: number) => wallCalendarIds.indexOf(productTypeId) >= 0;

export const isPlaceCard = (productTypeId: number) => placeCards.indexOf(productTypeId) >= 0;

export const isBusinessCard = (productTypeId: number) => businessCardProducts.indexOf(productTypeId) >= 0;

export const isFrame = (productTypeId: number) => [ProductType.POSTER_HANGER, ProductType.POSTER_FRAME].indexOf(productTypeId) !== -1;
