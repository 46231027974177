import { Dispatch } from 'redux';
import apiRequest from '@categoryProduct/api/apiRequest';
import endpoints from '@categoryProduct/api/endpoints';
import loadCustomerDesign from '../action/loadCustomerDesign';
import setDesignStatus from '../action/setDesignStatus';
import setDesignData from '../action/setDesignData';
import { DesignStatus } from '../types';

const loadCustomerDesignOperation = (customerDesignId: string | null) => async (dispatch: Dispatch) => {
  if(!customerDesignId) {
    return null;
  }
  dispatch(loadCustomerDesign(customerDesignId));
  return apiRequest(endpoints.customerDesignGet, { customerDesignId: parseInt(customerDesignId, 10) }, 'POST', 'data')
    .then((design) => {
      return dispatch(setDesignData(design));
    }, () => {
      return dispatch(setDesignStatus(DesignStatus.DESIGN_FAILED));
    });
};

export default loadCustomerDesignOperation;
