import { Store } from '../../typings';
import isControlValid from './isControlValid';

const isSectionValid = (store: Store, sectionId: string): boolean => {
  if (!store.controls.sections || !store.controls.sections[sectionId]) {
    return false;
  }
  return !Object.keys(store.controls.sections[sectionId]).some(controlName => !isControlValid(store, sectionId, controlName));
};

export default isSectionValid;
