import { AnyAction } from 'redux';
import { DesignState, DesignStatus, DesignType } from '../types';

const loadPublicDesign = (state: DesignState, { customerDesignId }: AnyAction) => ({
  ...state,
  designId: customerDesignId,
  designData: null,
  designType: DesignType.CUSTOMER_DESIGN,
  designStatus: DesignStatus.DESIGN_LOADING,
});

export default loadPublicDesign;
