import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import cn from 'classnames';
import { Store } from '@categoryProduct/store/typings';
import SelectWrapper from '@categoryProduct/component/Select';
import setInputControlValue from '@categoryProduct/store/controls/operation/setInputControlValue';
import registerInputControl from '@categoryProduct/store/controls/operation/registerInputControl';
import deactivateInputControl from '@categoryProduct/store/controls/operation/deactivateInputControl';
import getControlValue from '@categoryProduct/store/controls/selector/getControlValue';
import defaultStyles from './index.css';

interface OwnProps {
  options: any[];
  initialValue: any;
  className: string;
  onSelect?: Function;
  optionRender?: Function;
  section: string;
  name: string;
}

interface Props extends OwnProps {
  dispatch: Dispatch;
  currentValue: any;
}

export interface SimpleSelectorOption {
  id: string;
  label: string;
  value: string;
}

const SimpleDropdown = (props: Props) => {
  const {options, onSelect, initialValue, currentValue, className, dispatch, section, name } = props;

  useEffect(() => {
    dispatch(registerInputControl(name, section, false, true, initialValue));
    return () => {
      dispatch(deactivateInputControl(name, section));
    };
  }, []);

  const onSelectWrapper = (value: any) => {
    dispatch(setInputControlValue(name, section, value, true));
    if (onSelect) onSelect(value);
  };

  return (
    <div className={cn({
      selector: defaultStyles.simpleSelector,
      [className]: Boolean(className),
    })}
    >
      <SelectWrapper
        value={currentValue}
        onChange={onSelectWrapper}
        options={options}
      />
    </div>
  );
};

const mapStateToProps = (state: Store, {section, name}: OwnProps) => ({
  currentValue: getControlValue(state, section, name),
});
export default connect(mapStateToProps)(SimpleDropdown);
