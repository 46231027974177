export const IPAD_PRO_WIDTH = 1024;

export default () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const windowWidth = window.innerWidth || document && document.body.clientWidth;
  return IPAD_PRO_WIDTH >= windowWidth;
};
