import { handleActions } from 'redux-actions';
import { types } from './actions';
import get from 'idx';

const priceReducer = handleActions<any>(
  {
    [types.productsPricesSet]: (
      state,
      { payload: { categoryId, products, priceFormat } },
    ) => ({
      priceFormat,
      // We only save the current category to reduce memory footprint
      [categoryId]: {
        ...state[categoryId],
        ...products,
      },
    }),
    [types.productShippingPricesSet]: (
      state,
      { payload: { categoryId, productId, shipmentPrices } },
    ) => ({
      ...state,
      [categoryId]: {
        ...(get(state, _ => _[categoryId]) || {}),
        [productId]: {
          ...(get(state, _ => _[categoryId][productId]) || {}),
          shipmentPrices,
        },
      },
    }),
  },
  {},
);

export default priceReducer;
