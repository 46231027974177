import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { InitialControlsValues } from '../types';
import { Store } from '../../typings';
import getControlValue from '../selector/getControlValue';
import setInitialControlsValuesAction from '../action/setInitialControlsValues';

import updateInputControlValue from './updateInputControlValue';

const setInitialControlsValues = (
  initialValues: InitialControlsValues
) => (
  dispatch: ThunkDispatch<Store, void, AnyAction>,
  getState: () => Store
) => {
  const state = getState();
  if (!initialValues) {
    return;
  }

  dispatch(setInitialControlsValuesAction(initialValues));

  Object.keys(initialValues).forEach((section: string) => {
    Object.keys(initialValues[section]).forEach((controlName: string) => {
      const currentValue = getControlValue(state, section, controlName);
      const initialValueToSet = initialValues[section][controlName];
      if (currentValue !== undefined) {
        dispatch(updateInputControlValue(controlName, section, initialValueToSet, true));
        // probably recheck should be called here
      }
    });
  });
};

export default setInitialControlsValues;
