import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import EditorControlsContext from '@categoryProduct/util/context/EditorControlsContext';
import BabyIndexContext from '@categoryProduct/util/context/BabyIndexContext';
import updatePosterPlaceholder from '@categoryProduct/store/birthPosters/operation/updatePosterPlaceholder';
import Text from '@categoryProduct/component/common/Text';
import getBabyLength from '@categoryProduct/store/birthPosters/selector/getBabyLength';
import { Store } from '@categoryProduct/store/typings';
import getBabyOrientation from '@categoryProduct/store/design/selector/getBabyOrientation';
import { BABY_ORIENTATIONS } from '@categoryProduct/store/design/types';
import getBabyLengthUnits from '@categoryProduct/store/birthPosters/selector/getBabyLengthUnits';
import { inch2mm } from '@categoryProduct/util/inch2mm';
import { mm2cm } from '@categoryProduct/util/mm2cm';
import { mm2inch } from '@categoryProduct/util/mm2inch';
import { cm2mm } from '@categoryProduct/util/cm2mm';
import recheckInputControl from '@categoryProduct/store/controls/operation/recheckInputControl';
import setInputControlValue from '@categoryProduct/store/controls/operation/setInputControlValue';
import SimpleInput from '../../Controls/SimpleInput';
import SimpleLabel from '../../Controls/SimpleLabel';

import styles from './index.css';


interface OwnProps {
  section: string;
}

interface Props extends OwnProps {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
  babyOrientation: number | null;
  babyLength?: number;
  babyLengthUnits: any;
}

const controlName = 'babyLength';
const controlUnitsName = 'babyLengthUnits';
const BABY_IMAGE_ELEMENT_NAME = 'birthPosterBaby_';

const maxLengthMm = 60 * 10;

const BabyLengthInput = ({ section, dispatch, babyLength, babyOrientation, babyLengthUnits }: Props) => {
  const { t } = useTranslation();
  const initialValue = '40';
  const editorControls = useContext(EditorControlsContext);
  const babyIndex = useContext(BabyIndexContext);

  const unitList = [
    {
      label: 'txt_cm',
      value: 'm',
      id: 1,
    },
    {
      label: 'txt_inches',
      value: 'i',
      id: 2,
    },
  ];

  const [ currentUnitIndex, setUnitIndex ] = useState(0);
  const nextIndex = () => ((currentUnitIndex + 1) % unitList.length);
  const nextValue = unitList[nextIndex()].label;
  const nameTemplate = babyIndex ? BABY_IMAGE_ELEMENT_NAME + Number(babyIndex) : null;

  useEffect(() => {
    if (!babyLengthUnits) {
      return;
    }
    const foundUnitIndex = unitList.findIndex(unitValue => unitValue.value === babyLengthUnits.value);
    setUnitIndex(foundUnitIndex);
  }, [babyLengthUnits]);

  useEffect(() => {
    if (nameTemplate && babyLength && babyOrientation && editorControls) {
      const propertyToChange = babyOrientation === BABY_ORIENTATIONS.VERTICAL ? 'height' : 'width';
      const babyLengthMM = babyLengthUnits.value === 'm' ? cm2mm(babyLength) : inch2mm(babyLength);
      setTimeout(() => {
        editorControls.changeImageSize(nameTemplate, { [propertyToChange]: babyLengthMM }, babyLengthUnits.value);
      });
    }
  }, [ babyLength, babyOrientation, nameTemplate, babyLengthUnits, editorControls ]);

  const validator = (v: string) => Boolean(v && v.trim().length > 0);
  const onChange = () => {
    if (editorControls) {
      dispatch(updatePosterPlaceholder(editorControls, section, babyIndex));
    }
  };

  const onChangeUnits = () => {
    onChange();
  };

  const isMetric = () => (unitList[currentUnitIndex].value === 'm');

  const filterLengthInput = (v: string) => {
    if (v === '') {
      return v;
    }

    const result = v.match(/^\d+\.?\d{0,2}$/g);
    if (result === null) {
      return null;
    }

    let resultN = Number(result[0]);
    let isModified = false;
    const lengthInMm = isMetric() ? cm2mm(resultN) : inch2mm(resultN);

    if (lengthInMm > maxLengthMm) {
      isModified = true;
      resultN = isMetric() ? mm2cm(maxLengthMm) : Math.round(mm2inch(maxLengthMm * 100)) / 100;
    }

    return isModified ? String(resultN) : result[0];
  };

  const convertLength = (currentLength: number | undefined, isMetric: boolean) => {
    let convertedValue = 0;
    if (isMetric) {
      convertedValue = Math.round(mm2inch(cm2mm(Number(currentLength)) * 100)) / 100;
    } else {
      convertedValue = Math.round(mm2cm(inch2mm(Number(currentLength)) * 100)) / 100;
    }
    return convertedValue;
  };

  const changeUnits = () => {
    const newValue = convertLength(babyLength, isMetric());
    setUnitIndex(nextIndex());
    dispatch(setInputControlValue(controlName, section, String(newValue), true));
    dispatch(recheckInputControl(controlName, section));
  };

  return (
    <>
      <div>
        <div className={styles.title}>
          <Text darkBlue>{t('txt_length')}:</Text>
          <div
            className={styles.changeUnits}
            onClick={changeUnits}
          >
            {t('txt_change_unit')} - <span>{t(nextValue)}</span>
          </div>
        </div>
        <div className={styles.inputs}>
          <SimpleInput
            className={styles.length}
            initialValue={initialValue}
            type="text"
            filter={filterLengthInput}
            validator={validator}
            onChange={onChange}
            section={section}
            name={controlName}
          />
          <SimpleLabel
            options={unitList}
            index={currentUnitIndex}
            onChange={onChangeUnits}
            section={section}
            name={controlUnitsName}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: Store, props: OwnProps) => {
  return {
    babyOrientation: getBabyOrientation(state, props.section),
    babyLength: getBabyLength(state, props.section),
    babyLengthUnits: getBabyLengthUnits(state, props.section),
  };
};

export default connect(mapStateToProps)(BabyLengthInput);
