import classnames from 'classnames';
import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { get, isEmpty, throttle } from 'lodash';
import LocaleToCurrency from 'locale-currency';
import { Translation, withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { History, Location } from 'history';
import ReactPlaceholder from 'react-placeholder';

import { saveLastCategoryHistory } from '../../store/ui/operations';
import { categoryOperations, categorySelectors } from '../../store/category';
import { getParamFromLocation, setParamInLocation } from '../../util/browserLocation';
import ScrollManager from '../common/ScrollManager';
import FiltersContainer from './Filters';
import FiltersContainerMobile from './FiltersMobile';
import CardLoading from './Card/CardLoading';
import Card from './Card/Card';
import Select from '../Select';
import global from '../../util/global';

import iconStyles from '../commonStyles/icon.css';
import * as styles from './Category.css';

import apiTypings, { AppBundle } from '../../api/optimalprint-sdk';
import { priceOperations, priceSelectors } from '../../store/price';
import { uiSelectors } from '../../store/ui';
import { isEntityFetched } from '@categoryProduct/store/request/selectors';

import SubCategories from './Filters/SubCategories/SubCategories';
import memoize from 'memoize-one';
import i18next from 'i18next';
import apiRequest from '../../api/apiRequest';
import endpoints from '../../api/endpoints';
import Helmet from 'react-helmet';
import shouldUseNewPreviews from '../../util/shouldUseNewPreviews';
import { isPosterCategory, isWallCalendarCategory } from '../util/categoryIdsHelper';
import { setBasketItemsCount, setFavouritesCount } from '../../util/updateBadgesCount';
import { StoreType } from '@categoryProduct/store/typings';
import { DesignFamily } from '@categoryProduct/typings';

import 'react-placeholder/lib/reactPlaceholder.css?global';

export const sortByOptions: (SelectType & { urlValue: string })[] = [
  { value: 4, label: 'txt_sort_by_popular_and_new', urlValue: 'default' },
  { value: 1, label: 'txt_sort_by_most_popular', urlValue: 'popularity' },
  { value: 2, label: 'txt_sort_by_new', urlValue: 'new' },
];

const PAGE_LIMIT = 50;
const LOADING_STATE_NUMBER_OF_DESIGNS = 10;
const SCROLL_BUTTON_APPEAR_OFFSET = 100;

export const parseQueryString = (path: string): any =>
  path.slice(1).split('&').reduce((car, cur) => {
    const [ key, value ] = cur.split('=');

    return { ...car, [key]: value };
  }, {});

withTranslation()(({ t }) => {
  return (
    <>
      {t('txt_sort_by_popular_and_new')}
      {t('txt_sort_by_most_popular')}
      {t('txt_sort_by_new')}
    </>
  );
});

interface Props extends RouteComponentProps {
  categoryId: number;
  categories: { [s: string]: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo };
  category: AppBundle.Api.Entity.Category.V1.CategoryInfo;
  subCategories: AppBundle.Api.Entity.Category.V1.CategoryInfo[];
  parentCategories: AppBundle.Api.Entity.Category.V1.CategoryInfo[];
  req: any;
  allDesignFamilies: { [s: string]: DesignFamily } | DesignFamily[];
  designFamilies: DesignFamily[] | null;
  totalNumberOfDesigns: number;
  fetchDesignFamilies: typeof categoryOperations.fetchDesignFamiliesAndFilters;
  filters: Filters;
  getPrices: (productId: number) => apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[] | undefined;
  priceFormat: string;
  lastCategoryHistory?: History;
  selectedFilters: number[];
  t: i18next.TFunction;
  dispatch: Dispatch;
  state: any;
  gtmData: any;
  isAllDesignsAreFetched: boolean;
}

interface State {
  subCategoriesCollapsed: boolean;
  gtmData: { [designId: string]: object };
  hasScrolled: boolean;
  restoreScrollPositionWithDelay: boolean;
  isFetchingCategory: boolean;
}

class Category extends React.Component<Props, State> {
  static getInitialData = ({ dispatch, categoryId, req }: Props) => {
    return ([
      categoryOperations.fetchCategory(dispatch, undefined, categoryId, true)
        .then((parentCategoryId) => {
          let initialSortByValue = sortByOptions[0].value as number;
          const sortByFromQuery = sortByOptions.find(o => o.urlValue === get(req, 'query.sort_by', ''));

          if (sortByFromQuery) {
            initialSortByValue = sortByFromQuery.value as number;
          } else if (parentCategoryId && (isPosterCategory(parentCategoryId) || isWallCalendarCategory(parentCategoryId))) {
            // ts "!" to skip null check as it hardcoded object on top of the files
            initialSortByValue = sortByOptions.find(o => o.urlValue === 'popularity')!.value as number;
          }

          return categoryOperations.fetchDesignFamiliesAndFilters(
            dispatch,
            {},
            categoryId,
            initialSortByValue,
            get(req, 'query.filter', ''),
            get(req, 'query.page_nr', 1),
            PAGE_LIMIT,
            undefined,
          )
            .then(async ({ designFamilies }) => {
              const productTypeIds = Object.values(designFamilies)
                .reduce((acc, fam) => {
                  if (!fam || !fam.designs || !fam.designs.length) {
                    return acc;
                  }

                  return [ ...acc, ...fam.designs.map(d => d.productTypeId) ];
                }, [] as number[])
                .reduce((acc, id) => (acc.indexOf(id) > -1 ? acc : [ ...acc, id ]), [] as number[]);

              await Promise.all([
                categoryOperations.fetchPaperFormats(dispatch, undefined, productTypeIds),
              ]);

            });
        }),
      // VS: there is some issue with fetching it on server side
      // categoryOperations.favouritesFetch(dispatch, undefined)
    ]);
  };

  state = {
    subCategoriesCollapsed: true,
    gtmData: {},
    hasScrolled: false,
    restoreScrollPositionWithDelay: false,
    isFetchingCategory: false,
  };

  mobileSubCategoriesRef = React.createRef<HTMLDivElement>();

  areDesignsFetchedOnClient = false;

  componentDidMount() {
    const {
      location,
      lastCategoryHistory,
    } = this.props;

    if (lastCategoryHistory && lastCategoryHistory.location.pathname !== location.pathname) {
      this.resetFilter();
    }

    this.updateSavedCategoryLocation(location);

    this.fetchData();

    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps: Props) {
    const {
      location,
      categoryId,
      designFamilies,
      dispatch,
    } = this.props;

    this.updateSavedCategoryLocation(location);

    if (this.getSortByValueFromLocation(location) !== this.getSortByValueFromLocation(prevProps.location)) {
      this.fetchData();
    }

    const queries = queryString.parse(location.search);
    const currentPage = parseInt(
      queries['page_nr'] as string || '1',
      10,
    );

    const prevQueries = queryString.parse(prevProps.location.search);
    const prevPage = parseInt(
      prevQueries['page_nr'] as string || '1',
      10,
    );

    if (currentPage !== prevPage) {
      designFamilies && this.fetchGtmFormats(dispatch, {}, categoryId, designFamilies, currentPage)
        .then(() => {
          this.prepareImpressionData();
          this.sendProductViewEvent();
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  filterImpressionsAlreadySent = (impressions: any[]) => {
    const filteredImpressions = [];
    if (!(window as any).filterImpressionsAlreadySent) {
      (window as any).filterImpressionsAlreadySent = [];
    }

    for (let i = 0; i < impressions.length; i = i + 1) {
      if ((window as any).filterImpressionsAlreadySent.indexOf(impressions[i].id) === -1) {
        (window as any).filterImpressionsAlreadySent.push(impressions[i].id);
        filteredImpressions.push(impressions[i]);
      }
    }

    return filteredImpressions;
  };

  prepareImpressionData = async () => {
    if (typeof window === 'undefined') {
      return;
    }

    const { gtmData, history } = this.props;

    if (isEmpty(gtmData)) {
      return;
    }

    const windowY = window.scrollY + window.screen.availHeight;

    const cardsElm = document.getElementsByClassName(styles.cards)[0];
    if (!cardsElm) {
      return;
    }

    const cards = cardsElm.children;

    const allIdsOnPage = Array.prototype.slice.call(cards)
      .map(item => item.id.replace('design-', '').replace('-with-colors', ''));

    const visibleElements = Array.prototype.slice.call(cards)
      .filter(item => {
        const y = item.offsetTop;
        const height = item.offsetHeight;
        const getElementBottomY = y + height;
        return windowY > getElementBottomY;
      });

    const getShownDesignIds = visibleElements.map(item => item.id.replace('design-', '').replace('-with-colors', ''));

    const impressions = getShownDesignIds.map(item => gtmData[item] && (
      {
        ...gtmData[item],
        list: 'Category Page',
        position: (allIdsOnPage.indexOf(item) + 1) + ((Number(getParamFromLocation(history, 'page_nr') || 1) - 1) * PAGE_LIMIT),
      })).filter(i => !!i);

    const locale = global('locale');
    const currencyCode = locale && LocaleToCurrency.getCurrency(locale);

    const filteredImpressions = this.filterImpressionsAlreadySent(impressions);

    if (!filteredImpressions.length) {
      return;
    }

    const chunked = filteredImpressions.reduce((acc, obj, i) => {
      const chunkIndex = Math.floor(i / 10);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }

      acc[chunkIndex] = [ ...acc[chunkIndex], obj ];
      return acc;
    }, {});

    Object.values(chunked).forEach(objArray => {
      (window as any).dataLayer && (window as any).dataLayer.push({
        event: 'productImpression',
        ecommerce: {
          currencyCode,
          impressions: objArray,
        },
      });
    });
  };

  handleScroll = throttle(() => {
    const { hasScrolled } = this.state;
    this.prepareImpressionData();

    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > SCROLL_BUTTON_APPEAR_OFFSET && !hasScrolled) {
      this.setState({ hasScrolled: true });
    } else if (scrollTop <= SCROLL_BUTTON_APPEAR_OFFSET && hasScrolled) {
      this.setState({ hasScrolled: false });
    }
  }, 1000);

  sendProductViewEvent = () => {
    if (global('env') !== 'live') {
      return;
    }

    const { categoryId, designFamilies } = this.props;

    const designIds = designFamilies ? designFamilies.slice(0, 3).map(f => f.designs[0].designId) : [];

    if (designIds.length) {
      apiRequest(endpoints.gtmProducts, {
        categoryId,
        designIds: designIds.join(','),
      }, 'GET', 'payload')
        .then((answer: any) => {
          const designEnc = designIds.map(item => (global('locale') + item).replace(/[^A-Za-z0-9 ]/, ''));
          (window as any).dataLayer && (window as any).dataLayer.push({
            designEnc,
            event: 'designData',
            design: designIds,
            designEncDoubleClick: designEnc.join('|'),
          });
        });
    }

  };


  fetchGtmFormats = (
    dispatch: Dispatch,
    state: StoreType,
    categoryId: number,
    designFamilies: apiTypings.AppBundle.Api.Entity.Design.V1.IDesignFamily[],
    currentPage: number,
  ) => {
    const startPosition = (currentPage - 1) * PAGE_LIMIT;
    const designIds: string[] = [];
    designFamilies && designFamilies.slice(startPosition, (startPosition + PAGE_LIMIT)).map(item => {
      item.designs && designIds.push(item.designs[0].designId);
    });

    return categoryOperations.fetchGtmFormats(dispatch, { categoryId, designIds: designIds.join(',') });
  };

  productClick = (actualPosition: number) => {
    if (global('env') !== 'live') {
      return;
    }
    const { categoryId, history } = this.props;
    const position = actualPosition + ((Number(getParamFromLocation(history, 'page_nr') || 1) - 1) * PAGE_LIMIT);
    return (designId: string) => {
      apiRequest(endpoints.gtmProducts, {
        categoryId,
        designIds: designId,
      }, 'GET', 'payload')
        .then((answer: any) => {
          (window as any).dataLayer && (window as any).dataLayer.push({
            event: 'productClick',
            ecommerce: {
              actionField: {
                list: 'Category Page',
              },
              products: [
                {
                  ...answer[designId],
                  position,
                  list: 'Category Page',
                },
              ],
            },
          });
        });
    };
  };

  updateSavedCategoryLocation = memoize((location: Location) => {
    const {
      history,
      dispatch,
      lastCategoryHistory,

    } = this.props;
    if (!lastCategoryHistory
      || location.pathname !== lastCategoryHistory.location.pathname
      || location.search !== lastCategoryHistory.location.search) {

      saveLastCategoryHistory(dispatch, Object.assign({}, history));
    }
  });

  getSortByValueFromLocation = (location: Location) => {
    const queries = queryString.parse(location.search);

    const selectedSorting = sortByOptions.find(s => s.urlValue == queries['sort_by']) || this.getInitialSortByOptionByName(sortByOptions[0].urlValue);
    return selectedSorting.value as number;
  };

  getInitialSortByOptionByName = (name: string) => {
    const { category } = this.props;
    const categoryId = category.parentCategoryId || category.categoryId;
    const bestSellerByDefault = (isPosterCategory(categoryId) || isWallCalendarCategory(categoryId))
      ? sortByOptions.find(s => s.urlValue === 'popularity')
      : null;

    return bestSellerByDefault || sortByOptions.find(s => s.urlValue === name) || sortByOptions[0];
  };

  fetchData = async () => {
    const {
      dispatch,
      state,
      location,
      categoryId,
      getPrices,
      history,
      designFamilies,
    } = this.props;

    const queries = queryString.parse(location.search);
    const filters = queries['filter'] || '';
    const page = (queries['page_nr'] && parseInt(queries['page_nr'], 10)) || 1;

    const sortBy = this.getSortByValueFromLocation(location);

    this.setState({ isFetchingCategory: true });

    // noinspection JSIgnoredPromiseFromCall
    categoryOperations.fetchCategory(dispatch, state, categoryId, true);

    // case: Product refresh and back => only 1 design is available
    const areAllDesignsReady = designFamilies && designFamilies.length > 1;
    categoryOperations.fetchDesignFamiliesAndFilters(dispatch, state, categoryId, sortBy, filters, undefined, undefined, history)
      .then(({ designFamilies }) => {
        const productIds = Object.values(designFamilies)
          .reduce((acc, fam) => fam.designs ? [ ...acc, ...fam.designs ] : acc, [] as apiTypings.AppBundle.Api.Entity.Design.V1.IDesign[])
          .map(design => design.productId)
          .filter(id => !getPrices(id))
          .reduce((acc, id) => (acc.indexOf(id) > -1 ? acc : [ ...acc, id ]), [] as number[]);

        // noinspection JSIgnoredPromiseFromCall
        priceOperations.fetchPrices(dispatch, categoryId, productIds);

        this.fetchGtmFormats(dispatch, state, categoryId, designFamilies, page)
          .then(() => {
            this.prepareImpressionData();
            this.sendProductViewEvent();
          });

        // case: Product refresh and back => only 1 design is available
        let partialState = {};
        if (!areAllDesignsReady) {
          partialState = { restoreScrollPositionWithDelay: true };
        }

        this.setState({ isFetchingCategory: false, ...partialState });

        this.areDesignsFetchedOnClient = true;
      });

    // noinspection JSIgnoredPromiseFromCall
    categoryOperations.fetchPaperFormats(dispatch, state);

    categoryOperations.favouritesFetch(dispatch, state).then(favs => {
      setFavouritesCount(favs.length);
    });

    categoryOperations.cartItemsCountFetch(dispatch, state).then(count => {
      typeof count === 'number' && setBasketItemsCount(count);
    });

    this.scrollToTop();
  };

  scrollToTop = () => window.scroll(0, 0);

  resetFilter = () => {
    const { filters, dispatch, allDesignFamilies, history } = this.props;
    const familiesKeys: string[] = Object.keys(allDesignFamilies);
    const reMappedAllDesigns: DesignFamily[] = familiesKeys.map((key: string) => allDesignFamilies[key]);
    setParamInLocation(history, 'page_nr', '1', true);
    setParamInLocation(history, 'filter', '', true);
    categoryOperations.resetFilter(dispatch, filters, reMappedAllDesigns);
  };

  clickToFilter = (tag: number) => {
    const { filters, selectedFilters, dispatch, allDesignFamilies, history } = this.props;
    const familiesKeys: string[] = Object.keys(allDesignFamilies);
    const reMappedAllDesigns: DesignFamily[] = familiesKeys.map((key: string) => allDesignFamilies[key]);
    this.scrollToTop();
    categoryOperations.setFilter(history, dispatch, tag, filters, selectedFilters, reMappedAllDesigns);
  };

  renderScrollToTopButton = () => (
    <i
      className={classnames({
        [iconStyles.icon]: true,
        [styles.goTopIcon]: true,
        [styles.goTopIconVisible]: this.state.hasScrolled,
      })}
      onClick={this.scrollToTop}
    />
  );

  renderCategoryContent = (
    families: DesignFamily[],
    useNewPreviews: boolean,
    noDesignsMessage: string,
  ) => {
    const isEmptyCategory = Boolean(!families.filter(e => Boolean(e)).length) && !this.state.isFetchingCategory;

    if (isEmptyCategory && this.areDesignsFetchedOnClient) {
      return <>{noDesignsMessage}</>;
    }

    return families.map((family, index) => {
      return family
        ? (
          <Card
            onClick={this.productClick((index + 1))}
            family={family}
            key={family.designs[0].designId}
            category={this.props.category}
            useNewPreviews={useNewPreviews}
          />
        )
        : (
          <CardLoading key={index} useNewPreviews={useNewPreviews} />
        );
    });
  };

  render() {
    const {
      designFamilies = [],
      category,
      subCategories,
      parentCategories,
      location: {
        search,
        pathname,
      },
      categories,
      selectedFilters,
      totalNumberOfDesigns: totalDesignsInCategory,
      dispatch,
      history,
      isAllDesignsAreFetched,
    } = this.props;

    // if filters activated, update number of designs accordingly
    const totalNumberOfDesigns = selectedFilters.length && designFamilies ? designFamilies.length : totalDesignsInCategory;

    const queries = queryString.parse(search);

    const selectedSorting = sortByOptions.find(s => s.urlValue === queries['sort_by']) || this.getInitialSortByOptionByName(sortByOptions[0].urlValue);

    const currentPage = parseInt(queries['page_nr'], 10) || 1;

    const lastPage = Math.ceil(totalNumberOfDesigns / PAGE_LIMIT);

    const nextLink = {
      pathname,
      search: `?page_nr=${currentPage + 1}`,
    };

    const prevLink = {
      pathname,
      search: `?page_nr=${currentPage - 1}`,
    };

    const filters = typeof window !== 'undefined' ? getParamFromLocation(history, 'filter') : parseQueryString(search).filter;

    if (filters) {
      prevLink.search += `&filter=${filters}`;
      nextLink.search += `&filter=${filters}`;
    }

    const sortBy = typeof window !== 'undefined' ? getParamFromLocation(history, 'sort_by') : parseQueryString(search)['sort_by'];

    if (sortBy) {
      prevLink.search += `&sort_by=${sortBy}`;
      nextLink.search += `&sort_by=${sortBy}`;
    }

    const familiesKeys: number[] = (Array(PAGE_LIMIT).fill(0) as number[])
      .map((_: any, i: number) => i + (currentPage - 1) * PAGE_LIMIT)
      .filter((p: number) => p <= Math.min(totalNumberOfDesigns || LOADING_STATE_NUMBER_OF_DESIGNS) - 1);

    const designFamiliesLength = totalNumberOfDesigns ? totalNumberOfDesigns : designFamilies ? designFamilies.length : 0;
    const useNewPreviews = shouldUseNewPreviews(categories, category.categoryId);

    const renderPagination = () => designFamiliesLength > PAGE_LIMIT && (
      <Translation>
        {t =>
          <div className={styles.pagination}>
            <div className={styles.paginationButtonPlaceholder}>
              {currentPage > 1 && currentPage <= lastPage && (
                <Link id='paginationPrevLink' to={prevLink} onClick={this.scrollToTop} rel='prev'>
                  <i className={classnames(iconStyles.icon, styles.goBackIcon)} />
                </Link>

              )}</div>

            <div className={styles.paginationText}>
              {t('txt_category_mobile_pages')} {currentPage} {t('txt_category_mobile_of')} {lastPage}
            </div>

            <div className={styles.paginationButtonPlaceholder}>
              {currentPage < lastPage && (
                <Link id='paginationNextLink' to={nextLink} onClick={this.scrollToTop} rel='next'>
                  <i className={classnames(iconStyles.icon, styles.goBackIcon, styles.reverse180)} />
                </Link>
              )}
            </div>
          </div>
        }
      </Translation>
    );

    const renderSubCategoriesMobile = () => {
      const hasParentCategory = category.parentCategoryId && category.categoryId !== 12;
      const isCurrentCategory = (c: AppBundle.Api.Entity.Category.V1.CategoryInfo) => category.categoryId === c.categoryId;

      const subcategoriesLinks = subCategories.map(c => (
        <Link
          id='mobileSubcategoryLink'
          key={c.categoryId}
          to={c.url}
          className={classnames(styles.subCategoryLinkMobile, {
            [styles.subCategoriesMobileSelected]: isCurrentCategory(c),
            [styles.subCategoriesMobileDisabled]: hasParentCategory && !isCurrentCategory(c),
          })}
        >
          {c.translatedName}
        </Link>
      ));

      const selectedCategoryOffset = () => {
        if (typeof document === 'undefined') {
          return;
        }

        const selectedCategoryDom = document.getElementsByClassName('subCategoriesMobileSelected___1H82i')[0];
        if (!selectedCategoryDom) {
          return;
        }

        const selectedCategoryBounds = selectedCategoryDom.getBoundingClientRect();

        return {
          x: selectedCategoryBounds.left - 50,
          y: 0,
        };
      };

      return (
        <React.Fragment>
          {typeof window !== 'undefined' && (
            <ScrollManager
              defaultPosition={selectedCategoryOffset()}
              scrollKey={'mobileNav'}
              targetRef={this.mobileSubCategoriesRef}
            />
          )}

          <div
            ref={this.mobileSubCategoriesRef}
            className={classnames(styles.subCategoriesMobile)}
          >
            {subcategoriesLinks}
            <div style={{ paddingRight: '11px' }} />
          </div>
        </React.Fragment>
      );
    };

    const renderBreadcrumbs = () => {
      const links = [ category, ...parentCategories ].map(c => ({ title: c.translatedName, to: c.url })).reverse();

      return (
        <Translation>
          {t => (
            <div className={styles.breadCrumbsWrapper}>
              <a id='mobileHome-Link' href={global('urlRoot')}>{t('txt_top_menu_home')}</a>
              {links.map((l, i) => (
                <React.Fragment key={i}>
                  <span className={classnames(iconStyles.icon, styles.iconArrowBreadcrumbs, 'op-icon-arrow-left-1')} />
                  <Link id='categoryName_Link' to={l.to}>{l.title}</Link>
                </React.Fragment>
              ))}
            </div>
          )}
        </Translation>
      );
    };

    const families = familiesKeys.map((key: number) => designFamilies && designFamilies[key]) as DesignFamily[];

    const onSortByChange = (sortingOption: SelectType) => {
      const q = Object.assign({}, queries, { sort_by: sortingOption.urlValue, page_nr: 1 });
      if (sortingOption.value === selectedSorting.value) {
        return;
      }

      categoryOperations.clearFamilies(dispatch, category.categoryId);
      history.replace(`${pathname}?${queryString.stringify(q)}`);
    };

    const renderMobileCategoryTitle = () => {
      const title = category.h1;

      const parent = category.parentCategoryId && parentCategories.find(c => c.categoryId === category.parentCategoryId);
      const subtitle = parent ? parent.h1 : undefined;

      return (
        <div className={styles.mobileBackPanel}>
          {parent && <Link id='mobileParent_link' to={parent.url}>
            <i className={classnames(iconStyles.icon, styles.goBackIcon)} />
          </Link>}
          <div className={styles.mobileBackPanelTextWrapper}>
            {subtitle && <div className={styles.mobileHeaderText}>{subtitle}</div>}
            <div className={styles.mobileSubHeaderText}>{title}</div>
          </div>
        </div>
      );
    };

    return (
      <Translation>
        {t => (
          <div className={styles.wrapper}>
            <Helmet title={`${category.h1} | Optimalprint`} />
            {typeof window !== 'undefined' && (
              <ScrollManager
                delayedRestore={this.state.restoreScrollPositionWithDelay}
                scrollKey={location.pathname + location.search}
              />
            )}
            <div className={styles.container}>
              <div className={styles.header}>
                <h1 className={styles.headerText}>{category.h1}</h1>

                <div className={styles.subHeader}>
                  {renderBreadcrumbs()}
                  <div className={classnames(styles.counterAndSort)}>
                    <div className={styles.sortWrapper}>
                      {Boolean(totalNumberOfDesigns) && (
                        <ReactPlaceholder
                          showLoadingAnimation
                          type='textRow'
                          ready={isAllDesignsAreFetched}
                          style={{ width: '100px', backgroundColor: '#e8e8e8', margin: 'auto 10px' }}
                        >
                          <div>{totalNumberOfDesigns} {t('txt_landing_designs')}</div>
                        </ReactPlaceholder>
                      )}
                      <div>{t('txt_label_sort')}:</div>
                      <div className={styles.sortSelect}>
                        <Select
                          value={selectedSorting}
                          onChange={onSortByChange as any}
                          options={sortByOptions.map(item => ({ ...item, label: t(`${item.label}`) as string }))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.sidebar}>
                <SubCategories
                  categoryId={category.categoryId}
                  parentCategoryId={category.parentCategoryId}
                />
                <FiltersContainer
                  clickToFilter={this.clickToFilter}
                  resetFilter={this.resetFilter}
                  categoryId={category.categoryId}
                />
              </div>

              {renderMobileCategoryTitle()}
              {renderSubCategoriesMobile()}

              <FiltersContainerMobile
                history={history}
                sortByOptions={sortByOptions}
                selectedSorting={selectedSorting}
                onSortByChange={onSortByChange}
                clickToFilter={this.clickToFilter}
                countOfDesigns={totalNumberOfDesigns}
                resetFilter={this.resetFilter}
                categoryId={category.categoryId}
                parentCategoryId={category.parentCategoryId}
                isDisabled={!isAllDesignsAreFetched}
              />

              <div className={styles.content}>
                <div
                  className={classnames(styles.cards, {
                    [styles.oldLayout]: !useNewPreviews,
                  })}
                >
                  {this.renderCategoryContent(families, useNewPreviews, t('txt_no_templates_of_this_category'))}
                </div>
                <div className={styles.paginationWrapper}>
                  {renderPagination()}
                </div>
                {this.renderScrollToTopButton()}
              </div>
            </div>
          </div>
        )
        }
      </Translation>
    );
  }
}

const mapStateToProps = (state: any, props: Props) => {
  const currentCategory = categorySelectors.getCategory(state, props.categoryId);

  return {
    state,
    categories: categorySelectors.getCategories(state),
    category: currentCategory,
    parentCategories: categorySelectors.getBreadcrumbsCategories(state, currentCategory.categoryId),
    subCategories: categorySelectors.getSubCategories(state, currentCategory.categoryId, currentCategory.parentCategoryId),
    designFamilies: categorySelectors.getVisibleFamilies(
      state,
      props.categoryId,
    ),
    totalNumberOfDesigns: categorySelectors.getDesignFamiliesCount(
      state,
      props.categoryId,
    ),
    allDesignFamilies: categorySelectors.getDesignFamilies(
      state,
      props.categoryId,
    ),
    priceFormat: priceSelectors.getPriceFormat(state),
    getPrices: (productId: number) => priceSelectors.getPrices(state, props.categoryId, productId),
    filters: state.category.filters,
    selectedFilters: categorySelectors.getSelectedFiltersFromState(state),
    lastCategoryHistory: uiSelectors.getLastCategoryHistory(state),
    gtmData: state.category.gtmFormats,
    isAllDesignsAreFetched: isEntityFetched(state, `category/${props.categoryId}`),
  };
};

const ConnectedCategory = connect(
  mapStateToProps,
)(Category);
ConnectedCategory.displayName = 'Category';

export default ConnectedCategory;
