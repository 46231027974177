export type RecordType = string | {
  x: number,
  y: number,
};

export const KEY = 'OP_MEMORY_STORAGE_DATA';
export const BACK_URL_KEY = 'BACK_URL';

export const reviveMapFromSessionStorage = () => {
  if (typeof window !== 'undefined' && sessionStorage) {
    const data = sessionStorage.getItem(KEY);
    sessionStorage.removeItem(KEY);
    return data ? new Map(JSON.parse(data)) : new Map();
  }

  return new Map();
};

export const saveMapToSessionStorage = (data: Map<string, RecordType>) => {
  if (typeof window !== 'undefined' && sessionStorage) {
    sessionStorage.setItem(KEY, JSON.stringify(Array.from(data.entries())));
  }
};

export const memoryStore = {
  data: reviveMapFromSessionStorage(),
  get(key: string) {
    if (!key) {
      return null;
    }

    return this.data.get(key) || null;
  },
  set(key: string, value: RecordType) {
    if (!key) {
      return;
    }
    this.data.set(key, value);
  },
};

export const getBackUrlFromScrollManagerData = (): string => {
  if (typeof window !== 'undefined' && memoryStore) {
    return memoryStore.get(BACK_URL_KEY) || '';
  }

  return '';
};

// for debugging
if (typeof window !== 'undefined') {
  (window as any).opMemoryStore = memoryStore;
}
