import { AnyAction } from 'redux';
import { ControlsState } from '../types';

const deactivateSectionControls = (state: ControlsState, { sectionId }: AnyAction) => {
  if (!state.sections[sectionId]) {
    return state;
  }

  const deactivatedSection = {...state.sections[sectionId]};
  Object.keys(deactivatedSection).forEach((control: string) => {
    deactivatedSection[control].active = false;
  });

  return {
    ...state,
    sections: {
      ...state.sections,
      [sectionId]: deactivatedSection,
    },
  };
};

export default deactivateSectionControls;
