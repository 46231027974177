import classnames from 'classnames';
import * as React from 'react';

import Carousel from '../../../CarouselNew';

import * as styles from './Formats.css';
import apiTypings from '../../../../api/optimalprint-sdk';
import getImagesRootUrl from '../../../../util/getImagesRootUrl';
import { DesignFamily } from '@categoryProduct/typings';

interface Props {
  categoryId: number;
  family: DesignFamily;
  paperFormats: apiTypings.AppBundle.Api.Entity.PaperFormat.V1.PaperFormat[];
  selectedDesignId: string;
  onMouseEnter: (formatKey: number) => void;
}

const Formats: React.FC<Props> = ({ family, paperFormats, selectedDesignId, onMouseEnter, categoryId }) => {
  const selectedDesign = family.designs.find(d => d.designId === selectedDesignId);
  if (!selectedDesign) {
    return null;
  }

  const {
    productTypeId,
    paperFormatId: selectedPaperFormatId,
  } = selectedDesign;

  const formats = family && family.designs ? family.designs.filter(design => design.categoryIds && design.categoryIds.indexOf(categoryId) >= 0).reduce(
    (acc, design) => {
      const old = acc[design.paperFormatId] || {
        colors: [],
      };

      // prevent double colors
      if (old.colors.indexOf(design.color) >= 0) {
        return acc;
      }

      acc[design.paperFormatId] = {
        ...old,
        colors: [...old.colors, design.color],
      };
      return acc;
    },
    {} as {
      [formatId: string]: {
        colors: string[];
      };
    },
  ) : {};

  if (!formats || Object.keys(formats).length <= 1) {
    return null;
  }

  const renderedFormats = Object.keys(formats).map(formatKey => {
    let formatData = paperFormats.find(f => f.productTypeId === productTypeId && f.paperFormatId === parseInt(formatKey, 10));
    if (!formatData) {
      formatData = paperFormats.find(f => f.paperFormatId === parseInt(formatKey, 10)) || {} as apiTypings.AppBundle.Api.Entity.PaperFormat.V1.PaperFormat;
    }

    const baseUrl = getImagesRootUrl();

    return (
      <div
        className={classnames(styles.format, 'glider-slide', {
          [styles.active]: parseInt(formatKey, 10) === selectedPaperFormatId,
        })}
        key={formatKey}
        onMouseEnter={() => onMouseEnter(parseInt(formatKey, 10))}
        title={formatData['name']}
        style={{
          backgroundImage: `url("${baseUrl + formatData['icon']}")`,
        }}
      />
    );
  });

  const selectedFormatIndex = Object.keys(formats).indexOf(selectedPaperFormatId.toString());

  return (
    <div className={styles.formats}>
      <Carousel selectedIndex={selectedFormatIndex}>
        {renderedFormats}
      </Carousel>
    </div>
  );

};

export default Formats;
