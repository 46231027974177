import React from 'react';
import classNames from 'classnames/bind';
import Text from '@categoryProduct/component/common/Text';
import getImagesRootUrl from '@categoryProduct/util/getImagesRootUrl';
import Tooltip from '@categoryProduct/component/common/Tooltip';
import FilterTooltipContent from '@categoryProduct/component/Category/FilterTooltipContent';

import styles from './FilterItem.css';

export interface Props {
  onClick: () => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  isOnlyEnabled?: boolean;
  title?: string;
  color?: string;
  icon?: string;
  text?: string;
  name?: string;
  value?: string;
  accessibilityLabel?: string;
}

const cn = classNames.bind(styles);

interface WithTooltipProps {
  id: number;
  condition: boolean;
  children: React.ReactElement;
}

const WithTooltip = (props: WithTooltipProps) => {
  const { condition, children, id } = props;

  // filter none foil (value is 1)
  return condition && id > 1
    ? (
      <Tooltip content={<FilterTooltipContent id={id} />}>
        {children}
      </Tooltip>
    )
    : children;
};

const renderColor = (color: string, isDisabled: boolean = false, isSelected: boolean = false) => {
  const isGradient = (color.length > 7);
  let addStyles = null;

  if (isGradient) {
    const gradient = color.split(',');
    const linGrad = `${gradient[0]}, ${gradient[1]} ${gradient[2]}, ${gradient[3]} ${gradient[4]}`;
    addStyles = { background: `linear-gradient(${linGrad})` };
  } else {
    addStyles = { backgroundColor: color };
  }

  return (
    <span className={cn('iconWrapper', { disabledRound: isDisabled, selectedRound: isSelected })}>
      <span className={cn('round')} style={addStyles} />
    </span>
  );
};

const FilterItem: React.FC<Props> = (props: Props) => {
  const {
    title,
    text,
    onClick,
    icon,
    color,
    isDisabled,
    isSelected,
    isOnlyEnabled,
    value,
    name,
  } = props;
  const onClickHandler = () => {
    if (isDisabled && !isSelected) return;
    onClick();
  };

  const renderControl = () => {
    const isFoilElement = Boolean(name && name.indexOf('foil') >= 0);
    const filterValue = value ? Number(value) : -1;

    // correct case: icon is present, but not defined (no foil item)
    if ((icon || icon === '') && color) {
      const baseUrl = getImagesRootUrl();
      return (
        <WithTooltip condition={isFoilElement} id={filterValue}>
          <div className={cn('colorTitled')}>
            {!icon && color && renderColor(color, isDisabled, isSelected)}
            {icon && (
              <div className={cn('iconWrapper', { disabledRound: isDisabled, selectedRound: isSelected })}>
                <img className={cn('round')} src={baseUrl + icon} alt='' />
              </div>
            )}
            {title && <Text className={cn('title', 'smallTitle', { disabledText: isDisabled })}>{title}</Text>}
          </div>
        </WithTooltip>
      );
    }

    return (
      <React.Fragment>
        {color && renderColor(color, isDisabled, isSelected)}
        {icon && <input
          type='checkbox'
          name={title}
          checked={isSelected}
          disabled={isDisabled}
          onChange={onClickHandler}
        />}
      </React.Fragment>
    );
  };

  return (
    <div
      id={`filterItem_div_${color || text || title}`}
      onClick={onClickHandler}
      className={cn(styles.wrapper, { [styles.only]: isOnlyEnabled })}
    >
      {renderControl()}
      {text && <Text>{text}</Text>}
      {!color && title && <Text className={cn('title', { disabledText: isDisabled })}>{title}</Text>}
    </div>
  );
};

export default FilterItem;
