/* OPERATIONS = REDUX THUNKS
This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator, ex: simpleQuack
Complex operations involve returning a thunk that dispatches multiple actions in a certain order, ex: complexQuack
*/
import { Dispatch } from 'redux';
import apiRequest from '@categoryProduct/api/apiRequest';
import endpoints from '@categoryProduct/api/endpoints';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import * as actions from './actions';
import { getShipmentPrices } from './selectors';

export const fetchPrices = async (
  dispatch: Dispatch,
  categoryId: number,
  productIds: number[],
  encPublicDesignIds?: string[],
  useDesignUpsell?: boolean,
) => {
  if (!productIds.length) {
    return;
  }

  const params = {
    productIds,
    categoryId,
  };

  const prices = apiRequest(
    endpoints.productPriceList,
    params,
    'POST',
    'data'
  );

  let upsellEndPoint = endpoints.productUpsellList;
  let upsellParams = params as any;

  if (useDesignUpsell && encPublicDesignIds) {
    upsellParams = {
      encPublicDesignIds,
      categoryId,
    };
    upsellEndPoint = endpoints.designUpsellListWithPreview;
  }

  // designUpsellListWithPreview
  const upsells = apiRequest(
    upsellEndPoint,
    upsellParams,
    'POST',
    'data.products'
  );

  const result = await Promise.all([ prices, upsells ]);

  const { products: pricesList, priceFormat } = result[0] as apiTypings.AppBundle.Api.Response.Product.V1.ProductPriceListV1Response;

  const products = {};

  (pricesList as apiTypings.AppBundle.Api.Entity.Product.V1.ProductPriceListProduct[])
    .forEach((product) => {
      products[product.productId] = {
        prices: product.prices,
      };
    });

  (result[1] as apiTypings.AppBundle.Api.Entity.Product.V1.ProductUpsellItems[])
    .forEach((product) => {
      products[product.productId] = {
        ...products[product.productId],
        upsells: product,
      };
    });

  dispatch(
    actions.productsPricesSet({
      categoryId,
      products,
      priceFormat,
    })
  );
};

export const fetchShipmentPrices = async (
  dispatch: Dispatch,
  state: any,
  categoryId: number,
  productId: number,
  designId: string
) => {
  // fetch only products that are not yet in store.
  const productFetched = getShipmentPrices(state, categoryId, designId);

  if (productFetched) {
    return;
  }

  // TODO: add countryId of the customer
  const params = {
    categoryId,
    productIds: [ productId ],
  };

  try {
    const shipmentPrices = await apiRequest(
      endpoints.categoryShipmentPriceList,
      params,
      'POST',
      'data.products'
    );

    dispatch(
      actions.productShippingPricesSet({
        categoryId,
        shipmentPrices,
        productId,
      })
    );
  } catch (err) {
    console.error(err);
  }
};
