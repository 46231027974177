import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const options: i18n.InitOptions = {
  fallbackLng: 'en',
  load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  saveMissing: true,
  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
    prefix: '{',
    suffix: '}',
    formatSeparator: ',',
    format: (value: any, format: any) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },
};

// for browser use xhr backend to load translations and browser lng detector
// noinspection JSIgnoredPromiseFromCall
i18n.use(initReactI18next).init(options);

export default i18n;
