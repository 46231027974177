/* ACTION CREATOR FUNCTIONS
Put here the functions that return an action object that can be dispatched
HINT: Always use functions for consistency, don't export plain objects
*/

import { createAction } from 'redux-actions';

export const types = {
    productsPricesSet: 'products/prices/SET',
    productShippingPricesSet: 'product/shippingPrices/SET',
};

export const productsPricesSet = createAction(types.productsPricesSet);
export const productShippingPricesSet = createAction(types.productShippingPricesSet);
