import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ProductBirthPoster from '@categoryProduct/view/ProductBirthPoster';
import Product from '@categoryProduct/component/Product';
import isBirthPoster from '@categoryProduct/util/birthPoster/isBirthPoster';

interface RouteParams {
  // eslint-disable-next-line camelcase
  category_id: string;
  // eslint-disable-next-line camelcase
  design_id: string;
}

interface Props extends RouteComponentProps<RouteParams> {

}

const ProductPageProxy = (props: Props) => {
  const { match: { params: { category_id: categoryId } } } = props;
  const Component = isBirthPoster(Number(categoryId)) ? ProductBirthPoster : Product;

  (ProductPageProxy as any).getInitialData = Component.WrappedComponent.getInitialData;

  return <Component {...props} />;
};

// important: we need this to load data on server side
// we don't need it for BP, but still will call it on server side to make work
ProductPageProxy.getInitialData = Product.getInitialData;

export default ProductPageProxy;
