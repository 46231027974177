import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router-dom';
import { Store } from '@categoryProduct/store/typings';
import { Editor, EditorControls, JsonDesign, JsonDesignPage } from 'browser-editor';
import EditorControlsContext from '@categoryProduct/util/context/EditorControlsContext';

import EditorArea from '@categoryProduct/view/ProductBirthPoster/EditorArea';
import loadPublicDesign from '@categoryProduct/store/design/operation/loadPublicDesign';
import loadCustomerDesign from '@categoryProduct/store/design/operation/loadCustomerDesign';
import getDesignData from '@categoryProduct/store/design/selector/getDesignData';
import activateSection from '@categoryProduct/store/controls/operation/activateSection';
import { categoryOperations } from '@categoryProduct/store/category';

import PosterDesignSettings from './PosterDesignSettings';
import BabyDataInput from './BabyDataInput';
import ProductWelcome from './ProductWelcome';
import ProductPrice from './ProductPrice';

import getInitialData from './getInitialData';
import fetchIllustrations from '@categoryProduct/store/birthPosters/operation/fetchIllustrations';
import { DesignFamily } from '@categoryProduct/typings';
import { priceOperations } from '@categoryProduct/store/price';
import updatePosterPlaceholder from '@categoryProduct/store/birthPosters/operation/updatePosterPlaceholder';
import numberOfBabiesFromCategory from '@categoryProduct/util/birthPoster/numberOfBabiesFromCategory';

import styles from './index.css';
import getEditedProductInitialValues from '@categoryProduct/util/birthPoster/getEditedProductInitialValues';
import useQuery from '@categoryProduct/util/useQuery';
import setInitialControlsValues from '@categoryProduct/store/controls/operation/setInitialControlsValues';
import { InitialControlsValues } from '@categoryProduct/store/controls/types';

interface RouteParams {
  // eslint-disable-next-line camelcase
  category_id: string;
  // eslint-disable-next-line camelcase
  design_id: string;
}

interface OwnParams {
}

interface Props extends OwnParams, RouteComponentProps<RouteParams> {
  designData: JsonDesign | null;
  dispatch: ThunkDispatch<Store, undefined, AnyAction>;
}

const ProductBirthPoster = (props: Props) => {
  const query = useQuery();
  const [ pageSize, setPageSize ] = useState({ width: 0, height: 0 });
  const [ controls, setControls ] = useState<EditorControls | null>(null);
  const { match: { params: { category_id: categoryId, design_id: designId } }, designData, dispatch } = props;
  const numberOfBabies = numberOfBabiesFromCategory(categoryId);

  // Load design, we need page size before we can start Editor
  useEffect(() => {
    if (query.has('customerDesignId')) {
      dispatch(loadCustomerDesign(query.get('customerDesignId')));
    } else {
      dispatch(loadPublicDesign(designId));
    }
    dispatch(fetchIllustrations());
    document.body.classList.add('op-react--gray-bg');
    return () => document.body.classList.remove('op-react--gray-bg');
  }, []);

  const onEditorCreate = async (editorControls: EditorControls) => {
    // debugging/development
    (window as any).__CT = editorControls;
    setControls(editorControls);
  };

  useEffect(() => {
    categoryOperations.fetchDesignFamily(dispatch, undefined, Number(categoryId), designId)
      .then(async (family: DesignFamily) => {
        // fetch pricing
        const productIds = family.designs.map(design => design.productId)
          .reduce((acc, id) => (acc.indexOf(id) > -1 ? acc : [ ...acc, id ]), [] as number[]);

        await Promise.all([
          priceOperations.fetchPrices(dispatch, Number(categoryId), productIds),
        ]);
      });
  }, [ categoryId, designId ]);

  useEffect(() => {
    if (!designData) {
      return;
    }

    const design = { ...designData };
    const pageSizes = design.structure.map((page: JsonDesignPage) => ({
      width: parseInt(page.width, 10),
      height: parseInt(page.height, 10),
    }));
    setPageSize(pageSizes[0]);

    if (!controls) {
      return;
    }

    controls.loadDesign(design);

    const initialValues = getEditedProductInitialValues(designData);
    dispatch(setInitialControlsValues(initialValues as any as InitialControlsValues));

    Array(numberOfBabies).fill('').forEach((v: any, babyIndex) => {
      dispatch(updatePosterPlaceholder(controls, `step${babyIndex + 2}`, babyIndex + 1, true));
    });
  }, [designData, controls]);

  useEffect(() => {
    dispatch(activateSection('step1'));
  }, []);

  return (
    <>
      <div className={styles.productPage}>
        <EditorArea pageSize={pageSize}>
          <Editor onCreate={onEditorCreate} />
        </EditorArea>
        <div className={styles.settingsArea}>
          <EditorControlsContext.Provider value={controls}>
            <ProductWelcome>
              <ProductPrice />
            </ProductWelcome>
            <PosterDesignSettings />
            {Array(numberOfBabies).fill(undefined).map((_, index) => (
              <BabyDataInput
                key={`babyInput${index * 2}`}
                titleKey={numberOfBabies === 1 ? 'txt_step_baby_single' : ''}
                babyIndex={index + 1}
                numberOfBabies={numberOfBabies}
                isLastStep={index + 1 === numberOfBabies}
              />
            ))}
          </EditorControlsContext.Provider>
        </div>
      </div>
      {/*<ProductData />*/}
    </>
  );
};

ProductBirthPoster.getInitialData = getInitialData;

const mapStateToProps = (state: Store) => ({
  designData: getDesignData(state),
});

export default connect(mapStateToProps)(ProductBirthPoster);
