import cn from 'classnames';
import React from 'react';
import defaultStyles from './index.css';

interface Props {
  children: any;
  option: { id: string };
  active: boolean;
  onSelect: Function;
  styles: any;
}

const ListOption = (props: Props) => {
  const { children, option, active, onSelect, styles } = props;

  return (
    <div
      id={option.id}
      className={cn(
        defaultStyles.simpleListOption, styles.simpleListOption || '',
        {
          [defaultStyles.active]: active,
          [styles.active || '']: active,
        },
      )}
      key={option.id}
      onClick={() => {
        onSelect(option);
      }}
    >
      {children}
    </div>
  );
};

export default ListOption;
