import { AnyAction } from 'redux';
import { DesignState, DesignStatus } from '../types';

const setDesignData = (state: DesignState, { designData }: AnyAction) => ({
  ...state,
  designData,
  designStatus: DesignStatus.DESIGN_READY,
});

export default setDesignData;
