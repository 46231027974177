import * as React from 'react';
import classNames from 'classnames/bind';
import { Translation } from 'react-i18next';

const styles = require('./Button.css');

interface ButtonProps {
  id?: string | undefined;
  onClick?: React.MouseEventHandler;
  deleteAllButton?: boolean;
  backBtn?: boolean;
  fullWidth?: boolean;
  transparent?: boolean;
  roundCorner?: boolean;
  blueBorder?: boolean;
  className?: string;
  classNameWrapper?: string;
  state?: ButtonState;
  disabled?: boolean;
  errorMessage?: JSX.Element | Element | string;
  children: JSX.Element | Element | string;
}

export enum ButtonState {
  undefined,
  loading,
  success,
  failed,
}

const cn = classNames.bind(styles);

const CheckIcon = () => (
  <span className={styles.iconSpan}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 7'>
      <path d='m.65909042 4.04356308 2.43156433 2.43156433 5.83150483-5.83150482' fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.25' />
    </svg>
  </span>
);

const LoadingIcon = () => (
  <span className={styles.iconSpan}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
      <circle cx='8' cy='8' r='7' />
    </svg>
  </span>
);

const Button = (props: ButtonProps) => {
  const {
    id,
    children,
    onClick,
    deleteAllButton,
    backBtn,
    fullWidth,
    transparent,
    roundCorner,
    blueBorder,
    className = '',
    classNameWrapper = '',
    state = ButtonState.undefined,
    errorMessage = '',
    disabled,
  } = props;
  return (
    <div className={cn(styles.btnWrapper, { [classNameWrapper]: Boolean(classNameWrapper) })}>
      <button
        id={id}
        className={
          cn(
            'btn',
            'btn-default',
            { deleteAllButton },
            { backBtn },
            { fullWidth },
            { transparent },
            { roundCorner },
            { blueBorder },
            { [className]: Boolean(className) },
            { [ButtonState[state]]: state }
          )
        }
        onClick={e => {
          if (!onClick || state === ButtonState.loading) return;

          onClick(e);
        }}
        disabled={disabled}
      >
        {state === ButtonState.failed ? <Translation>{t => t('txt_error')}</Translation> : children}
        {state === ButtonState.loading && <LoadingIcon />}
        {state === ButtonState.success && <CheckIcon />}
      </button>
      {state === ButtonState.failed && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
};

export default Button;
