import React, { useEffect, useRef, useState } from 'react';
import ProductType from '@categoryProduct/view/ProductBirthPoster/ProductType';
import getEditorSize from '@categoryProduct/view/ProductBirthPoster/EditorArea/getEditorSize';

import styles from './index.css';
import '../../../asset/css/fonts.css';

interface Props {
  children: React.ReactNode;
  pageSize: {
    width: number,
    height: number,
  };
}

const MAX_PREVIEW_HEIGHT = 600;

const EditorArea = ({ children, pageSize }: Props) => {
  const [ height, setHeight ] = useState(0);
  const [ width, setWidth ] = useState(0);
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editorRef.current && pageSize.width && pageSize.height ) {
      const rect = editorRef.current.getBoundingClientRect();
      const size = getEditorSize(rect, pageSize, MAX_PREVIEW_HEIGHT);
      setWidth(size.width);
      setHeight(size.height);
    }
  }, [ pageSize ]);

  const style = {
    height: `${height}px`,
    width: width ? `${width}px` : '0px',
  };

  return (
    <div className={styles.editorArea}>
      <div className={styles.editorWrap} ref={editorRef}>
        <div className={styles.editor} style={style}>
          {!!width && !!height && children}
        </div>
      </div>
      <ProductType />
    </div>
  );
};

export default EditorArea;
