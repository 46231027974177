import React, { useContext } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import EditorControlsContext from '@categoryProduct/util/context/EditorControlsContext';
import updatePosterPlaceholder from '@categoryProduct/store/birthPosters/operation/updatePosterPlaceholder';
import Text from '@categoryProduct/component/common/Text';
import BabyIndexContext from '@categoryProduct/util/context/BabyIndexContext';
import { Store } from '@categoryProduct/store/typings';
import SimpleInput from '../../Controls/SimpleInput';
import styles from './index.css';

interface OwnProps {
  section: string;
}

interface Props extends OwnProps {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const BabyNameInput = ({ section, dispatch }: Props) => {
  const { t } = useTranslation();
  const nameKeys = [
    'txt_birth_poster_pl_default_name_1',
    'txt_birth_poster_pl_default_name_2',
    'txt_birth_poster_pl_default_name_3',
  ];

  const controlName = 'babyName';

  const editorControls = useContext(EditorControlsContext);
  const babyIndex = useContext(BabyIndexContext);
  const initialValue = t(nameKeys[babyIndex - 1]);
  const validator = (v: string) => {
    return Boolean(v && v.trim().length > 0);
  };

  const onChange = (value: string, isValid: boolean) => {
    if (editorControls) {
      dispatch(updatePosterPlaceholder(editorControls, section, babyIndex));
    }
  };


  return (
    <>
      <Translation>
        {t => (
          <div
            className={styles.babyName}
          >
            <Text darkBlue>{t('txt_name')}:</Text>
            <SimpleInput
              initialValue={initialValue}
              validator={validator}
              onChange={onChange}
              section={section}
              name={controlName}
            />
          </div>
        )}
      </Translation>
    </>
  );
};

export default connect()(BabyNameInput);
