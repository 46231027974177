import React from 'react';
import classNames from 'classnames/bind';
import { Translation, withTranslation } from 'react-i18next';

import Text from '@categoryProduct/component/common/Text';
import { getSpotFinishImageById } from '@categoryProduct/component/util/getStaticImageUrl';

import styles from '@categoryProduct/component/Product/UpsellLists/styles.css';

type Props = {
  id: number,
  selectedId: number,
  onClick: (id: number) => void
  isDisabled: boolean,
  children?: JSX.Element | null,
};

const cn = classNames.bind(styles);

// to be picked up by script-collector
withTranslation()(({ t }) => {
  return (
    <>
      {t('txt_api_spot_finish_none_name_short')}
      {t('txt_api_spot_finish_10_name_short')}
      {t('txt_api_spot_finish_11_name_short')}
      {t('txt_api_spot_finish_12_name_short')}
    </>
  );
});

const mapIdToName = (id: number) => ({
  1: 'txt_api_spot_finish_none_name_short',
  2: 'txt_api_spot_finish_10_name_short',
  3: 'txt_api_spot_finish_11_name_short',
  4: 'txt_api_spot_finish_12_name_short',
}[id]);

export default (props: Props) => {
  const {
    id,
    selectedId,
    isDisabled,
    children,
  } = props;
  const isActive = id === selectedId;
  const imageUrl = getSpotFinishImageById(id);
  const onClick = () => {
    if (!isDisabled) {
      props.onClick(id);
    }
  };

  return (
    <Translation>
      {(t) => (
        <div
          id={`spot_finish_button_${id}`}
          className={cn('square', {
            'squareSelected': isActive,
            'squareDisabled': isDisabled,
            'squareOnly': !imageUrl,
          })}
          key={id}
          onClick={onClick}
        >
          {imageUrl && (
            <div className={styles.squareImageWrapper}>
              <img src={imageUrl} alt='' />
            </div>
          )}
          <div className={cn({ 'squareContentOnly': !imageUrl, 'squareContent': imageUrl })}>
            <Text small={!imageUrl}>{t(mapIdToName(id))}</Text>
            {children}
          </div>
        </div>
      )}
    </Translation>
  );
};
