import { Store } from '@categoryProduct/store/typings';

const BABY_IMAGE_NAME = 'birthPosterBaby_';

export default (state: Store, section: string) => {
  const sectionIndex = section.replace(/[^0-9]*/g, '');
  const babyIndex = Number(sectionIndex) - 1;
  const { designData } = state.design;
  const designPage = designData ? designData.structure[0] : null;
  const element = designPage && designPage.elements.filter((e: any) => {
    return e.t === 'image' && e.n === `${BABY_IMAGE_NAME}${babyIndex}`;
  })[0];

  return element ? element.bpbd : null;
};
