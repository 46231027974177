enum ActionTypes {
  REGISTER_INPUT_CONTROL = 'REGISTER_INPUT_CONTROL',
  DEACTIVATE_INPUT_CONTROL = 'DEACTIVATE_INPUT_CONTROL',
  ACTIVATE_SECTION = 'ACTIVATE_SECTION',
  DEACTIVATE_SECTION_CONTROLS = 'DEACTIVATE_SECTION_CONTROLS',
  SET_INPUT_VALUE = 'SET_INPUT_VALUE',
  TOUCH_SECTION = 'TOUCH_SECTION',
  TOUCH_ALL_SECTIONS = 'TOUCH_ALL_SECTIONS',
  RECHECK_INPUT_CONTROL = 'RECHECK_INPUT_CONTROL',
  SET_INITIAL_CONTROLS_VALUES = 'SET_INITIAL_CONTROLS_VALUES',
  CLEAR_ALL_SECTIONS = 'CLEAR_ALL_SECTIONS',
}

export default ActionTypes;
