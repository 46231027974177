import LocaleToCurrency from 'locale-currency';
import global from './global';

const REPLACE_DECIMALS_FOR_CURRENCIES = [ 'NOK', 'SEK', 'DKK', 'CZK', 'PLN' ];
const BIG_PRICE_DEFINITION = 1000;
const WITH_FRACTIONAL_PART = 100;
const FRACTIONAL_PART_LENGTH = 2;

export default (
  price: number,
  currencyFormat: string,
  withoutDecimals: boolean | 'auto' = false,
) => {
  const format = withoutDecimals === true ? removeDecimalsFromTemplate(currencyFormat) : currencyFormat;
  const currency = getCurrency();
  let result = getPriceStringFromPrice(price);
  let [ base, decimal ] = result.split('.');

  // corner case: USD and big number
  if (currency === 'USD' && parseInt(base, 10) >= BIG_PRICE_DEFINITION) {
    base = getBigPriceFormat(base);
  }

  result = convertTemplateToPrice(format, base, decimal);

  return withoutDecimals === 'auto' && REPLACE_DECIMALS_FOR_CURRENCIES.indexOf(currency) !== -1
    ? removeDecimals(result)
    : result;
};

/* helpers */

export const getBigPriceFormat = (basePriceString: string) => {
  return Number(basePriceString).toLocaleString('en-US');
};

export const getPriceStringFromPrice = (price: number) => {
  return Math.round(price * WITH_FRACTIONAL_PART) === 0
    ? '0.00'
    : parseFloat(String(price)).toFixed(FRACTIONAL_PART_LENGTH);
};

export const getCurrency = () => {
  const locale = global('locale');
  return locale && LocaleToCurrency.getCurrency(locale);
};

export const convertTemplateToPrice = (template: string, base: string, decimals: string) => {
  return template
    .replace('5', base)
    .replace('DD', decimals.substring(0, FRACTIONAL_PART_LENGTH));
};

export const removeDecimalsFromTemplate = (priceString: string) => {
  return priceString
    .replace('.DD', '')
    .replace(',DD', '');
};

export const removeDecimals = (priceString: string) => {
  return priceString
    .replace('.00', '')
    .replace(',00', '');
};
