import { ControlsState } from '../types';

const clearAllSections = (state: ControlsState) => ({
  ...state,
  sections: [],
  touchedSections: [],
  activeSectionId: '',
});

export default clearAllSections;
