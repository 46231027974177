/* INDEX FILE
This file, from a module perspective, behaves as the duck file form the original proposal.
It exports as default the reducer function of the duck.
It exports as named export the selectors and the operations.
*/

import reducer from './reducers';

import * as requestSelectors from './selectors';

export {
    requestSelectors,
};

export default reducer;
