import React from 'react';
import classnames from 'classnames/bind';

const styles = require('./styles.css');

const cn = classnames.bind(styles);

interface Props {
  id?: string;
  small?: boolean;
  header1?: boolean;
  header2?: boolean;
  header3?: boolean;
  header4?: boolean;
  header5?: boolean;
  header6?: boolean;
  bold?: boolean;
  darkBlue?: boolean;
  lightBlue?: boolean;
  className?: string;
  onClick?: () => void;
}

const Text: React.FC<Props> = (
  {
    children,
    small,
    header1,
    header2,
    header3,
    header4,
    header5,
    header6,
    bold,
    darkBlue,
    lightBlue,
    className = '',
    onClick,
  }) => {
  return (
    <div
      className={cn(
        'default',
        {
          small,
          header1,
          header2,
          header3,
          header4,
          header5,
          header6,
          bold,
          darkBlue,
          lightBlue,
          [className]: Boolean(className),
        },
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Text;
