import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import Text from '@categoryProduct/component/common/Text';
import EditorControlsContext from '@categoryProduct/util/context/EditorControlsContext';
import BabyIndexContext from '@categoryProduct/util/context/BabyIndexContext';
import getControlValue from '@categoryProduct/store/controls/selector/getControlValue';
import updatePosterPlaceholder from '@categoryProduct/store/birthPosters/operation/updatePosterPlaceholder';
import { Store } from '@categoryProduct/store/typings';
import SimpleInput from '../../Controls/SimpleInput';
import SimpleLabel from '../../Controls/SimpleLabel';
import styles from './index.css';

interface OwnProps {
  section: string;
}

interface Props extends OwnProps {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
  weightUnits: any;
}

const controlName = 'babyWeightGrams';
const controlPoundsName = 'babyWeightPounds';
const controlOuncesName = 'babyWeightOunces';
const controlUnitsName = 'babyWeightUnits';

const BabyWeightInput = ({ section, weightUnits, dispatch }: Props) => {
  const { t } = useTranslation();
  const initialValueGrams = '3000';
  const initialValue = '';
  const editorControls = useContext(EditorControlsContext);
  const babyIndex = useContext(BabyIndexContext);
  const unitList = [
    {
      label: 'txt_grams',
      value: 'm',
      id: 1,
    },
    {
      label: 'txt_pounds_and_ounces',
      value: 'i',
      id: 2,
    },
  ];

  const [ currentUnitIndex, setUnitIndex ] = useState(0);
  const nextIndex = () => ((currentUnitIndex + 1) % unitList.length);

  const nextValue = unitList[nextIndex()].label;

  useEffect(() => {
    if (!weightUnits) {
      return;
    }
    const foundUnitIndex = unitList.findIndex(unitValue => unitValue.value === weightUnits.value);
    setUnitIndex(foundUnitIndex);
  }, [weightUnits]);

  const validator = (v: string) => {
    return Boolean(v && v.trim().length > 0);
  };
  const onChange = () => {
    if (editorControls) {
      dispatch(updatePosterPlaceholder(editorControls, section, babyIndex));
    }
  };

  const changeUnitsClick = () => {
    setUnitIndex(nextIndex());
  };

  return (
    <>
      <div>
        <div className={styles.title}>
          <Text darkBlue>{t('txt_weight')}:</Text>
          <div
            className={styles.changeUnits}
            onClick={changeUnitsClick}
          >
            {t('txt_change_unit')} - <span>{t(nextValue)}</span>
          </div>
        </div>
        <div className={styles.inputs}>
          {unitList[currentUnitIndex].id === 1 && (
            <SimpleInput
              className={styles.weight}
              initialValue={initialValueGrams}
              type="number"
              validator={validator}
              onChange={onChange}
              section={section}
              name={controlName}
            />
          )}
          {unitList[currentUnitIndex].id === 2 && (
            <>
              <SimpleInput
                className={styles.weight}
                initialValue={initialValue}
                type="number"
                validator={validator}
                onChange={onChange}
                section={section}
                name={controlPoundsName}
              />
              <div className={styles.unitPounds}>{t('txt_pounds')}</div>
              <SimpleInput
                className={styles.weight}
                initialValue={initialValue}
                type="number"
                validator={validator}
                onChange={onChange}
                section={section}
                name={controlOuncesName}
              />
              <div className={styles.unitOunces}>{t('txt_ounces')}</div>
            </>
          )}
          <div style={{ display: unitList[currentUnitIndex].id === 2 ? 'none' : 'flex' }}>
            <SimpleLabel
              options={unitList}
              index={currentUnitIndex}
              onChange={onChange}
              section={section}
              name={controlUnitsName}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: Store, {section}: OwnProps) => ({
  weightUnits: getControlValue(state, section, controlUnitsName),
});
export default connect(mapStateToProps)(BabyWeightInput);
