import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';

import { StoreType } from '@categoryProduct/store/typings';
import Text from '@categoryProduct/component/common/Text';
import { priceSelectors } from '@categoryProduct/store/price';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import { Expand, ExpandStyles } from '@categoryProduct/component/common/Animation';
import TrimOptionPrice from './UpsellOptionPrice';
import TrimOption from './TrimOption';
import {
  getAvailableTrims,
  getOrderedUpsellIds,
  getTrimsForSelectedSpotFinishId,
  isOnlyDefaultUpsellValue,
} from '@categoryProduct/component/util/TrimFoilHelper';

import styles from '@categoryProduct/component/Product/Product.css';
import upsellListsStyles from './styles.css';
import { Design } from '@categoryProduct/typings';

type Props = {
  priceFormat: string,
  getUpsells: (cId: number, pId: number) => apiTypings.AppBundle.Api.Entity.Product.V1.ProductUpsellItems | undefined,
};

type OwnProps = {
  selectedTrimId: number,
  selectedSpotFinishId: number,
  categoryId: number,
  selectedDesign: Design,
  quantity: number,
  selectUpsell: (
    ids: apiTypings.AppBundle.Api.Entity.Product.V1.IUpsellItem[],
    id: number,
  ) => apiTypings.AppBundle.Api.Entity.Product.V1.IUpsellItem | null,
  onClick: (id: number) => void,
};

const TrimList = (props: OwnProps & Props) => {
  const {
    getUpsells,
    selectUpsell,
    selectedTrimId,
    selectedSpotFinishId,
    priceFormat,
    quantity,
    categoryId,
    selectedDesign: { upsellOptions, productId },
    onClick,
  } = props;
  const trims = getAvailableTrims(upsellOptions);

  // if no options or only options is default one
  if (!trims || trims.length === 1 && trims[0] === 1) {
    return null;
  }

  const trimsAvailableForSelectedSpot = getTrimsForSelectedSpotFinishId(upsellOptions, selectedSpotFinishId);
  const upsellCombinationsAreUnavailable = isOnlyDefaultUpsellValue(trimsAvailableForSelectedSpot);
  const upsells = getUpsells(categoryId, productId);

  const renderedList = getOrderedUpsellIds(trims)
    .map((id: number) => {
      const trim = upsells && selectUpsell(upsells.trims, id);

      if (!trim) {
        return null;
      }

      const price = trim.prices && trim.prices
        .find((p: apiTypings.AppBundle.Api.Entity.Product.V1.IProductPrice) => p.q === quantity);
      return (
        <React.Fragment key={id}>
          {id === 1 && <div className={upsellListsStyles.flexBreakLineElement} />}
          <div className={upsellListsStyles.upsellOptionsContainer} key={id}>
            <TrimOption
              id={id}
              selectedId={selectedTrimId}
              isDisabled={id !== 1 && upsellCombinationsAreUnavailable}
              onClick={onClick}
            >
              {price && (
                <TrimOptionPrice
                  price={price}
                  priceFormat={priceFormat}
                  isDisabled={upsellCombinationsAreUnavailable}
                  isVisible
                />
              )}
            </TrimOption>
          </div>
        </React.Fragment>
      );
    });

  return (
    <Translation>
      {t => (
        <div id='trim_box' className={cn(styles.infoWrapper, upsellListsStyles.withOverflowMessage)}>
          {upsells && <Text bold darkBlue className={styles.infoTitle}>{t('txt_trim')}:</Text>}
          <div className={styles.infoValuesWrapper}>
            <div className={styles.formatContainer}>
              {renderedList}
            </div>
            <Expand in={upsellCombinationsAreUnavailable}>
              <div
                className={cn(
                  styles.note,
                  upsellListsStyles.upsellNote,
                  { [ExpandStyles.exitDone]: !upsellCombinationsAreUnavailable },
                )}
              >
                {t('txt_disabled_trim_note')}
              </div>
            </Expand>
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state: StoreType) => ({
  priceFormat: priceSelectors.getPriceFormat(state),
  getUpsells: (categoryId: number, productId: number) => priceSelectors.getUpsells(state, categoryId, productId),
});

export default connect(mapStateToProps)(TrimList);
