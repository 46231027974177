import React from 'react';
import { connect } from 'react-redux';
import {Store} from '@categoryProduct/store/typings';
import getPageSize from '@categoryProduct/store/design/selector/getPageSize';
import {useTranslation} from 'react-i18next';
import {mm2cm} from '@categoryProduct/util/mm2cm';
import {mm2inch} from '@categoryProduct/util/mm2inch';

interface Props {
  pageSize: {
    width: number,
    height: number,
    bleed: number,
  } | null
}

const ProductType: React.FC<Props> = ({pageSize}: Props) => {
  const { t } = useTranslation();
  if (!pageSize) {
    return null;
  }
  const cmWidth = Math.round(mm2cm(pageSize.width - pageSize.bleed * 2));
  const cmHeight = Math.round(mm2cm(pageSize.height - pageSize.bleed * 2));
  const inchWidth = Math.round(mm2inch(pageSize.width - pageSize.bleed * 2) * 10) / 10;
  const inchHeight = Math.round(mm2inch(pageSize.height - pageSize.bleed * 2) * 10) / 10;

  return (
    <React.Fragment>
      {cmWidth && <span>
        {t('txt_poster_size')}: {cmWidth}x{cmHeight} cm ({inchWidth}”x{inchHeight}”)
        </span>
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state: Store) => ({
    pageSize: getPageSize(state),
});

export default connect(mapStateToProps)(ProductType);
