import React from 'react';
import { connect } from 'react-redux';
import {useTranslation} from 'react-i18next';
import { Store } from '@categoryProduct/store/typings';
import getNumberOfBabies from '@categoryProduct/store/birthPosters/selector/getNumberOfBabies';
import SimpleSection from '../Controls/SimpleSection';
import BabyNumberSelector from './BabyNumberSelector';
import BackgroundSelector from './BackgroundSelector';
import BabyIllustrationSelector from '../BabyIllustrationSelector';
import BabyFacingSelector from '../BabyFacingSelector';
import NextStepButton from '../NextStepButton';
import styles from './index.css';

interface Props {
  numberOfBabies: number;
}

const PosterDesignSettings = ({numberOfBabies}: Props) => {
  const { t } = useTranslation();
  const section = 'step1';
  const baby1Section = 'step2';
  return (
    <SimpleSection title={t('txt_step_1_design')} section={section} allowUserExpand>
      <div className={styles.inputControls}>
        <BabyNumberSelector section={section} />
        { numberOfBabies === 1 && (
          <>
            <BabyIllustrationSelector section={baby1Section} babyIndex={1} />
            <BabyFacingSelector section={baby1Section} babyIndex={1} />
          </>
        )}
        <BackgroundSelector section={section} />
        <NextStepButton ifSectionValid={section} nextSection="step2" />
      </div>
    </SimpleSection>
  );
};

const mapStateToProps = (state: Store) => ({
  numberOfBabies: getNumberOfBabies(state),
});

export default connect(mapStateToProps)(PosterDesignSettings);
