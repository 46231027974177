import getImagesRootUrl from '@categoryProduct/util/getImagesRootUrl';

export const RELATIVE_TOOLTIP_IMAGE_PATH = '/s/assets/images/reactCategoryProduct/UpsellTooltip/';
export const mapSpotFinishIdToImageName = (id: number) => ({
  2: 'preview_gold.jpg',
  3: 'preview_silver.jpg',
  4: 'preview_copper.jpg',
}[id]);
export const mapTrimIdToImageName = (id: number) => ({
  2: 'preview_rounded.jpg',
}[id]);

export const getSpotFinishImageById = (id: number) => {
  const imageName = mapSpotFinishIdToImageName(id);
  return imageName ? `${getImagesRootUrl()}${RELATIVE_TOOLTIP_IMAGE_PATH}${imageName}` : '';
};

export const getTrimImage = (id: number) => {
  const imageName = mapTrimIdToImageName(id);
  return imageName ? `${getImagesRootUrl()}${RELATIVE_TOOLTIP_IMAGE_PATH}${imageName}` : '';
};


