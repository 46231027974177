import React from 'react';
import { connect } from 'react-redux';
import { Store } from '@categoryProduct/store/typings';
import { getPriceFormat, getPrices } from '@categoryProduct/store/price/selectors';
import getCategoryId from '@categoryProduct/store/design/selector/getCategoryId';
import getProductId from '@categoryProduct/store/design/selector/getProductId';
import Price from '@categoryProduct/component/common/Price';
import formatPrice from '@categoryProduct/util/formatPrice';

import styles from './styles.css';

interface Props {
  prices: any;
  priceFormat: string;
}

const ProductPrice = (props: Props) => {
  const { prices, priceFormat } = props;
  const defaultPrice = prices && prices.filter((p: any) => Boolean(p.d))[0];

  const renderPrice = (price?: number) => {
    return (price || price === 0) && priceFormat ? formatPrice(price, priceFormat) : undefined;
  };

  if (!defaultPrice) {
    return null;
  }

  return (
    <div className={styles.productPrice}>
      <Price
        price={renderPrice(defaultPrice ? defaultPrice.pi : 0)}
        discountPrice={renderPrice(defaultPrice ? defaultPrice.p : 0)}
      />
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const categoryId = getCategoryId(state);
  const productId = getProductId(state);

  return {
    priceFormat: getPriceFormat(state),
    prices: getPrices(state, categoryId, productId),
  };
};

export default connect(mapStateToProps)(ProductPrice);
