import { ControlsState } from '../types';

const touchAllSections = (state: ControlsState) => {
  const touchedSections = Object.keys(state.sections).map(section => section);
  return {
    ...state,
    touchedSections,
  };
};

export default touchAllSections;
