import React from 'react';

import ProductPageProxy from '@categoryProduct/view/Proxy';
import Category from '../component/Category';

const productTranslations = [ 'prodotto', 'product', 'producto', 'produit', 'produkt', 'produtos' ];

const productRoutes = productTranslations.map(trans => ({
  path: `/${trans}/:slug/:category_id/:design_id`,
  Component: ProductPageProxy,
}));

const productRoutesWithBaseLocale = productTranslations.map(trans => ({
  path: `/:locale/${trans}/:slug/:category_id/:design_id`,
  Component: ProductPageProxy,
}));

interface Route {
  path: string;
  Component: React.ComponentType<any>;
  exact?: boolean;
  [x: string]: any;
}

const initialRoutes: Route[] = [
  {
    path: '/',
    Component: Category,
    exact: true,
    categoryId: 518,
  },
];

export const composeRoutes = (categories: any): Route[] => {
  const categoryRoutes = Object.keys(categories).map((key) => {
    const { categoryId, url } = categories[key];

    return {
      categoryId,
      path: url,
      exact: true,
      Component: Category,
    };
  });

  return [
    ...productRoutes,
    ...productRoutesWithBaseLocale,
    ...categoryRoutes,
    ...initialRoutes,
  ];
};
