/* OPERATIONS = REDUX THUNKS
This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator, ex: simpleQuack
Complex operations involve returning a thunk that dispatches multiple actions in a certain order, ex: complexQuack
*/

import { Dispatch } from 'redux';
import { difference, each, get, isEmpty, isUndefined } from 'lodash';

import * as actions from './actions';
import { EMPTY_FILTERS, mapAvailableUpsells } from './helper';
import apiRequest from '@categoryProduct/api/apiRequest';
import endpoints from '@categoryProduct/api/endpoints';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import {
  getParamFromLocation,
  removeParamFromLocation,
  setParamInLocation,
} from '@categoryProduct/util/browserLocation';

import global from '@categoryProduct/util/global';
import {
  defaultFamilyWrapper,
  filterFamilies,
  findFilterbyTag,
  getSelectedFilters,
  remapSelectedFiltersToAvailableFilters,
} from '@categoryProduct/util/category';
import { History } from 'history';
import { isEntityFetched } from '../request/selectors';
import shouldUseNewPreviews from '@categoryProduct/util/shouldUseNewPreviews';
import { getCategories, getDefaultFiltersForCategory, getFavourites } from './selectors';
import { decrementFavouritesCount, incrementFavouritesCount } from '@categoryProduct/util/updateBadgesCount';
import { Design, DesignFamily } from '@categoryProduct/typings';
import findAndSetFrameAlternatives from './findAndSetFrameAlternatives';

const base64Img = require('base64-img');

const fetchDesignFamilies = async (
  dispatch: Dispatch,
  state: any,
  categoryId: number,
  sortBy: number,
  page?: number,
  limit?: number,
  history?: History,
  fetchFirstPage?: boolean,
) => {
  const categories = state && state.category
    ? Object.values((getCategories(state) || {}))
    : (process as unknown as { categories: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo })
      .categories[global('locale')];
  const useProspectivePreviews = shouldUseNewPreviews(categories, categoryId);
  const locale = global('locale');
  const useTrim = !isUndefined(global('ab_trim')) ? global('ab_trim') : true;
  const useFoil = !isUndefined(global('ab_spot-finish')) ? global('ab_spot-finish') : true;

  const requestData: { [k: string]: any } = {
    categoryId,
    sortBy,
    locale,
    returnUrls: true,
    allowSpotFinish: useFoil,
    allowTrim: useTrim,
    useNewPreview: useProspectivePreviews,
  };

  if (fetchFirstPage) {
    requestData.offset = 0;
    requestData.limit = limit;
  }

  // TODO: add isMobile param.
  const designFamilies = (await apiRequest(
    endpoints.categoryDesignList,
    requestData,
    'GET',
    'data.designFamilies',
  )) as { designs: Design[] }[];

  if (isEntityFetched(state, `category/${categoryId}`)) {
    return designFamilies;
  }

  const totalDesignsCount = designFamilies.length;
  const pageLimitedToAvailableData = limit && page && designFamilies.length / limit < page
    ? Math.ceil(designFamilies.length / limit)
    : page;
  const data = defaultFamilyWrapper(
    designFamilies
      .map(family => ({
        ...family,
        designs: family.designs ? family.designs.map(mapAvailableUpsells) : family.designs,
      })),
    pageLimitedToAvailableData,
    limit,
  );

  dispatch(
    actions.designFamiliesSet({
      categoryId,
      totalDesignsCount,
      data,
      fetchState: !limit && !page ? 'all' : 'partial',
    }),
  );

  // update pagenr in url if it needs to change
  const pageNrFromUrl = history && getParamFromLocation(history, 'page_nr') as string;
  let parsedNr = (pageNrFromUrl && parseInt(pageNrFromUrl, 10));
  if (!parsedNr && parsedNr !== 0) {
    parsedNr = 1;
  }

  let pageNrLimited = pageNrFromUrl && designFamilies.length / 50 < parsedNr ? Math.ceil(designFamilies.length / 50) : parsedNr;
  if (!pageNrLimited || pageNrLimited < 1) {
    pageNrLimited = 1;
  }

  if (history && pageNrLimited !== parsedNr) {
    setParamInLocation(history, 'page_nr', `${pageNrLimited}`, true);
  }

  return designFamilies;
};

export const fetchDesignFamiliesAndFilters = async (
  dispatch: Dispatch,
  state: any,
  categoryId: number,
  sortBy: number,
  filterString?: string,
  page?: number,
  limit?: number,
  history?: History,
) => {
  const shouldFetchInitialChunk = !filterString && typeof window === 'undefined';
  const responses = await Promise.all([
    fetchDesignFamilies(dispatch, state, categoryId, sortBy, page, limit, history, shouldFetchInitialChunk),
    apiRequest(
      endpoints.categoryFilterList,
      {
        locale: global('locale'),
      },
      'GET',
      'data',
    ),
  ]);

  const designFamilies = responses[0];
  const availableFilters = responses[1] as Filters;
  const defaultFilterTags = getDefaultFiltersForCategory(state, categoryId);

  const filters = filterString !== undefined && await defineFilters(
    dispatch,
    categoryId,
    filterString,
    availableFilters,
    designFamilies,
    defaultFilterTags,
  );

  return {
    designFamilies,
    filters,
  };
};

export const fetchDesignFamily = async (
  dispatch: Dispatch,
  state: any,
  categoryId: number,
  designId: string,
) => {
  const params = {
    categoryId,
    encPublicDesignId: designId,
    returnUrls: true,
  };

  const familyDesigns = (await apiRequest(
    endpoints.designFamily,
    params,
    'GET',
    'data.designs',
  )) as Design[];

  const family: DesignFamily = { designs: familyDesigns.map(mapAvailableUpsells) };

  if (family.designs.length === 0) {
    // It should mean design is no longer sold on OP, so we should send customer to category page
    throw {
      httpResponseCode: 404,
      reason: `Design with id ${designId} wasn't found`,
    };
  }

  if (isEntityFetched(state, `family/${family.designs[0].familyId}`)) {
    return family;
  }

  dispatch(actions.designFamilySet({ categoryId, data: family }));

  return family;
};

export const favouritesFetch = async (
  dispatch: Dispatch,
  state: any,
) => {
  const isLoggedIn = global('isLoggedIn');

  if (!isLoggedIn) {
    return [];
  }

  const params = {
    locale: global('locale'),
  };

  if (isEntityFetched(state, 'favourites')) {
    return getFavourites(state);
  }

  try {
    const favourites = (await apiRequest(
      endpoints.favouriteList,
      params,
      'POST',
      'data.favouriteDesigns',
    )) as apiTypings.AppBundle.Api.Entity.Design.V1.FavouriteDesign[];

    const designIds = favourites.map(f => f.encPublicDesignId);

    dispatch(actions.favouritesAdd({ data: designIds, fetchState: 'all' }));

    return designIds;
  } catch (err) {
    console.error(err);
  }

  return [];
};

let tmpCartCount = null as number | null;
export const cartItemsCountFetch = async (
  dispatch: Dispatch,
  state: any,
) => {
  const isLoggedIn = global('isLoggedIn');

  if (!isEmpty(tmpCartCount) || !isLoggedIn) {
    return tmpCartCount;
  }

  try {
    const carts = (await apiRequest(
      endpoints.cartGet,
      {},
      'POST',
      'data.carts',
    )) as apiTypings.AppBundle.Api.Entity.Cart.V1.Cart[];

    const totalNbItems = carts
      .reduce(
        (acc, cart) => [ ...acc, ...cart.items as apiTypings.AppBundle.Api.Entity.Cart.V1.Item[] ],
        [] as apiTypings.AppBundle.Api.Entity.Cart.V1.Item[],
      ).length;

    tmpCartCount = totalNbItems;

    return totalNbItems;
  } catch (err) {
    console.error(err);
  }

  return null;
};

export const favouritesAdd = async (
  dispatch: Dispatch,
  designId: string,
) => {
  const params = {
    encPublicDesignId: designId,
    locale: global('locale'),
  };

  await apiRequest(
    endpoints.favouriteAdd,
    params,
    'POST',
    'data',
  );

  dispatch(actions.favouritesAdd({ data: [ designId ] }));

  // TODO: (DIRTY) remove in favour of react header
  incrementFavouritesCount();
};

export const favouritesRemove = async (
  dispatch: Dispatch,
  designId: string,
) => {
  const params = {
    encPublicDesignId: designId,
  };

  await apiRequest(
    endpoints.favouriteRemove,
    params,
    'POST',
    'data',
  );

  dispatch(actions.favouritesRemove({ data: [ designId ] }));
  decrementFavouritesCount();
};

export const clearFamilies = async (dispatch: Dispatch, categoryId: number) => {
  dispatch(
    actions.designFamiliesDelete({
      categoryId,
    }),
  );
};

export const fetchCategory = async (dispatch: Dispatch, state: any, categoryId: number, fetchSubcategories: boolean = false) => {
  if (isEntityFetched(state, 'categories')) {
    return;
  }

  const data = (await apiRequest(
    endpoints.categoryList,
    {
      locale: global('locale'),
    },
    'GET',
    'data.categories',
  )) as apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo[];

  const category = data.find((cat) => cat.categoryId == categoryId);
  if (!category) {
    return;
  }

  let subCategories = [] as apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo[];
  let parentCategories = [] as apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo[];

  // fetch subcategories
  if (fetchSubcategories) {
    let parentCategoryId = category.parentCategoryId || category.categoryId;

    // category (thank-you-cards : 12) should not be treated as parent category
    if (category.parentCategoryId === 12) {
      parentCategoryId = category.categoryId;
    }

    subCategories = data.filter(c => c.parentCategoryId === parentCategoryId || c.categoryId === parentCategoryId);
  }

  // fetch parent categories
  const getParentCategories = (parentCatId: number): apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo[] => {
    const parentCategory = data.find(c => c.categoryId === parentCatId);
    return parentCategory ? [ parentCategory, ...getParentCategories(parentCategory.parentCategoryId) ] : [];
  };

  parentCategories = getParentCategories(category.parentCategoryId);

  const categoriesNormalised = [ category, ...subCategories, ...parentCategories ].reduce((acc, c) => ({
    ...acc,
    [c.categoryId]: c,
  }), {});

  dispatch(
    actions.listSet({
      data: categoriesNormalised,
    }),
  );

  return category.parentCategoryId || category.categoryId;
};

export const fetchCategories = async (dispatch: Dispatch, state: any) => {
  if (isEntityFetched(state, 'categories')) {
    return;
  }

  const data = (await apiRequest(
    endpoints.categoryList,
    {
      locale: global('locale'),
    },
    'GET',
    'data.categories',
  )) as apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo[];

  const categories = data.reduce((obj, item: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo) => {
    obj[item.categoryId] = item;

    return obj;
  }, {});

  dispatch(
    actions.listSet({
      data: categories,
      fetchState: 'all',
    }),
  );
};

export const defineFilters = async (
  dispatch: Dispatch,
  categoryId: number,
  filterString: string,
  availableFilters: Filters,
  designFamilies: any,
  defaultFilterTags: number[],
): Promise<Filters> => {
  const result = availableFilters;
  const enabled = [ 'age', 'color', 'foil', 'folded', 'gender', 'nfotos', 'orient', 'size', 'trim', 'style', 'theme' ];

  each(result, (_, i) => {
    if (enabled.indexOf(i) === -1) {
      delete result[i];
    }
  });

  each(EMPTY_FILTERS, (v, i) => {
    if (typeof result[i] === 'undefined') {
      result[i] = v;
    }
  });

  // Remove not used filters
  each([ 'style', 'theme', 'age' ], filter => {
    result[filter] = get((get(result, filter, []) as any[])
      .find(row => row.categoryId === categoryId), 'filters', []);
  });

  // Copy theme filters to styles
  const stylesTags = result.style.map(v => v.tag);
  each(result.theme, filter => {
    if (!stylesTags.find(v => v === filter.tag)) {
      stylesTags.push(filter.tag);
      result.style.push(filter);
    }
  });
  delete result.theme;

  result.style = result.style.filter(filter => !!filter.tagTr).map(filter => {
    filter.tagTr = filter.tagTr.charAt(0).toUpperCase() + filter.tagTr.slice(1);
    return filter;
  });

  const usedTags = {};
  each(designFamilies, family => {
    each(family.designs, design => {
      each(design.tags, tag => {
        usedTags[tag] = 1;
      });
    });
  });

  each(Object.keys(result), name => {
    let i;
    for (i = 0; i < result[name].length; i += 1) {
      if (typeof usedTags[result[name][i].tag] === 'undefined') {
        result[name].splice(i, 1);
        i -= 1;
      }
    }
  });

  const selectedTags: number[] = [ ...defaultFilterTags ];
  const filtersArray: string[] = (filterString || '').split(' ');
  const filtersKeys = Object.keys(availableFilters);

  each(filtersKeys, (key) => {
    each(availableFilters[key], (filter) => {
      if (filtersArray.includes(`${filter.filterName}_${filter.filterValue}`)) {
        selectedTags.push(filter.tag);
      }
    });
  });

  const { enabledTags, selectedTags: selectedTagsNew } = filterFamilies(
    designFamilies,
    getSelectedFilters(availableFilters, selectedTags),
    availableFilters,
  );

  dispatch(
    actions.availableFiltersSet({
      data: remapSelectedFiltersToAvailableFilters(availableFilters, selectedTagsNew, enabledTags),
    }),
  );

  return result;
};

export const setFilter = (
  history: History,
  dispatch: Dispatch,
  tag: number,
  availableFilters: Filters,
  selectedFilters: number[],
  designFamilies: DesignFamily[],
) => {
  const updateHashToSelectedTags = (selectedTags: number[], newTag: number) => {
    if (selectedTags.indexOf(newTag) === -1) {
      selectedTags.push(newTag);
      const filter = findFilterbyTag(newTag, availableFilters);
      filter && setParamInLocation(history, 'filter', `${filter.filterName}_${filter.filterValue}`);
    } else {
      selectedTags.splice(selectedTags.indexOf(newTag), 1);
      const filter = findFilterbyTag(newTag, availableFilters);
      filter && removeParamFromLocation(history, 'filter', `${filter.filterName}_${filter.filterValue}`);
    }
  };

  const selectedTags: number[] = [ ...selectedFilters ];
  updateHashToSelectedTags(selectedTags, tag);

  setParamInLocation(history, 'page_nr', '1', true);

  const { enabledTags, selectedTags: selectedTagsNew } = filterFamilies(
    designFamilies,
    getSelectedFilters(availableFilters, selectedTags),
    availableFilters,
  );

  // get difference between tags (in case some was automatically removed)
  const tagsDiff = difference(selectedTags, selectedTagsNew);
  tagsDiff.forEach(tagToRemove => updateHashToSelectedTags(selectedTags, tagToRemove));

  dispatch(
    actions.availableFiltersSet({
      data: remapSelectedFiltersToAvailableFilters(availableFilters, selectedTagsNew, enabledTags),
    }),
  );
};

export const resetFilter = (
  dispatch: Dispatch,
  availableFilters: Filters,
  designFamilies: DesignFamily[],
) => {
  const { enabledTags } = filterFamilies(designFamilies, [], availableFilters);

  dispatch(
    actions.availableFiltersSet({
      data: remapSelectedFiltersToAvailableFilters(availableFilters, [], enabledTags),
    }),
  );
};

export const fetchSimilarProducts = async (
  dispatch: Dispatch,
  categoryId: number,
  designId: string,
) => {
  const similarProducts = (await apiRequest(
    endpoints.categorySimilarProductsList,
    {
      categoryId,
      designId,
      locale: global('locale'),
      returnUrls: true,
    },
    'GET',
    'data.similarProducts',
  )) as apiTypings.AppBundle.Api.Entity.Design.V1.MatchingProduct[];

  dispatch(actions.similarProductsSet({ categoryId, designId, data: similarProducts || [] }));
};

export const fetchPaperFormats = async (
  dispatch: Dispatch,
  state: any,
  productTypeIds?: number[],
) => {
  if (isEntityFetched(state, 'paperFormats')) {
    return;
  }

  const params = {
    locale: global('locale'),
  };

  const data = (await apiRequest(
    endpoints.paperFormats,
    params,
    'GET',
    'data.paperFormats',
  )) as apiTypings.AppBundle.Api.Entity.PaperFormat.V1.PaperFormat[];

  const formats = productTypeIds ? data.filter(f => productTypeIds.indexOf(f.productTypeId) >= 0) : data;

  const toDispatch = {
    data: formats,
  } as any;

  if (!productTypeIds) {
    toDispatch.fetchState = 'all';
  }

  dispatch(actions.paperFormatsSet(toDispatch));
};

export const fetchFrameFormats = async (
  dispatch: Dispatch,
  state: any,
  encPublicDesignId: string,
  categoryId: number
) => {
  if (isEntityFetched(state, 'frameFormats')) {
    return;
  }

  const params = {
    encPublicDesignId,
    categoryId,
    locale: global('locale'),
  };

  const data = (await apiRequest(
    endpoints.frameFormats,
    params,
    'GET',
    'data.formats'
  )) as apiTypings.AppBundle.Api.Entity.PaperFormat.V1.PaperFormat[]; // TODO: FrameFormat type

  const toDispatch = {
    data,
  } as any;

  if (!data) {
    toDispatch.fetchState = 'all';
  }

  dispatch(actions.frameFormatsSet(toDispatch));
  dispatch(findAndSetFrameAlternatives(encPublicDesignId) as any);
};

export const fetchBase64Images = async (
  dispatch: Dispatch,
  images: string[],
) => {
  const imagesData = await Promise.all(images.map(async (url) => ({
    key: url,
    value: await new Promise((resolve) => base64Img.requestBase64(url, (err: any, res: any, body: any) => {
      resolve(body);
    })),
  })));

  const data = imagesData.reduce((acc, img) => ({
    ...acc,
    [img.key]: img.value,
  }), {});

  dispatch(actions.base64Images({ data }));
};

export const fetchGtmFormats = async (
  dispatch: Dispatch,
  params: { categoryId: number, designIds: string },
) => {
  const data = (await apiRequest(
    endpoints.gtmProducts,
    params,
    'GET',
    'payload',
  )) as { [designId: string]: object };

  dispatch(actions.gtmFormatsSet({ data }));
};
