import cn from 'classnames';
import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Translation } from 'react-i18next';
import memoize from 'memoize-one';
import isDeepEqual from 'lodash.isequal';
import merge from 'lodash.merge';
import queryString from 'query-string';
import get from 'idx';
import { History } from 'history';
import { push } from 'react-router-redux';
import Helmet from 'react-helmet';
import LocaleToCurrency from 'locale-currency';

import Button, { ButtonState } from '../common/Button';
import Text from '../common/Text';
import { Expand, ExpandStyles, Fade, FadeStyles } from '../common/Animation';
import Price from './Price';
import { isArtistic, isCalendar, isFrame, isNonPersonalisedProduct, isPoster } from '../util/productTypeIdsHelper';
import { Design, DesignFamily } from '@categoryProduct/typings';

import ReactPlaceholder from 'react-placeholder';

import Glider from '../Glider';
import SimilarProducts from './SimilarProducts';

import SelectWrapper from '../Select';
import { setLocation, setLocationSearch, setParamInLocation } from '@categoryProduct/util/browserLocation';
import formatPrice from '@categoryProduct/util/formatPrice';
import { priceOperations, priceSelectors } from '@categoryProduct/store/price';
import { categoryOperations, categorySelectors } from '@categoryProduct/store/category';
import findAndSetFrameAlternatives from '@categoryProduct/store/category/findAndSetFrameAlternatives';
import { uiSelectors } from '@categoryProduct/store/ui';
import apiTypings from '@categoryProduct/api/optimalprint-sdk';
import { accumulateProductPrice, isFrameAvailable, selectPriceObjectOfTheUpsell, shouldApplyFrame } from './helpers';
import styles from './Product.css';
import iconStyles from '../commonStyles/icon.css';
import priceStyles from './Price/styles.css';
import './Product.raw.css';
import CompareCardsModal from './CompareCardStock';
import global from '@categoryProduct/util/global';

import 'react-placeholder/lib/reactPlaceholder.css';
import LazyImage from '../LazyImage';
import apiRequest from '@categoryProduct/api/apiRequest';
import endpoints from '@categoryProduct/api/endpoints';
import PixleeWidget from './PixleeWidget';
import Accordion from './Accordion';
import shouldUseNewPreviews from '@categoryProduct/util/shouldUseNewPreviews';
import getImagePreviews from '../util/getImagePreviews';
import { wallCalendarWithoutPlannerIds } from '@categoryProduct/api/productTypes';
import * as CalendarStartDateHelper from '../util/calendarStartDateHelper';
import {
  incrementBasketItemsCount,
  setBasketItemsCount,
  setFavouritesCount,
} from '@categoryProduct/util/updateBadgesCount';
import getImagesRootUrl from '@categoryProduct/util/getImagesRootUrl';
import { isUndefined } from 'lodash';
import isSmallScreen from '@categoryProduct/component/util/isSmallScreen';
import { getBackUrlFromScrollManagerData } from '@categoryProduct/component/util/ScrollManagerHelper';
import FavouriteIcon from '@categoryProduct/component/Product/FavouriteIcon';
import { sortByOptions } from '@categoryProduct/component/Category';
import TrimList from './UpsellLists/TrimList';
import SpotFinishList from './UpsellLists/SpotFinishList';
import {
  getSpotFinishesForSelectedTrimId,
  getTrimsForSelectedSpotFinishId,
  isOnlyDefaultUpsellValue,
} from '@categoryProduct/component/util/TrimFoilHelper';
import FramesList from '@categoryProduct/component/Product/FramesList';
import FramesTypeList from '@categoryProduct/component/Product/FramesTypeList';
import PreviewWithFrame from '@categoryProduct/component/Product/PreviewWithFrame';
import FrameSizeSelector from '@categoryProduct/component/Product/FrameSizeSelector';
import FrameSizeGuide from './FrameSizeGuide';
import ProductPlaceholder from './ProductPlaceholder';

interface StateProps {
  state: any;
  categories: { [s: string]: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo };
  category: apiTypings.AppBundle.Api.Entity.Category.V1.CategoryInfo;
  family?: DesignFamily;
  paperFormats: apiTypings.AppBundle.Api.Entity.PaperFormat.V1.PaperFormat[];
  imagesData?: { [s: string]: string };
  similarProducts?: apiTypings.AppBundle.Api.Entity.Design.V1.SimilarProduct[];
  getShipmentPrices: (
    id: string,
  ) => apiTypings.ShipmentBundle.Entity.API.ShipmentPrice.ShipmentPriceListV2Response.ProductShipmentPriceList[] | undefined;
  priceFormat: string;
  lastCategoryHistory?: History;
  getPrices: (
    productId: number,
  ) => apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[] | undefined;
  getUpsells: (productId: number) => apiTypings.AppBundle.Api.Entity.Product.V1.ProductUpsellItems | undefined;
  getFrames: (categoryId: number, productId: number) => any;
}

interface OwnProps extends RouteComponentProps<any> {
}

interface Props extends OwnProps, StateProps {
  dispatch: Dispatch;
}

interface State {
  selectedDesign: Design;
  selectedQuantity: number;
  selectedColor: string;
  selectedSubstrateId?: number;
  selectedSpotFinishId?: number;
  selectedFrameId?: number;
  selectedTrimId?: number;
  selectedCalendarStartDate?: Date;
  isCompareCardsModalOpen: boolean;
  frameSizeGuideModalOpen: boolean;
  selectedPreview: number;
  lastLoadedPreviews: { [i: number]: string };
  isFrameChanged: boolean;
}

interface FiltersParams {
  formats: {
    [formatId: string]: {
      colors: string[];
    };
  } | never[];
  paperFormats: apiTypings.AppBundle.Api.Entity.PaperFormat.V1.PaperFormat[];
  productTypeId: number;
  selectedColor: string;
}

interface ColorsParams {
  formats: {
    [formatId: string]: {
      colors: string[];
    };
  } | never[];
}

class Product extends React.Component<OwnProps, State> {
  static getInitialData = (
    {
      dispatch,
      state,
      match: {
        params: { category_id, design_id },
      },
    }: Props,
  ) => ([
    categoryOperations.fetchPaperFormats(dispatch, undefined, undefined),
    categoryOperations.fetchCategory(dispatch, state, category_id),
    categoryOperations.fetchSimilarProducts(dispatch, category_id, design_id),
    categoryOperations.fetchDesignFamily(dispatch, state, category_id, design_id)
      .then(async (family: DesignFamily) => {
        const currentDesign = family.designs.find(d => d.designId === design_id);

        if (!currentDesign) {
          return;
        }

        // fetch pricing
        const productIds =
          family.designs.map(design => design.productId)
            .reduce((acc, id) => (acc.indexOf(id) > -1 ? acc : [ ...acc, id ]), [] as number[]);

        const productTypeIds = family.designs.map(design => design.productTypeId)
          .reduce((acc, id) => (acc.indexOf(id) > -1 ? acc : [ ...acc, id ]), [] as number[]);

        // fetch images for base64
        // const images = Product.getImagePreviews(currentDesign);

        await Promise.all([
          // categoryOperations.fetchBase64Images(dispatch, images.slice(0, 1)),
          categoryOperations.fetchFrameFormats(dispatch, undefined, design_id, category_id),
          categoryOperations.fetchSimilarProducts(dispatch, category_id, design_id),
        ]);
      }),
  ]);
  newFrameData = {
    designId: 0,
    categoryId: 0,
    productId: 0
  };
  state = {
    selectedDesign: {} as Design,
    selectedQuantity: 0,
    selectedColor: '',
    selectedSubstrateId: 0,
    selectedSpotFinishId: 0,
    selectedTrimId: 0,
    selectedFrameId: -1,
    isCompareCardsModalOpen: false,
    frameSizeGuideModalOpen: false,
    selectedPreview: 0,
    selectedCalendarStartDate: undefined,
    lastLoadedPreviews: {},
  };

  seeAllButtonRestoredLink: string = '';

  static getDerivedStateFromProps(props: Props, state: State) {
    const {
      match: { params: { design_id, category_id } },
      location: { search },
      family,
      getFrames,
    } = props;

    if (!family) {
      return null;
    }

    const queries = queryString.parse(search);
    const selectedDesign = state.selectedDesign.id
      ? state.selectedDesign
      : family.designs.find(design => design.designId === design_id) || family.designs[0];
    const selectedQuantity = state.selectedQuantity || queries.q || 0;
    const selectedTrimId = Number(state.selectedTrimId || queries.trim || 0);
    const selectedFrameId = Number(((state.selectedFrameId || state.selectedFrameId === 0) && state.selectedFrameId !== -1)
      ? state.selectedFrameId
      : (queries.posterBindingProductId || -1)
    );
    const selectedSpotFinishId = Number(state.selectedSpotFinishId || queries.spot || 0);
    const frames = getFrames(category_id, selectedDesign.productId);
    const defaultFrameId = frames && frames.length ? 0 : -1;
    const isFrameAvailableForProduct = isFrameAvailable(frames, selectedFrameId);

    return {
      selectedQuantity,
      selectedTrimId,
      selectedFrameId: isFrameAvailableForProduct ? selectedFrameId : defaultFrameId,
      selectedSpotFinishId,
      selectedDesign,
    } as Partial<State>;
  }

  componentWillUnmount(): void {
    const {
      dispatch,
      getPrices
    } = this.props;

    const { selectedDesign: { productTypeId } } = this.state;

    if(this.newFrameData.categoryId) {
      categoryOperations.fetchCategory(dispatch, undefined, this.newFrameData.categoryId, false);
      categoryOperations.fetchDesignFamily(dispatch, undefined, this.newFrameData.categoryId, this.newFrameData.designId);
      categoryOperations.fetchFrameFormats(dispatch, undefined, this.newFrameData.designId, this.newFrameData.categoryId);

      if (!getPrices(this.newFrameData.productId)) {
        priceOperations.fetchPrices(dispatch, this.newFrameData.categoryId, [this.newFrameData.productId], [this.newFrameData.designId], isArtistic(productTypeId));
      }
    }
  }

  componentDidMount() {
    this.seeAllButtonRestoredLink = getBackUrlFromScrollManagerData();

    const {
      match: {
        params: { category_id, design_id },
      },
      family,
      similarProducts,
      getPrices,
      getShipmentPrices,
      dispatch,
      lastCategoryHistory,
      state,
      history,
      getUpsells,
    } = this.props;

    const { selectedDesign: { productId, designId, productTypeId } } = this.state;

    if (!family) {
      return;
    }

    const shipmentPrices = getShipmentPrices(designId);

    categoryOperations.fetchCategories(dispatch, state);
    categoryOperations.fetchCategory(dispatch, state, category_id, false);
    categoryOperations.fetchPaperFormats(dispatch, state, undefined);
    categoryOperations.fetchDesignFamily(dispatch, state, category_id, design_id);
    categoryOperations.fetchFrameFormats(dispatch, undefined, design_id, category_id);

    categoryOperations.favouritesFetch(dispatch, state).then(favs => {
      setFavouritesCount(favs.length);
    });

    categoryOperations.cartItemsCountFetch(dispatch, state).then(count => {
      typeof count === 'number' && setBasketItemsCount(count);
    });

    !similarProducts && categoryOperations.fetchSimilarProducts(dispatch, category_id, design_id);

    if (!getPrices(productId)) {
      const productIds = family.designs
        .map(design => design.productId)
        .reduce((acc, id) => (acc.indexOf(id) > -1 ? acc : [ ...acc, id ]), [] as number[]);

      const designIds = family.designs
        .map(design => design.designId)
        .reduce((acc, id) => (acc.indexOf(id) > -1 ? acc : [ ...acc, id ]), [] as string[]);

      priceOperations.fetchPrices(dispatch, category_id, productIds, designIds, isArtistic(productTypeId));
    }

    if (!shipmentPrices) {
      priceOperations.fetchShipmentPrices(dispatch, state, category_id, productId, design_id);
    }

    const currentSortBy = (lastCategoryHistory && queryString.parse(lastCategoryHistory.location.search)['sort_by']);
    const currentSortOption = sortByOptions.find(o => o.urlValue === currentSortBy);
    const currentSortNumberValue = currentSortOption && currentSortOption.value as number || sortByOptions[0].value as number;

    // prefetch all current category designs
    categoryOperations.fetchDesignFamiliesAndFilters(dispatch, state, category_id, currentSortNumberValue, undefined, undefined, undefined, history);

    window.scrollTo(0, 0);
    this.sendProductViewEvent();

    // set initial values
    const upsells = getUpsells(productId);

    const {
      spotFinish,
      substrate,
      trim,
      frame,
    } = this.getUpsellObjects(upsells);

    this.setState({
      selectedSubstrateId: (substrate && substrate.id) || 0,
      selectedSpotFinishId: (spotFinish && spotFinish.id) || 0,
      selectedTrimId: (trim && trim.id) || 0,
      selectedFrameId: frame && (frame.id || frame.id === 0) ? frame.id : -1,
    });
  }

  changeFrame = (designUrl: string, encPublicDesignId: string, categoryId: number, productId: number) => {
    this.newFrameData = {designId:encPublicDesignId, categoryId, productId };
    const { history } = this.props;
    history.push(designUrl);
  }

  changeFrameSize = (sizeOption: {designUrl: string}) => {
    const { designUrl, encPublicDesignId, categoryId, productId } = sizeOption;
    this.newFrameData = {designId:encPublicDesignId, categoryId, productId};

    const { dispatch, history } = this.props;
    dispatch(findAndSetFrameAlternatives(encPublicDesignId) as any);
    history.push(designUrl);
  }

  sendProductViewEvent = () => {
    // if (global('env') !== 'live') {
    //   return;
    // }

    const { match: { params: { design_id, category_id } }, family } = this.props;
    if (family && family.designs) {
      const currentDesign = family.designs.find(item => item.designId === design_id);

      const sliceIndex = (family.designs.indexOf(currentDesign!) || 1) - 1;
      const designIds = family.designs.slice(sliceIndex, sliceIndex + 3).map(item => item.designId);

      if (designIds.length) {
        apiRequest(endpoints.gtmProducts, {
          categoryId: category_id,
          designIds: designIds.join(','),
        }, 'GET', 'payload')
          .then((answer: any) => {
            (window as any).dataLayer && (window as any).dataLayer.push({
              event: 'productView',
              ecommerce: {
                detail: {
                  actionField: {
                    list: 'Category Page',
                  },
                  products: [ answer[design_id] ],
                },
              },
            });
            const designEnc = designIds.map(item => (global('locale') + item).replace(/[^A-Za-z0-9 ]/, ''));
            (window as any).dataLayer && (window as any).dataLayer.push({
              designEnc,
              event: 'designData',
              design: designIds,
              designEncDoubleClick: designEnc.join('|'),
            });
          });
      }
    }
  };

  // select design by format and color
  selectDesignByFormatAndColor = (format: number, color: string) => {
    const {
      family,
      match: { params: { category_id } },
      dispatch,
      history,
      state,
    } = this.props;

    if (!family) {
      return;
    }

    const { selectedDesign: { productTypeId } } = this.state;

    let design = family.designs.find(d =>
      d.paperFormatId === format
      && d.color === color
      && d.productTypeId === productTypeId,
    );

    if (!design) {
      design = family.designs.find(design => design.paperFormatId === format && design.productTypeId === productTypeId);
    }

    if (!design) {
      design = family.designs.find(design => design.paperFormatId === format);
    }

    if (design && design.url) {
      const { selectedTrimId, selectedSpotFinishId } = this.state;
      const { trims, spotFinishes } = design;

      setLocation(history, design.url);
      setLocationSearch(history, '');
      // add proper upsell to hash for preserve selection on page reload
      if (trims && trims.length >= 2 && selectedTrimId > 1) {
        setParamInLocation(history, 'trim', String(selectedTrimId), true);
      } else if (spotFinishes && spotFinishes.length >= 2 && selectedSpotFinishId > 1) {
        setParamInLocation(history, 'spot', String(selectedSpotFinishId), true);
      }

      this.setState((state: State) => ({
        selectedDesign: design!,
        selectedColor: state.selectedColor || design!.color || '',
        selectedPreview: 0,
        // reset values
        selectedTrimId: (!trims || trims.length < 2) ? 1 : state.selectedTrimId,
        selectedSpotFinishId: (!spotFinishes || spotFinishes.length < 2) ? 1 : state.selectedSpotFinishId,
        selectedFrameId: 0,
      }));

      priceOperations.fetchShipmentPrices(dispatch, state, category_id, design.productId, design.designId);
    }
  };

  selectProducts = () => {
    const {
      family,
    } = this.props;

    if (!family) {
      return null;
    }

    const { selectedDesign: { categoryIds } } = this.state;
    const { designs } = family;

    const initialData = {
      mainProducts: [],
      matchingProducts: [],
    } as {
      [s: string]: Design[];
    };

    return designs.reduce((acc, design) => {
      design.categoryIds && design.categoryIds.indexOf(categoryIds[0]) >= 0
        ? acc.mainProducts.push(design)
        : acc.matchingProducts.push(design);

      return acc;
    }, initialData);
  };

  // determine quantity based on current selected price
  selectQuantity = memoize((
    currentQuantity: number,
    prices?: apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[],
  ) => {
    if (!prices || !prices.length) {
      return currentQuantity;
    }

    let selectedPrice;

    if (!currentQuantity) {
      selectedPrice = prices.find(p => p.d) || prices[0]; // use default or first
    } else {
      // select price within quantity range
      selectedPrice = prices.reduce((acc, price) => {
        if (price.q <= currentQuantity && price.q > acc.q) {
          return price;
        }

        return acc;
      }, prices[0]);
    }

    return selectedPrice.q;
  });

  // select valid substrate
  selectUpsell = memoize((
    upsells?: apiTypings.AppBundle.Api.Entity.Product.V1.IUpsellItem[],
    currentUpsell?: number,
    defaultUpsellId?: number,
  ) => {
    if (!upsells || !upsells.length) {
      return null;
    }

    // select with Id from state
    let upsell = upsells.find(upsell =>
      currentUpsell ?
        upsell.id === currentUpsell : // get selected upsell
        (defaultUpsellId || defaultUpsellId === 0) ? // get default
          upsell.id === defaultUpsellId : // default by provided id if exists
          !!upsell.isDefaultSelected, // default upsell
    );

    // if no default select first
    if (!upsell) {
      upsell = upsells[0];
    }

    return upsell;
  });

  getEnvelopesId =
    (upsells: apiTypings.AppBundle.Api.Entity.Product.V1.ProductUpsellItems | undefined): number =>
      upsells
      && upsells.envelopes.length > 0
      && (upsells.envelopes.find((envelope: apiTypings.AppBundle.Api.Entity.Product.V1.IUpsellItem) => !!(envelope.isDefaultSelected))
      || upsells.envelopes[0]).id
      || 0; // default or first

  calculateTotalPrice = memoize((
    selectedQuantity: number,
    selectedSubstrateId: number,
    selectedSpotFinishId: number,
    selectedTrimId: number,
    selectedFrameId: number,
    prices?: apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[],
    upsells?: apiTypings.AppBundle.Api.Entity.Product.V1.ProductUpsellItems,
  ): apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice | null => {

    if (!prices || !upsells) {
      return null;
    }

    const price = prices.find((priceObj) => priceObj.q === selectedQuantity);
    if (!price) {
      return null;
    }

    const envelopeId: number = this.getEnvelopesId(upsells);
    // add prices from selected upsells
    const upsellPrices = [
      selectPriceObjectOfTheUpsell(upsells.substrates, selectedSubstrateId, selectedQuantity),
      selectPriceObjectOfTheUpsell(upsells.trims, selectedTrimId, selectedQuantity),
      selectPriceObjectOfTheUpsell(upsells.spotFinishes, selectedSpotFinishId, selectedQuantity),
      selectPriceObjectOfTheUpsell(upsells.envelopes, envelopeId, selectedQuantity),
      selectPriceObjectOfTheUpsell(upsells.posterBindings, selectedFrameId, selectedQuantity),
    ] as apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[];

    return upsellPrices.reduce(accumulateProductPrice, Object.assign({}, price)) as apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice;
  });

  calculateTotalDropdownPrices = memoize((
    selectedQuantity: number,
    selectedSpotFinishId: number,
    selectedTrimId: number,
    selectedFrameId: number,
    prices?: apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[],
    upsells?: apiTypings.AppBundle.Api.Entity.Product.V1.ProductUpsellItems,
  ): apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[] => {

    if (!prices || !upsells) {
      return [];
    }

    return prices.reduce(
      (acc, price) => {
        const envelopeId = upsells && upsells.envelopes.length > 0 && (upsells.envelopes.find((envelope) => !!(envelope.isDefaultSelected)) || upsells.envelopes[0]).id || 0; // default or first

        // add prices from selected upsells
        const upsellPrices = [
          selectPriceObjectOfTheUpsell(upsells.trims, selectedTrimId, price.q),
          selectPriceObjectOfTheUpsell(upsells.spotFinishes, selectedSpotFinishId, price.q),
          selectPriceObjectOfTheUpsell(upsells.envelopes, envelopeId, price.q),
          selectPriceObjectOfTheUpsell(upsells.posterBindings, selectedFrameId, price.q),
        ] as apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[];

        const priceObject = upsellPrices.reduce(accumulateProductPrice, Object.assign({}, price)) as apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice;

        if (!priceObject) {
          return acc;
        }

        return [ ...acc, priceObject ];
      },
      [] as apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice[],
    );
  });

  selectShipmentPrice = memoize((
    selectedQuantity: number,
    selectedSubstrateId: number,
    selectedSpotFinishId: number,
    selectedTrimId: number,
    selectedFrameId: number,
    productId: number,
    shipmentPrices?: apiTypings.ShipmentBundle.Entity.API.ShipmentPrice.ShipmentPriceListV2Response.ProductShipmentPriceList[],
  ): apiTypings.ShipmentBundle.Entity.API.ShipmentPrice.ShipmentPriceListV2Response.ShipmentPrice | null => {

    if (!shipmentPrices) {
      return null;
    }

    const shipmentPricesObj = shipmentPrices.find(
      (obj) =>
        obj.spotFinishId === selectedSpotFinishId
        && obj.trimId === selectedTrimId
        && obj.substrateId === selectedSubstrateId
    );

    if (!shipmentPricesObj) {
      return null;
    }

    return shipmentPricesObj.prices.find((priceObj) => priceObj.q === selectedQuantity) || null;
  }, isDeepEqual);

  onImageLoad = (e: any, imageUrl: string, index: number) => {
    e.target.src === imageUrl && this.setState((state) => ({
      lastLoadedPreviews: merge(state.lastLoadedPreviews, {
        [index]: imageUrl,
      }),
    }));
  };

  renderFormats = (params: FiltersParams) => {
    const {
      formats,
      paperFormats,
      productTypeId,
      selectedColor,
    } = params;

    const { selectedDesign: { paperFormatId: selectedPaperFormatId } } = this.state;

    if (!formats || Object.keys(formats).length < 1) {
      return null;
    }

    const baseImageUrl = getImagesRootUrl();

    const renderedFormats = Object.keys(formats).map(formatKey => {
      let formatData = paperFormats.find(f => f.productTypeId === productTypeId && f.paperFormatId === parseInt(formatKey, 10));
      if (!formatData) {
        formatData = paperFormats.find(f => f.paperFormatId === parseInt(formatKey, 10));
      }
      if (!formatData) {
        console.error(`paperFormat ${formatKey} for productTypeId ${productTypeId} not found!`);
      }
      return (
        <div
          id={`formatButton_${formatData ? formatData.systemName : formatKey}`}
          className={cn(styles.format, {
            [styles.active]: parseInt(formatKey, 10) === selectedPaperFormatId,
          })}
          key={formatKey}
          onClick={() =>
            this.selectDesignByFormatAndColor(parseInt(formatKey, 10), selectedColor)
          }
        >
          {formatData
            ? (
              <img
                className={styles.squareIcon}
                src={baseImageUrl + formatData.icon}
                title={formatData.name}
                alt=''
              />
            )
            : formatKey
          }
        </div>
      );
    });

    const selectedFormat = paperFormats.find(f => f.productTypeId === productTypeId && f.paperFormatId === selectedPaperFormatId);

    return (
      <Translation>
        {t => (
          <div id='format-box' className={styles.infoWrapper}>
            <Text bold darkBlue className={styles.infoTitle}>{t('txt_format')}:</Text>
            <div className={styles.infoValuesWrapper}>
              <Text darkBlue className={styles.formatsText}>{selectedFormat && selectedFormat.name}</Text>
              <div className={styles.formatContainer}>
                {renderedFormats}
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  };

  renderColors = (params: ColorsParams) => {
    const { formats } = params;
    const {
      selectedDesign: {
        paperFormatId: selectedPaperFormatId,
        color: designColor,
      },
    } = this.state;
    const selectedColors = get(formats, _ => _[selectedPaperFormatId].colors) as string[] | undefined;
    if (!selectedColors || selectedColors.length < 2) {
      return;
    }

    // destruction to not modify original array
    const colors = [ ...selectedColors ].sort().map(
      (color: string) => (
        <div
          id={`color_Button_${color}`}
          className={cn(styles.colorWrapper, {
            [styles.active]: color === designColor,
          })}
          key={color}
          onClick={() => {
            this.selectDesignByFormatAndColor(selectedPaperFormatId, color);
          }}
        >
          <div
            className={cn(styles.color, {
              [styles.colorActive]: color === designColor,
            })}
            style={{ background: color }}
          />
        </div>
      ),
    );

    return (
      <Translation>
        {t => (
          <div id='color_box' className={styles.infoWrapper}>
            <Text bold darkBlue className={styles.infoTitle}>{t('txt_mobile_color')}:</Text>
            <div className={styles.infoValuesWrapper}>
              <div className={styles.formatContainer} style={{ marginTop: 0 }}>
                {colors}
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  };

  getUpsellObjects = (
    upsells: apiTypings.AppBundle.Api.Entity.Product.V1.ProductUpsellItems | undefined
  ) => {
    const {
      selectedSpotFinishId,
      selectedSubstrateId,
      selectedTrimId,
      selectedFrameId,
      selectedDesign,
    } = this.state;
    const {
      spotFinishId: _spotFinishId,
      spotFinishes,
      trimId: _trimId,
      trims,
      upsellOptions,
    } = selectedDesign;

    const trimIdFromUrl = parseInt(global('trim'), 10);
    const foilIdFromUrl = parseInt(global('spot'), 10);
    const frameIdFromUrl = parseInt(global('posterBindingProductId'), 10);

    let finalSpotFinishId = selectedSpotFinishId || foilIdFromUrl;
    let finalTrimId = selectedTrimId || trimIdFromUrl;
    const finalFrameId = selectedFrameId !== -1 ? selectedFrameId : frameIdFromUrl;

    // URL parameter if in priority - reset value if not supported via API's upsell options combinations
    if (trimIdFromUrl > 1) {
      const availableSpotFinishes = getSpotFinishesForSelectedTrimId(upsellOptions, finalTrimId);
      const isCombinationAvailable = !isOnlyDefaultUpsellValue(availableSpotFinishes);

      if (!isCombinationAvailable) {
        finalSpotFinishId = 1;
      }
    }

    if (foilIdFromUrl > 1) {
      const trimsAvailableForSelectedSpot = getTrimsForSelectedSpotFinishId(upsellOptions, finalSpotFinishId);
      const isCombinationAvailable = !isOnlyDefaultUpsellValue(trimsAvailableForSelectedSpot);

      if (!isCombinationAvailable) {
        finalTrimId = 1;
      }
    }

    const spotFinish = upsells && this.selectUpsell(upsells.spotFinishes.filter(sf => spotFinishes.includes(sf.id)), finalSpotFinishId, _spotFinishId);
    const isSpotFinishApplied = Boolean(spotFinish && spotFinish.id > 1);
    // in case of spot finish selected -> set silk paper to be selected
    const substrate = upsells && this.selectUpsell(upsells.substrates, selectedSubstrateId, isSpotFinishApplied ? 1 : undefined);
    const trim = upsells && this.selectUpsell(upsells.trims.filter(t => trims.includes(t.id)), finalTrimId, _trimId);
    const frameObj = upsells && this.selectUpsell(upsells.posterBindings, finalFrameId, 0);

    return {
      spotFinish,
      substrate,
      trim,
      frame: frameObj,
    };
  };

  renderPrice = (price?: number) => {
    const { priceFormat } = this.props;
    return (price || price === 0) && priceFormat ? formatPrice(price, priceFormat) : undefined;
  };

  getDiscountPrice = (
    t: (key: string) => string,
    isDiscount: boolean,
    price?: apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice | null,
  ) => {
    if (!price) {
      return null;
    }

    const discountedPrice = isDiscount && price && (
      <span className={priceStyles.red}>
        {price.ip > 0 ? `${this.renderPrice(price.ip)}` : t('txt_free')}
      </span>
    );

    return (
      <React.Fragment>
        {discountedPrice}
        {price.ip > 0 && <span> / {t('txt_cart_item')}</span>}
      </React.Fragment>
    );
  };

  onTrimOptionClick = (id: number) => {
    const { history } = this.props;
    setParamInLocation(history, 'trim', `${id}`, true);
    this.setState({ selectedTrimId: id });
  };

  onSpotFinishOptionClick = (id: number) => {
    const { history } = this.props;
    setParamInLocation(history, 'spot', `${id}`, true);
    this.setState((state) => ({
      selectedSpotFinishId: id,
      selectedSubstrateId: id > 1 ? 1 : state.selectedSubstrateId,
    }));
  };

  onFrameOptionClick = (id: number) => {
    const { history } = this.props;
    setParamInLocation(history, 'posterBindingProductId', `${id}`, true);
    this.setState({ selectedFrameId: id });
  };

  render = () => {
    const {
      categories,
      category,
      paperFormats,
      similarProducts,
      priceFormat,
      getPrices,
      getUpsells,
      getShipmentPrices,
      location: {
        search,
        pathname,
      },
      lastCategoryHistory,
      match: { params: { category_id } },
    } = this.props;

    const {
      selectedDesign,
      selectedQuantity: _quantity,
      selectedColor,
      isCompareCardsModalOpen,
      frameSizeGuideModalOpen,
      lastLoadedPreviews,
      selectedPreview,
      selectedCalendarStartDate,
      selectedSpotFinishId,
      selectedFrameId,
    } = this.state as State;

    if (!selectedDesign.id || !category) {
      return <ProductPlaceholder />;
    }

    const shipmentPrices = getShipmentPrices(selectedDesign.designId);
    const urlQueries = queryString.parse(search);
    const locale = global('locale');

    const useTrim = !isUndefined(global('ab_trim')) ? global('ab_trim') : true;
    const useFoil = !isUndefined(global('ab_spot-finish')) ? global('ab_spot-finish') : true;

    const {
      url: categoryUrl,
      h1: categoryTitle,
    } = category;

    const {
      designAuthorName,
      designName,
      designId,
      productId,
      productTypeId,
      color: designColor,
      isFolded,
      designDescriptions,
      familyId,
    } = selectedDesign;

    const products = this.selectProducts();
    if (!products) {
      return null;
    }

    const { mainProducts: designs, matchingProducts } = products;

    const artistic = isArtistic(productTypeId);
    const nonPersonalised = isNonPersonalisedProduct(productTypeId);
    const useNewImages = !artistic && shouldUseNewPreviews(categories, category.categoryId);

    /**
     * Computed values
     */
    const formats = designs ? designs.reduce(
      (acc, design) => {
        const old = acc[design.paperFormatId] || {
          colors: [],
        };

        // prevent double colors
        if (old.colors.indexOf(design.color) >= 0) {
          return acc;
        }

        acc[design.paperFormatId] = {
          ...old,
          colors: [ ...old.colors, design.color ],
        };
        return acc;
      },
      {} as {
        [formatId: string]: {
          colors: string[];
        };
      },
    ) : {};

    const prices = getPrices(productId);
    const upsells = getUpsells(productId);
    const quantity = this.selectQuantity(_quantity, prices);

    const {
      spotFinish,
      substrate,
      trim,
      frame,
    } = this.getUpsellObjects(upsells);

    const substrateId = substrate ? substrate.id : 1;
    const spotFinishId = useFoil && spotFinish ? spotFinish.id : 1;
    const trimId = useTrim && trim ? trim.id : 1;
    const frameId = frame ? frame.id : 0;

    const frames = this.props.getFrames(category_id, selectedDesign.productId);
    const productAndFrameCombinationKey = () => {
      const noFrameOptions = frames.length < 2;
      const frameSelected = frameId > 0;
      if (noFrameOptions) {
        return nonPersonalised ? 'txt_frame_not_included_design_poster' : 'txt_frame_not_included_personalised_poster';
      }
      if (frameSelected) {
        return nonPersonalised ? 'txt_frame_included_design_poster' : 'txt_frame_included_personalised_poster';
      }
      return nonPersonalised ? 'txt_frame_can_include_design_poster' : 'txt_frame_can_include_personalised_poster';
    };

    // get image previews
    const images = getImagePreviews(categories, category.categoryId, selectedDesign, spotFinishId, trimId);
//
    // calculate total price
    const subTotalPrice = this.calculateTotalPrice(
      quantity,
      substrateId,
      spotFinishId,
      trimId,
      frameId,
      prices,
      upsells,
    );

    const subTotalDropdownPrices = this.calculateTotalDropdownPrices(
      quantity,
      spotFinishId,
      trimId,
      frameId,
      prices,
      upsells,
    );

    const shipmentPrice = this.selectShipmentPrice(
      quantity,
      substrateId,
      spotFinishId,
      trimId,
      frameId,
      productId,
      shipmentPrices,
    );

    /**
     * Rendering Helpers
     */

    const renderPrice = this.renderPrice;

    const renderQuantitySelector = () => {
      const options = subTotalDropdownPrices.map(price => ({
        price,
        value: price.q,
        label: `${price.q} item${price.q > 1 ? 's' : ''}`,
        subLabel: formatOptionSubLabel(price),
      }));
      const value = options.find(op => op.value === quantity) as SelectType;
      return (
        subTotalDropdownPrices.length ? (
          <Translation>
            {t =>
              <div id='quantity_box' className={styles.infoWrapper}>
                <Text bold darkBlue className={styles.infoTitle}>{t('title_quantity_details_pla_page')}:</Text>
                <div className={styles.infoValuesWrapper}>
                  <SelectWrapper
                    value={value}
                    onChange={(e: any) => this.setState({ selectedQuantity: parseInt(e.value, 10) })}
                    options={options}
                  />
                </div>
              </div>
            }
          </Translation>
        ) : null
      );
    };

    const formatOptionSubLabel = (price: apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice) => {
      const isDiscount = price && (price.ip !== price.ipi);
      const regularPrice = () => (
        <span
          className={cn({
            [priceStyles.crossed]: isDiscount,
          })}
        >
          {renderPrice(price.ipi)}
        </span>
      );

      return (
        <Translation>{t => (
          <span>
            {
              price
                ? <span>{regularPrice()} {this.getDiscountPrice(t, isDiscount, price)}</span>
                : t('txt_included')
            }
          </span>
        )}</Translation>
      );
    };

    const renderSubstrates = () => {
      if (!upsells || !upsells.substrates || upsells.substrates.length < 2) {
        return null;
      }
      const isFoilSelected = Number(selectedSpotFinishId) > 1;

      const options = upsells.substrates.reduce((acc, substrate) => {
        const price = substrate.prices && substrate.prices.find(price => price.q === quantity);

        // foil can be printed only on silk paper
        if (isFoilSelected && substrate.id > 1) {
          return acc;
        }

        return [ ...acc, {
          price,
          label: substrate.name,
          value: substrate.id,
          subLabel: formatOptionSubLabel(price as apiTypings.AppBundle.Api.Entity.Product.V1.ProductPrice),
          upsellItem: substrate,
        } ];

      }, [] as SelectType[]);
      const value = options.find(op => op.value === substrateId) as SelectType;

      return (
        <Translation>
          {t => (
            <div id='substrate-box' className={styles.infoWrapper}>
              <Text bold darkBlue className={styles.infoTitle}>{t('txt_paper')}:</Text>
              <div className={styles.infoValuesWrapper}>
                <ReactPlaceholder ready={Boolean(value)}>
                  <SelectWrapper
                    value={value}
                    onChange={(e: any) => {
                      this.setState({
                        selectedSubstrateId: parseInt(e.value, 10),
                      });
                    }}
                    options={options}
                  />
                  {renderCompareCardsModal}
                </ReactPlaceholder>
              </div>
            </div>
          )}
        </Translation>
      );
    };

    const calendarStartDate = CalendarStartDateHelper.getStartDate(new Date());

    const renderCalendarStartDateSelector = () => {
      if (wallCalendarWithoutPlannerIds.indexOf(productTypeId) === -1) {
        return null;
      }

      const availableCalendarStartDates = CalendarStartDateHelper.getPossibleMonthsToStart(calendarStartDate);

      const options = availableCalendarStartDates.reduce((acc, date) => {
        const label = (
          <Translation>
            {t => `${t(`txt_date_format_month_full_${date.getMonth() + 1}`)} ${date.getFullYear()}`}
          </Translation>
        );

        return [ ...acc, {
          label,
          value: date,
        } ];

      }, [] as SelectType[]);

      let value = selectedCalendarStartDate && options.find(op => (op.value as Date).getTime() === selectedCalendarStartDate.getTime()) as SelectType;
      if (!value) value = options.find(op => (op.value as Date).getTime() === calendarStartDate.getTime()) as SelectType;

      return (
        <Translation>
          {t => (
            <div id='startdate-box' className={styles.infoWrapper}>
              <Text bold darkBlue className={styles.infoTitle}>{t('txt_start_month')}:</Text>
              <div className={styles.infoValuesWrapper}>
                <SelectWrapper
                  value={value as SelectType}
                  onChange={(e: any) => {
                    this.setState({
                      selectedCalendarStartDate: e.value,
                    });
                  }}
                  options={options}
                />
              </div>
            </div>
          )}
        </Translation>
      );
    };

    const renderImageThumbnails = () => {
      const {
        categories,
        category,
      } = this.props;

      const useNewPreviews = shouldUseNewPreviews(categories, category.categoryId);
      const thumbs = images && images.length && images.map((image, index) => {
        return shouldApplyFrame(frameId, productTypeId, useNewPreviews, index)
          ? (
            <PreviewWithFrame
              id={index}
              imageUrl={image}
              frameId={frameId}
              paperFormatId={selectedDesign.paperFormatId}
              productId={productId}
              categoryId={category_id}
              onClick={() => {
                this.setState({ selectedPreview: index });
              }}
            />
          )
          : (
            <LazyImage
              placeholder={lastLoadedPreviews[index] || image.replace('big', 'medium')}
              key={image}
              className={cn(styles.thumbnail, {
                [styles.isFoldedPreview]: isFolded,
                [styles.oldPreview]: !useNewImages,
                [styles.roundedCornersPreview]: trimId > 1,
              })}
              url={image}
              onLoad={this.onImageLoad}
              index={index}
            />
          );
      });

      if (!thumbs) {
        return null;
      }

      return (
        <Translation>
          {t =>
            <div className={styles.slidersWrap}>
              <div id='main-slider' className={styles.imagesSliderContainer}>
                <Glider
                  axis='horizontal'
                  showStatus={false}
                  showArrows={false}
                  showThumbs={false}
                  showIndicators={false}
                  swipeable
                  emulateTouch
                  onChange={(selectedPreview) => this.setState({ selectedPreview })}
                  selectedItemIndex={selectedPreview}
                >
                  {thumbs}
                </Glider>

                {useFoil && (
                  <Fade in={spotFinishId > 1}>
                    <span
                      className={cn(
                        styles.label,
                        styles.labelFoil,
                        styles[`spot_finish_color_${spotFinishId}`],
                        { [FadeStyles.exitDone]: spotFinishId <= 1 },
                      )}
                    >
                      {t('txt_with_foil_text')}
                    </span>
                  </Fade>
                )}
              </div>
              <div id={'thumbs-slider'} className={styles.imagesSliderContainer}>
                {images && (
                  <Glider
                    axis='horizontal'
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                    swipeable
                    perPage={5}
                    selectedItemIndex={selectedPreview}
                  >
                    {images.map((imgUrl, i) => (
                      <div
                        key={imgUrl}
                        className={cn(styles.thumbnailImg, {
                          [styles.active]: selectedPreview === i,
                        })}
                      >
                        {shouldApplyFrame(frameId, productTypeId, useNewPreviews, i)
                          ? (
                            <PreviewWithFrame
                              id={i}
                              imageUrl={imgUrl}
                              frameId={frameId}
                              productId={productId}
                              categoryId={category_id}
                              paperFormatId={selectedDesign.paperFormatId}
                              onClick={() => {
                                this.setState({ selectedPreview: i });
                              }}
                            />
                          )
                          : (
                            <img
                              id={`thumb_image_${i}`}
                              src={imgUrl}
                              alt=''
                              onClick={() => {
                                this.setState({ selectedPreview: i });
                              }}
                            />
                          )
                        }
                      </div>
                    ))}
                  </Glider>
                )}
              </div>
            </div>
          }
        </Translation>
      );
    };

    const renderCompareCardsModal = (
      <Translation>
        {t => (
          <div>
            <Fade in={Number(selectedSpotFinishId) <= 1}>
              <Text
                id='compareCardText'
                lightBlue
                className={cn(styles.compareLink, { [FadeStyles.exitDone]: Number(selectedSpotFinishId) > 1 })}
                onClick={() => this.setState({ isCompareCardsModalOpen: !isCompareCardsModalOpen })}
              >
                {t('txt_compare_card_stock')}
              </Text>
            </Fade>
            {upsells && (
              <CompareCardsModal
                isOpen={isCompareCardsModalOpen}
                paperTypes={upsells.substrates}
                selectedTypeId={substrateId}
                selectedQuantity={quantity}
                itemPrices={prices || []}
                onChange={(typeId) => {
                  this.setState({
                    isCompareCardsModalOpen: false,
                    selectedSubstrateId: typeId,
                  });
                }}
                priceFormat={priceFormat}
              />
            )}
          </div>
        )}
      </Translation>
    );

    const renderPersonalizeButton = () => {
      const getSelectedPaperType = () => {
        switch (substrateId) {
          case 2:
            return 'premium';
          case 3:
            return 'coating';
          default:
            return 'regular';
        }
      };

      const calendar = isCalendar(productTypeId);

      const defaultPrice = prices && prices.find(price => price.d);
      const defaultQuantity = defaultPrice ? defaultPrice.q : quantity;

      const baseEditEndpoint = calendar ? 'product-designer' : 'edit-design';

      const queries = {
        quantity,
        cid: category.categoryId,
        paper_type: getSelectedPaperType(),
        default_quantity: defaultQuantity,
      };

      const designIdKey = calendar ? 'public_design_id' : 'design_id';
      queries[designIdKey] = selectedDesign.designId;

      if (spotFinishId) {
        queries['spotFinishId'] = spotFinishId;
      }

      if (trimId) {
        queries['trimId'] = trimId;
      }

      if (frameId) {
        queries['posterBindingProductId'] = frameId;
      }

      if (calendar) {
        const date = selectedCalendarStartDate || calendarStartDate;
        queries['calendarStartDate'] = `${date.getFullYear()}-${date.getMonth() + 1}-01`;
      }

      if (urlQueries.isPla) {
        queries['isPla'] = 1;
      }

      const baseImageUrl = getImagesRootUrl();
      const isNotGermanLocale = locale.indexOf('de') === -1;
      const isTrimAndFoil = spotFinishId > 1 && trimId > 1;

      return (
        <Translation>
          {t => (
            <div className={styles.fullWidth}>
              {isNotGermanLocale && (
                <React.Fragment>
                  <Expand in={isTrimAndFoil}>
                    <div className={cn(styles.note, { [ExpandStyles.exitDone]: !isTrimAndFoil })}>
                      {t('txt_longer_foil_and_trim_delivery_note')}
                    </div>
                  </Expand>

                  <Expand in={spotFinishId > 1 && !isTrimAndFoil}>
                    <div className={cn(styles.note, { [ExpandStyles.exitDone]: isTrimAndFoil || spotFinishId <= 1 })}>
                      {t('txt_longer_foil_delivery_note')}
                    </div>
                  </Expand>

                  <Expand in={trimId > 1 && !isTrimAndFoil}>
                    <div className={cn(styles.note, { [ExpandStyles.exitDone]: isTrimAndFoil || trimId <= 1 })}>
                      {t('txt_longer_trim_delivery_note')}
                    </div>
                  </Expand>
                </React.Fragment>
              )}

              <a
                id='personalizeLink'
                href={`${baseImageUrl}/${baseEditEndpoint}?${queryString.stringify(queries)}`}
                className={styles.fullWidth}
              >
                <Button fullWidth backBtn>{t('txt_personalize')}</Button>
              </a>

              <Expand in={isPoster(productTypeId)}>
                {isPoster(productTypeId) ? <div className={cn(styles.note, styles.noItalic, styles.hideOnMobile, styles.noteActive, { [ExpandStyles.exitDone]: !isPoster(productTypeId) })} dangerouslySetInnerHTML={{ __html: t(productAndFrameCombinationKey()) }} /> : <div />}
              </Expand>
            </div>
          )}
        </Translation>
      );
    };

    const renderAddToBasketButton = () => {
      const [ btnState, setBtnState ] = React.useState(ButtonState.undefined);

      const onAddToBasket = async () => {
        try {
          setBtnState(ButtonState.loading);

          if (isFrame(productTypeId)) {
            await apiRequest(endpoints.customerProductAdd, {
              quantity,
              categoryId: category.categoryId,
              productId: selectedDesign.productId,
              includeAnalytics: true,
            }, 'POST', 'data');
          } else {
            // non frame product
            const designAddParams: any = {
              quantity,
              encPublicDesignId: designId,
              categoryId: category.categoryId,
              includeAnalytics: true,
            };
            if (this.state.selectedFrameId >= 0) {
              designAddParams.posterBindingProductId = this.state.selectedFrameId;
            }
            const addToBasketResult = await apiRequest(endpoints.cartPublicDesignAdd, designAddParams,
              'POST', 'data') as apiTypings.AppBundle.Api.Response.Cart.V1.IItemAddV1Response; // TODO probably wrong type

            // connect frame to the artistic poster
            if (isArtistic(productTypeId) && this.state.selectedFrameId > 0) {
              await apiRequest(endpoints.customerProductAdd, {
                quantity,
                categoryId: category.categoryId,
                productId: this.state.selectedFrameId,
                bundleOwnerOrderItemId: addToBasketResult.orderItemId,
                includeAnalytics: true,
              }, 'POST', 'data');
            }
          }
          incrementBasketItemsCount();
          setBtnState(ButtonState.success);
          setTimeout(() => setBtnState(ButtonState.undefined), 5000);
        } catch (e) {
          setBtnState(ButtonState.failed);
          setTimeout(() => setBtnState(ButtonState.undefined), 5000);
        }
      };
      return (
        <Translation>
          {t => (
            <div className={styles.fullWidth}>
              <Button
                fullWidth
                backBtn
                onClick={onAddToBasket}
                state={btnState}
                errorMessage={t('txt_design_add_to_cart_failed')}
              >
                {t('txt_add_to_basket')}
              </Button>
              {isPoster(productTypeId) && (
                <div className={cn(styles.note, styles.noItalic, styles.hideOnMobile, styles.noteActive)} dangerouslySetInnerHTML={{ __html: t(productAndFrameCombinationKey()) }} />
              )}
            </div>
          )}
        </Translation>
      );
    };

    const renderMatchingProducts =
      () => {
        if (!matchingProducts || !matchingProducts.length) {
          return null;
        }
        const {
          categories,
          category,
        } = this.props;

        const useNewPreviews = shouldUseNewPreviews(categories, category.categoryId);
        const matchingProductsWithSimilarColorPreferablyObj = matchingProducts.reduce((acc, matchingProduct) => {
          // select the first with same ProductTypeId
          if (!acc[matchingProduct.productTypeId]) {
            acc[matchingProduct.productTypeId] = matchingProduct;
          }
          // if color matches overwrite the selected product
          if (matchingProduct.color === designColor) {
            acc[matchingProduct.productTypeId] = matchingProduct;
          }

          return acc;
        }, {});

        const matchingProductsWithSimilarColorPreferably = Object.values(matchingProductsWithSimilarColorPreferablyObj);

        return matchingProductsWithSimilarColorPreferably.length ? (
          <Translation>
            {t => (
              <div className={styles.matchingProductsContainer}>
                <Text header5 className={styles.subCategoryText}>{t('txt_shop_the_collection')}</Text>
                <div className={styles.alsoContainer}>
                  {
                    matchingProductsWithSimilarColorPreferably.map((matchingProduct: any) => {
                      const categoryName = categories && categories[matchingProduct.categoryId] && categories[matchingProduct.categoryId].h1;
                      const isNewPreview = !isArtistic(matchingProduct.productTypeId) && useNewPreviews;
                      const previewSize = isSmallScreen() && !isNewPreview ? 'small' : 'medium';
                      let image = matchingProduct
                        .thumbnail[0]
                        .replace('medium', previewSize);

                      if (useNewImages) {
                        image = image.replace('_3d', '_perspective');
                      } else {
                        image = image.replace('_perspective', '_3d');
                      }

                      return (
                        <a
                          id={`matchingProduct_link_${matchingProduct.id}`}
                          href={matchingProduct.url}
                          key={matchingProduct.id}
                          className={styles.productSmall}
                        >
                          <img className={styles.alsoItem} src={image} alt={categoryName} />
                          <Text className={styles.alsoItemLink}>{categoryName}</Text>
                        </a>
                      );
                    })
                  }
                </div>
              </div>
            )}
          </Translation>
        ) : null;
      };

    const renderDescription = () => designDescriptions && designDescriptions.length ? (
      <Translation>
        {t => (
          <div className={styles.sectionWrapper}>
            <Accordion title={t('txt_amp_product_details_title')}>
              {designDescriptions.map((
                desc: string,
                i: number,
              ) => <div key={i} dangerouslySetInnerHTML={{ __html: desc }} />)}
            </Accordion>
          </div>
        )}
      </Translation>
    ) : null;

    const renderReviews = () => {
      return null;
    };

    const renderFrameSizeGuide = () => {
      const toggleFrameSizeGuide = () => {
        this.setState({ frameSizeGuideModalOpen: !frameSizeGuideModalOpen });
      }
      return (
        <>
          <Translation>
            {t => (
              <div className={styles.frameSizeGuideButton} onClick={toggleFrameSizeGuide}>{t('txt_frame_size_guide')}</div>
            )}
          </Translation>
          {frameSizeGuideModalOpen && <FrameSizeGuide isOpen={frameSizeGuideModalOpen} onClick={toggleFrameSizeGuide}/>}
        </>
      );
    };

    const renderEcommerceData = (totalPrice: number) => {
      const currency = locale && LocaleToCurrency.getCurrency(locale);
      const baseUrl = getImagesRootUrl();
      const currentUrl = baseUrl + pathname;
      const name = (designName || categoryTitle) + (designAuthorName ? ` | by ${designAuthorName}` : '');
      const data = {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        'name': name,
        'image': (images && images[0]) || '',
        'sku': designId,
        'url': currentUrl,
        'brand': {
          'type': 'Brand',
          'name': 'Optimalprint',
          'logo': 'https://c.optimalprint.com/assets/images/general/online-printing-new-v1527241212.png',
        },
        'offers': {
          '@type': 'Offer',
          'priceCurrency': currency,
          'price': `${totalPrice || ''}`,
          'availability': 'http://schema.org/InStock',
        },
      };

      return (
        <Helmet
          script={[ {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(data),
          } ]}
        />
      );
    };

    const seeAllLink = this.seeAllButtonRestoredLink || (lastCategoryHistory
      ? `${lastCategoryHistory.location.pathname}${lastCategoryHistory.location.search}`
      : categoryUrl);

    const formatsParams: FiltersParams = {
      formats,
      paperFormats,
      productTypeId,
      selectedColor,
    };

    const colorsParams: ColorsParams = { formats };
    const isPla = urlQueries.isPla === '1' || global('isPla') === '1' || global('isPla') === true;
    const envelopeId: number = this.getEnvelopesId(upsells);
    const showEnvelopesNote = envelopeId > 1;

    const { selectedDesign: { paperFormatId: selectedPaperFormatId } } = this.state;

    return (
      <Translation>
        {t => (
          <div className={styles.container}>
            <Helmet title={`${designName || categoryTitle} | Optimalprint`} />
            {renderEcommerceData(subTotalPrice ? subTotalPrice.p : 0)}

            {isPla && (
              <SimilarProducts
                light
                compact
                similarProducts={similarProducts}
                category={category}
                seeAllLink={seeAllLink}
              />
            )}

            <div className={styles.topBar}>
              <a id='seeAllLink' href={seeAllLink}>
                <Button backBtn className={styles.backButton}>
                  <i className={cn(iconStyles.icon, styles.goBackIcon)} />
                  {t('txt_see_all')}
                </Button>
              </a>

              {!isFrame(productTypeId) && <FavouriteIcon designId={designId} />}
            </div>

            <div className={styles.product}>
              <div className={styles.carouselWrapper}>
                <div
                  className={cn(styles.backgroundThumbnailMobile, {
                    [styles.oldBackgroundThumbnailMobile]: !useNewImages,
                  })}
                />
                {renderImageThumbnails()}
                <div className={styles.mobilePersButton}>
                  {nonPersonalised ? renderAddToBasketButton() : renderPersonalizeButton()}
                </div>
              </div>

              <div className={styles.infoContentWrapper}>
                <div>

                  <div className={styles.headerTitleWrapper}>
                    {designName
                      ? (
                        <React.Fragment>
                          <Text header6 darkBlue>{categoryTitle}</Text>
                          <Text header3 darkBlue>{designName}</Text>
                        </React.Fragment>
                      )
                      : (<Text header3 darkBlue>{categoryTitle}</Text>)
                    }

                    {designAuthorName && (
                      <div className={styles.authorName}>
                        {t('txt_design_author_from_shutterstock', { author: designAuthorName })}
                      </div>
                    )}
                  </div>

                  {isFrame(productTypeId) && (
                    <FrameSizeSelector
                      value={selectedPaperFormatId}
                      onChange={this.changeFrameSize}
                    />
                  )}

                  <FramesTypeList
                    currentFrameId={selectedDesign.productId}
                    onClick={this.changeFrame}
                  />

                  {!isFrame(productTypeId) && this.renderFormats(formatsParams)}
                  {!isFrame(productTypeId) && this.renderColors(colorsParams)}

                  <FramesList
                    selectedFrameId={selectedFrameId}
                    productId={productId}
                    categoryId={category_id}
                    quantity={quantity}
                    onClick={this.onFrameOptionClick}
                  />

                  {useFoil && (
                    <SpotFinishList
                      selectedDesign={selectedDesign}
                      selectedSpotFinishId={spotFinishId}
                      selectedTrimId={trimId}
                      selectUpsell={this.selectUpsell}
                      categoryId={category_id}
                      quantity={quantity}
                      onClick={this.onSpotFinishOptionClick}
                    />
                  )}

                  {useTrim && (
                    <TrimList
                      selectedDesign={selectedDesign}
                      selectedTrimId={trimId}
                      selectedSpotFinishId={spotFinishId}
                      selectUpsell={this.selectUpsell}
                      categoryId={category_id}
                      quantity={quantity}
                      onClick={this.onTrimOptionClick}
                    />
                  )}

                  {renderQuantitySelector()}
                  {renderCalendarStartDateSelector()}
                  {renderSubstrates()}
                </div>
                <div>
                  <div className={styles.priceWrap}>
                    <Price
                      vatLabel={t('txt_incl_vat')}
                      envelopesLabel={t('txt_inc_envelopes')}
                      shipmentLabel={t('txt_amp_estimated_shipping')}
                      price={renderPrice(subTotalPrice ? subTotalPrice.pi : 0)}
                      discountPrice={renderPrice(subTotalPrice ? subTotalPrice.p : 0)}
                      deliveryPrice={renderPrice(shipmentPrice ? shipmentPrice.p : undefined)}
                      trustPilotStarsCount={undefined}
                      trustPilotReviewsCount={undefined}
                      showEnvelopesNote={showEnvelopesNote}
                    />
                  </div>
                  {isPoster(productTypeId) && (
                    <div className={cn(styles.note, styles.hideOnDesktop, styles.noItalic, styles.noteActive)} dangerouslySetInnerHTML={{ __html: t(productAndFrameCombinationKey()) }} />
                  )}
                  <div className={styles.desktopPersButton}>
                    {nonPersonalised ? renderAddToBasketButton() : renderPersonalizeButton()}
                  </div>
                  {isFrame(productTypeId) && renderDescription()}
                </div>
              </div>
            </div>

            <PixleeWidget
              productId={productId}
              productType={productTypeId}
              categoryId={category_id}
              familyId={familyId}
            />

            {!isFrame(productTypeId) && renderDescription()}
            {(isFrame(productTypeId) || isPoster(productTypeId)) && renderFrameSizeGuide()}
            {renderReviews()}
            {renderMatchingProducts()}

            <SimilarProducts
              similarProducts={similarProducts}
              category={category}
              seeAllLink={seeAllLink}
            />
            {/* {typeof document !== 'undefined' && <Modal />} */}
          </div>
        )}
      </Translation>
    );
  };
}

const mapStateToProps = (state: any, props: OwnProps) => {
  const { match } = props;
  const { category_id, design_id } = match.params;

  return {
    state,
    categories: state.category.categories,
    category: categorySelectors.getCategory(state, category_id),
    family: categorySelectors.getDesignFamilyByDesignId(state, category_id, design_id) as { designs: Design[] },
    paperFormats: categorySelectors.getPaperFormats(state),
    imagesData: categorySelectors.getImagesData(state),
    similarProducts: categorySelectors.getSimilarProducts(state, category_id, design_id),
    priceFormat: priceSelectors.getPriceFormat(state),
    getPrices: (productId: number) => priceSelectors.getPrices(state, category_id, productId),
    getUpsells: (productId: number) => priceSelectors.getUpsells(state, category_id, productId),
    getShipmentPrices: (designId: string) => priceSelectors.getShipmentPrices(state, category_id, designId),
    lastCategoryHistory: uiSelectors.getLastCategoryHistory(state),
    getFrames: (categoryId: number, productId: number) => priceSelectors.getFrames(state, categoryId, productId),
  };
 };

const ConnectedProduct = connect<StateProps>(mapStateToProps)(withRouter(Product as any));
ConnectedProduct.displayName = 'Product';
export default ConnectedProduct;
