import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { Dispatch } from 'redux';
import setInputControlValue from '@categoryProduct/store/controls/operation/setInputControlValue';
import registerInputControl from '@categoryProduct/store/controls/operation/registerInputControl';
import deactivateInputControl from '@categoryProduct/store/controls/operation/deactivateInputControl';
import getControlValue from '@categoryProduct/store/controls/selector/getControlValue';
import { Store } from '@categoryProduct/store/typings';
import cn from 'classnames';
import defaultStyles from './index.css';

interface OwnProps {
  options: any[];
  index: any;
  section: string;
  name: string;
  styles?: any;
  onChange?: Function;
}

interface Props extends OwnProps {
  dispatch: Dispatch;
  value: any;
}

const SimpleLabel = ({ index, styles, name, section, dispatch, options, onChange, value } : Props) => {
  const getValue = (i: number) => (options ? options[i % options.length] : undefined);

  useEffect(() => {
    const value = getValue(index);
    dispatch(registerInputControl(name, section, false, value));
    return () => {
      dispatch(deactivateInputControl(name, section));
    };
  }, []);

  useEffect(() => {
    const value = getValue(index);
    dispatch(setInputControlValue(name, section, value, true));
    if (onChange) {
      onChange(value, true);
    }
  }, [index]);

  return (
    <>
      <Translation>
        {t => (
          <div className={cn(defaultStyles.simpleLabel, styles ? styles.simpleLabel || '' : '')}>
            {value ? t(value.label) : ''}
          </div>
        )}
      </Translation>
    </>
  );
};

const mapStateToProps = (state: Store, {section, name}: OwnProps) => ({
  value: getControlValue(state, section, name),
});
export default connect(mapStateToProps)(SimpleLabel);
