import React, { ReactElement } from 'react';
import { CSSTransition } from 'react-transition-group';

import animationClasses from './Fade.css';

type Props = {
  children: ReactElement,
  [k: string]: any,
};

export default ({ children, ...props }: Props) => {
  return (
    <CSSTransition
      timeout={300}
      classNames={{ ...animationClasses }}
      {...props}
    >
      {children}
    </CSSTransition>
  );
};
