import React, { Component } from 'react';
import requestAnimationFrame from 'raf';
import { BACK_URL_KEY, memoryStore, RecordType, saveMapToSessionStorage } from '@categoryProduct/component/util/ScrollManagerHelper';

interface Props {
  scrollKey: string;
  defaultPosition?: {
    x: number;
    y: number;
  };
  targetRef?: React.RefObject<any>;
  children?: React.ReactElement;
  delayedRestore?: boolean;
}

/* Component that will save and restore Window scroll position. */
export default class ScrollPositionManager extends Component<Props> {
  target?: Window | Element = window;
  scrollStore = memoryStore;
  backUrl = location.pathname + location.search;

  componentDidMount() {
    this.restoreScrollPosition();
  }

  componentWillReceiveProps(nextProps: Props) {
    this.target = nextProps.targetRef ? nextProps.targetRef.current : window;

    if (this.props.scrollKey !== nextProps.scrollKey) {
      this.saveScrollPosition();
    }
  }

  componentDidUpdate(prevProps: Props) {
    this.backUrl = location.pathname + location.search;
    if (this.props.scrollKey !== prevProps.scrollKey) {
      this.restoreScrollPosition();
    }

    if (this.props.delayedRestore && !prevProps.delayedRestore) {
      this.restoreScrollPosition();
    }
  }

  componentWillUnmount() {
    this.saveScrollPosition();
    this.saveCurrentListPageState();
    saveMapToSessionStorage(this.scrollStore.data);
  }

  scroll = (target: any, x: number, y: number) => {
    if (target instanceof Window) {
      target.scrollTo(x, y);
    } else if (this.target && !(this.target instanceof Window)) {
      this.target.scrollLeft = x;
      this.target.scrollTop = y;
    }
  };

  getScrollPosition = (target: any): RecordType => {
    if (target instanceof Window) {
      return { x: target.pageXOffset, y: target.pageYOffset };
    }

    return { x: target.scrollLeft, y: target.scrollTop };
  };

  restoreScrollPosition(pos?: RecordType) {
    const { scrollKey, defaultPosition } = this.props;

    const position = pos || this.scrollStore.get(scrollKey) || defaultPosition;
    if (this.target && position) {
      requestAnimationFrame(() => {
        this.scroll(this.target, position.x, position.y);
      });
    }
  }

  saveScrollPosition(key?: string) {
    if (this.target) {
      const pos: RecordType = this.getScrollPosition(this.target);
      const sKey = key || this.props.scrollKey;
      this.scrollStore.set(sKey, pos);
    }
  }

  saveCurrentListPageState() {
    if (this.target) {
      this.scrollStore.set(BACK_URL_KEY, this.backUrl);
    }
  }

  render() {
    return this.props.children || null;
  }
}
