import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Translation } from 'react-i18next';
import EditorControlsContext from '@categoryProduct/util/context/EditorControlsContext';
import Text from '@categoryProduct/component/common/Text';
import changeBabyFacing from '@categoryProduct/store/birthPosters/operation/changeBabyFacing';
import { Store } from '@categoryProduct/store/typings';
import SimpleList from '../Controls/SimpleList';

import styles from './index.css';

interface OwnProps {
  section: string;
  babyIndex: number;
}

interface Props extends OwnProps {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const controlName = 'babyFacing';

const BabyFacingSelector = ({section, dispatch, babyIndex}: Props) => {
  const editorControls = useContext(EditorControlsContext);

  const options = [
    {
      label: 'txt_left',
      value: 'Left',
      id: '1',
    },
    {
      label: 'txt_right',
      value: 'Right',
      id: '2',
    },
  ];

  const onChange = (value: any) => {
    dispatch(changeBabyFacing(editorControls, babyIndex));
  };

  return (
    <div>
      <Translation>
        {t => (
          <div>
            <Text darkBlue>{t('txt_baby_facing')}:</Text>
            <SimpleList
              options={options}
              initialValue={options[0]}
              onSelect={onChange}
              styles={styles}
              section={section}
              name={controlName}
            />
          </div>
        )}
      </Translation>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(BabyFacingSelector);
