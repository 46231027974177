/* ACTION CREATOR FUNCTIONS
Put here the functions that return an action object that can be dispatched
HINT: Always use functions for consistency, don't export plain objects
*/

import { createAction } from 'redux-actions';

export const types = {
    lastVisitedCategorySet: 'ui/history/lastCategory/SET',
};

export const lastVisitedCategorySet = createAction(types.lastVisitedCategorySet);
