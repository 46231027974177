import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

// import isEqual from 'react-fast-compare';

// import loadingImg from '../util/loadingRect';
import { categorySelectors } from '../../store/category';

interface Props {
  url: string;
  placeholder?: string;
  className?: string;
  imagesData: { [s: string]: string };
  onLoad?: any;
  index?: number;
  imageRef?: any;
}

// interface State {
//   imgData?: string;
//   imgUrl?: string;
// }

// class LazyImage extends React.PureComponent<Props, State> {
//   state = {
//     imgData: undefined,
//     imgUrl: undefined,
//   };

//   static getDerivedStateFromProps(props: Props) {
//     const { url, imagesData } = props;
//     if (imagesData[url]) {
//       return {
//         imgData: imagesData[url],
//         imgUrl: url,
//       };
//     }

//     return null;
//   }

//   componentDidMount() {
//     this.fetchImg();
//   }

//   componentDidUpdate() {
//     this.fetchImg();
//   }

//   fetchImg = () => {
//     const { url } = this.props;
//     const { imgData, imgUrl } = this.state;

//     if (url !== imgUrl) {
//       console.log(url)
//       fetch(url).then(res => res.text()).then(() => console.log('fetched!'))
//       // .then(data => this.setState({
//       //   imgData: data || '',
//       //   imgUrl: url,
//       // }));
//     }
//   }

//   render() {
//     const { className } = this.props;
//     const { imgData, imgUrl } = this.state;
//     return (
//       <img
//         key={imgUrl}
//         className={className}
//         src={imgData}
//         alt=''
//       />
//     )
//   }
// }

// const LazyImage: React.FC<Props> = ({ url, placeholder, className, imagesData, onLoad }) => {
//   url === `https://cdn-adl-test.gelatogroup.net/do/rv4i9LXNe7E/medium_perspective-p1-en-GB?v=1561134630` && console.log('render image', url, placeholder, className);
//   return (
//     <img
//       key={url}
//       className={cn('lazy', className)}
//       src={imagesData[url] || (placeholder && imagesData[placeholder]) || placeholder}
//       data-src={imagesData[url] || url}
//       alt=''
//       onLoad={onLoad}
//     />
//   );
// };

class LazyImage extends React.Component<Props> {
  // shouldComponentUpdate() {
  //   return !isEqual()
  // }
  render = () => {
    const { url, placeholder, className, imagesData, onLoad, index, imageRef } = this.props;

    return (
      <img
        key={url}
        className={cn('lazy', className)}
        src={imagesData[url] || (placeholder && imagesData[placeholder]) || placeholder}
        data-src={imagesData[url] || url}
        alt=''
        onLoad={(e: any) => onLoad && onLoad(e, url, index)}
        ref={imageRef && imageRef}
      />
    );
  };

}

const mapStateToProps = (state: any) => {
  return {
    imagesData: categorySelectors.getImagesData(state),
  };
};

export default connect(
  mapStateToProps,
)(React.memo(LazyImage));
