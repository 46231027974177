import shouldUseNewPreviews from '../../util/shouldUseNewPreviews';
import { AppBundle } from '../../api/optimalprint-sdk';
import { isArtistic, isBusinessCard, isCalendar, isPlaceCard, isPoster } from './productTypeIdsHelper';

export const BACK_PAGE_WITH_LOGO = 'back-page-with-op-logo';

export default (
  categories: any,
  categoryId: number,
  selectedDesign: AppBundle.Api.Entity.Design.V1.Design,
  selectedSpotFinishId?: number,
  selectedTrimId?: number,
) => {
  const { thumbnail, previews, isFolded, spotFinishId, trimId, productTypeId, backpagePreviewUrlWithOpLogo } = selectedDesign;
  const previewImages = getPreviewImages(previews);
  let images: string[] = [];

  const sfId = selectedSpotFinishId || spotFinishId;
  const tId = selectedTrimId || trimId;

  const isWallCalendarProduct = isCalendar(productTypeId);
  const isPosterProduct = isPoster(productTypeId);
  const isArtisticPosterProduct = isArtistic(productTypeId);
  const isPlaceCardProduct = isPlaceCard(productTypeId);
  const isBusinessCardProduct = isBusinessCard(productTypeId);
  const useNewPreviews = !isArtisticPosterProduct && shouldUseNewPreviews(categories, categoryId);

  if (!previews || !previews.length) {
    return [];
  }

  const heroUrl = getHeroImageUrl(thumbnail[0], previewImages[0], useNewPreviews);
  const templateUrl = getTemplateImageUrl(previewImages[0]);

  if (isFolded) {
    images = getFoldedPreviews(heroUrl, templateUrl, backpagePreviewUrlWithOpLogo);
  } else if (isArtisticPosterProduct) {
    images = getArtisticPreviews(heroUrl, templateUrl, previewImages[0]);
  } else if (isPosterProduct || isPlaceCardProduct) {
    images = getSimplePreviews(heroUrl, templateUrl, useNewPreviews);
  } else if (isBusinessCardProduct) {
    images = getBusinessCardPreviews(heroUrl, templateUrl, previewImages.length > 1 ? 2 : 1, useNewPreviews);
  } else if (isWallCalendarProduct) {
    images = getCalendarPreviews(heroUrl, previewImages, useNewPreviews);
  } else {
    images = getFlatPreviews(heroUrl, templateUrl, previewImages.length > 1 ? 2 : 1, useNewPreviews);
  }

  if (!isWallCalendarProduct) {
    images = getMediumPreviews(images);
  }

  return images.map(img => getPreviewWithSelectedUpsellInUrl(img, tId, sfId));
};

export const getFoldedPreviews = (heroUrl: string, templateUrl: string, backpagePreviewUrlWithOpLogo?: string) => {
  const numberOfPages = backpagePreviewUrlWithOpLogo ? 3 : 4;
  const images = getPreviewImagesByTemplate(numberOfPages, templateUrl);
  return backpagePreviewUrlWithOpLogo ? [ heroUrl, ...images, backpagePreviewUrlWithOpLogo ] : [ heroUrl, ...images ];
};

export const getFlatPreviews = (heroUrl: string, templateUrl: string, numberOfPages: number, useNewPreviews: boolean) => {
  const images = getPreviewImagesByTemplate(numberOfPages, templateUrl);
  return useNewPreviews ? [ heroUrl, ...images ] : [ heroUrl, ...images.slice(1) ];
};

export const getSimplePreviews = (heroUrl: string, templateUrl: string, useNewPreviews: boolean) => {
  return useNewPreviews ? [ heroUrl, templateUrl ] : [ templateUrl ];
};

export const getArtisticPreviews = (heroUrl: string, templateUrl: string, preview: string) => {
  const onWallPreview = getOnWallPreview(preview);
  return [ heroUrl, templateUrl, onWallPreview ];
};

export const getBusinessCardPreviews = (heroUrl: string, templateUrl: string, numberOfPages: number, useNewPreviews: boolean) => {
  const images = getPreviewImagesByTemplate(numberOfPages, templateUrl);
  return useNewPreviews ? [ heroUrl, ...images ] : images;
};

export const getCalendarPreviews = (heroUrl: string, previews: string[], useNewPreviews: boolean) => {
  const largeImages = getLargePreviews(previews.slice(0, 2));
  return useNewPreviews ? [ heroUrl, ...largeImages ] : [ heroUrl, ...largeImages.slice(1) ];
};

export const getHeroImageUrl = (thumbnail: string, preview: string, useNewPreviews: boolean) => {
  return useNewPreviews
    ? preview
      .replace('small_3d', 'medium_perspective')
      .replace('medium_3d', 'medium_perspective')
      .replace('listing_medium', 'medium_perspective')
      .replace('listing_large', 'big_perspective')
    : thumbnail;
};

export const getTemplateImageUrl = (preview: string) => {
  return preview
    .replace('listing_', 'preview_page_')
    .replace('medium_perspective-', 'preview_page_medium-')
    .replace('big_perspective-', 'preview_page_medium-');
};

export const getPreviewImagesByTemplate = (numberOfPages: number, templateUrl: string) => {
  if (numberOfPages <= 0) {
    return [ templateUrl ];
  }
  return (new Array(numberOfPages)).fill(undefined).map((_, index) => templateUrl.replace('p1', `p${index + 1}`));
};

const MINIMUM_NOT_DEFAULT_UPSELL_OPTION_VALUE = 2;
export const getPreviewWithSelectedUpsellInUrl = (imageUrl: string, selectedTrim?: number, selectedFoil?: number) => {
  if (imageUrl.indexOf(BACK_PAGE_WITH_LOGO) >= 0) {
    return imageUrl;
  }

  const bareImage = imageUrl.replace(/(_trim_[0-9]|_spot_[0-9])/g, '');

  if (
    selectedTrim && selectedFoil
    && selectedTrim >= MINIMUM_NOT_DEFAULT_UPSELL_OPTION_VALUE
    && selectedFoil >= MINIMUM_NOT_DEFAULT_UPSELL_OPTION_VALUE
  ) {
    return bareImage.replace(/(-p[0-9]-)/, `_spot_${selectedFoil}_trim_${selectedTrim}$1`);
  }

  if (selectedTrim && selectedTrim >= MINIMUM_NOT_DEFAULT_UPSELL_OPTION_VALUE) {
    return bareImage.replace(/(-p[0-9]-)/, `_trim_${selectedTrim}$1`);
  }

  if (selectedFoil && selectedFoil >= MINIMUM_NOT_DEFAULT_UPSELL_OPTION_VALUE) {
    return bareImage.replace(/(-p[0-9]-)/, `_spot_${selectedFoil}$1`);
  }

  return bareImage;
};

export const getLargePreviews = (previewUrls: string[]) => {
  return previewUrls.map(url => url.replace('medium', 'large'));
};

export const getMediumPreviews = (previewUrls: string[]) => {
  return previewUrls
    .map(url => url
      .replace('large', 'medium')
      .replace('big', 'medium')
      .replace('small', 'medium'));
};

export const getPreviewImages = (previews: AppBundle.Api.Entity.Design.V1.IPagePreview[]) => previews.map(preview => preview.image);

export const getOnWallPreview = (previewUrl: string) => previewUrl.replace('listing_', 'preview_on_wall_');
