import React from 'react';

import Select from 'react-select-me';
import classnames from 'classnames';

const styles = require('./Select.css');
const itemStyles = require('./item.css');
const iconStyles = require('../commonStyles/icon.css');

// const DropdownIndicator: React.FC<IndicatorProps<SelectType>> = () => (<span style={styles.dropDownIcon} />);

const Option = ({ option, selected }: { option: SelectType, selected?: boolean }) => (
  <div className={itemStyles.container}>
    <div
      className={classnames(itemStyles.option, {
        [itemStyles.selected]: selected,
      })}
    >
      <div>{option.label}</div>
      <div className={itemStyles.optionSubLabel}>{option.subLabel}</div>
    </div>
  </div>
);

const optionRenderer = (option: SelectType, selected: SelectType[]) => {
  return (
    <Option
      key={option.value.toString()}
      option={option}
      selected={selected[0] && selected[0].value === option.value}
    />
  );
};

const selectedValueRenderer = (option: SelectType) => (
  <Option key={option.value.toString()} option={option} />
);

const iconRenderer = () => (
  <span className={classnames(iconStyles.icon, styles.openDropdownIcon, 'op-product-dropdown_arrow')} />
);

interface Props {
  options: SelectType[];
  value: SelectType;
  onChange: any;
}

const SelectWrapper: React.FC<Props> = (props) => {
  return (
    <Select
      valueKey='value'
      s={styles}
      optionRenderer={optionRenderer}
      iconRenderer={iconRenderer}
      selectedValueRenderer={selectedValueRenderer}
      optionHeight={45}
      listPosition='bottom'
      {...props}
    />
  );
};

export default SelectWrapper;
