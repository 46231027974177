import ActionTypes from '../actionTypes';
import createReducer from '../../../util/createReducer';
import { DesignState } from '../types';
import loadPublicDesign from './loadPublicDesign';
import loadCustomerDesign from './loadCustomerDesign';
import setDesignData from './setDesignData';
import setDesignStatus from './setDesignStatus';

export const initialState: DesignState = {
  designData: null,
  designId: '',
  designType: undefined,
  designStatus: undefined,
};

const design = createReducer(initialState, {
  [ActionTypes.LOAD_PUBLIC_DESIGN]: loadPublicDesign,
  [ActionTypes.LOAD_CUSTOMER_DESIGN]: loadCustomerDesign,
  [ActionTypes.SET_DESIGN_DATA]: setDesignData,
  [ActionTypes.SET_DESIGN_STATUS]: setDesignStatus,
});

export default design;
